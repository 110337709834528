/* eslint-disable react/jsx-curly-newline */
import { useEffect } from 'react'
import { createStyles, Box } from '@mantine/core'
import { mq } from 'src/utils/style'
import { useParams } from 'react-router'
import { useApi } from 'src/utils/useApi'
import { getFullIncidentReport } from '@venturi-io/api/src/config/alarmHistory'
import { useUser } from 'src/UserContext'
import Nothing from 'src/Nothing'
import Header from './Header'
import Details from './Details'
import Content from './Content'

const useStyles = createStyles((theme) => ({
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    [mq(theme.breakpoints.sm)]: {
      width: '80vw',
      maxWidth: 960
    },
    [mq('1440px')]: {
      width: '80vw',
      maxWidth: 'none'
    }
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: 12,
    padding: '16px',
    [mq(theme.breakpoints.xs)]: {
      flexDirection: 'row'
    }
  }
}))

interface RouteParams extends Record<string, string | undefined> {
  incidentId: string
}

export default function Incident () {
  const { classes } = useStyles()
  const { token } = useUser()
  const { incidentId } = useParams<RouteParams>()
  const getIncident = useApi(getFullIncidentReport)

  useEffect(() => {
    if (!incidentId) return
    void getIncident.fetch({ incidentId }, token)
  }, [incidentId])

  return (
    <Box className={classes.root}>
      {getIncident.data.caseOf({
        Nothing: () => (
          <Nothing
            isLoading={getIncident.loading}
            nothing={getIncident.data.isNothing()}
          />
        ),
        Just: (data) => (
          <Box className={classes.container}>
            <Header
              agentName={data.agentName}
              incidentName={data.name}
              cleared={data.cleared}
            />
            <Box className={classes.body}>
              <Details
                agentId={data.agentId}
                incident={data}
              />
              <Content />
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
