import { type ReactNode, type CSSProperties } from 'react'
import { createStyles, Loader as MantineLoader, type LoaderProps } from '@mantine/core'

const useStyles = createStyles(() => ({
  loader: {
    display: 'flex',
    width: '100%',
    paddingTop: '20px',
    paddingBottom: '20px',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

interface Props {
  first?: boolean
  style?: CSSProperties
  children?: ReactNode
  color?: LoaderProps['color']
  variant?: LoaderProps['variant']
  size?: LoaderProps['size']
}

export default function Loader ({
  first,
  style,
  children,
  color = 'primary',
  variant = 'oval',
  size
}: Props) {
  const { classes } = useStyles()

  const style_ = first ? { paddingTop: 0 } : {}

  return (
    <div
      className={classes.loader}
      style={{
        ...style_,
        ...style
      }}
    >
      {children ?? (
        <MantineLoader
          color={color}
          variant={variant}
          size={size}
        />
      )}
    </div>
  )
}
