/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useMemo } from 'react'
import { Table as MantineTable, Paper, useMantineTheme } from '@mantine/core'
import { type ReportData } from '@venturi-io/api/src/config/report'
import { uiDateFormatUniversal } from 'src/utils/dates'
import dayjs from 'dayjs'
import { type Range } from '@venturi-io/api'
import { getWhiteBackgroundColor } from 'src/utils/theme'
import Row from './Row'

export interface Props {
  data: ReportData
  queryId: number
  range: Range | null
  maxWidth: number
  setGeozoneReportData: (data: ParsedGeozoneEvent[]) => void
  selectedAgentIds: string[]
  selectedAgentGroupIds: string[]
}

export interface GeozoneItem {
  trip_id: string
  total_geo_zone_events: string
  trip_end_time: string
  org_user_last_name: string
  geo_zone_names: string
  agent_id: string
  agent_name: string
  agent_groups_ids: string | string[]
  user_groups_names: string
  org_user_id: string
  trip_start_time: string
  org_user_first_name: string
}

export interface ParsedGeozoneEvent {
  trip_id: string | null
  asset: string | null
  geozoneNames: string | null
  date: string | null
  count: string | null
  driver: string | null
  group: string | null
}

interface RowsProps extends Omit<Props, 'data'> {
  data: GeozoneItem[]
}

const Rows = ({ data, range, maxWidth }: RowsProps) => {
  return data
    ? data.map(trip => (
      <Row
        key={trip.trip_id}
        trip={trip}
        range={range}
        maxWidth={maxWidth}
      />
    ))
    : null
}

export default function GeozoneReportView (props: Props) {
  const {
    data,
    queryId,
    setGeozoneReportData,
    selectedAgentIds,
    selectedAgentGroupIds
  } = props

  const theme = useMantineTheme()
  const geozoneData = data[`${queryId}`] as unknown as GeozoneItem[] ?? []

  // Convert agent group ids into array of string
  const updatedGeozoneData = geozoneData.map(({ agent_groups_ids, ...props }) => ({
    ...props,
    agent_groups_ids: (typeof agent_groups_ids === 'string') ? agent_groups_ids.split(',') : []
  }))

  // Convert selected agent group ids into string
  const agentGroupIds = selectedAgentGroupIds.join(',') ?? ''

  // Filtered updatedGeozoneData by selected agent group id/ids
  const filteredDataByGroups = useMemo(() =>
    agentGroupIds.length > 0
      ? updatedGeozoneData.filter(({ agent_groups_ids }) => agent_groups_ids.some(id => agentGroupIds.includes(id)))
      : updatedGeozoneData,
  [updatedGeozoneData, agentGroupIds])

  // Filtered updatedGeozoneData/filteredDataByGroups by selected agent id/ids
  const filteredDataById = useMemo(() =>
    selectedAgentIds.length > 0
      ? filteredDataByGroups.filter(({ agent_id }) => selectedAgentIds.includes(agent_id ?? ''))
      : filteredDataByGroups,
  [filteredDataByGroups, selectedAgentIds])

  const parsedGeozone: ParsedGeozoneEvent[] = filteredDataById.map(({
    trip_id,
    agent_name,
    geo_zone_names,
    trip_start_time,
    org_user_first_name,
    org_user_last_name,
    total_geo_zone_events,
    user_groups_names
  }) => ({
    trip_id,
    asset: agent_name,
    geozoneNames: geo_zone_names,
    driver: org_user_first_name && org_user_last_name
      ? `${org_user_first_name} ${org_user_last_name}`
      : 'N/A',
    group: user_groups_names ?? 'N/A',
    date: dayjs(trip_start_time).format(uiDateFormatUniversal),
    count: total_geo_zone_events
  }))

  useEffect(() => {
    setGeozoneReportData(parsedGeozone)
  }, [queryId, props.range, selectedAgentIds, selectedAgentGroupIds])

  return (
    <Paper
      p="sm"
      shadow="xs"
      sx={{
        width: '100%',
        scrollbarWidth: 'thin'
      }}
    >
      <MantineTable
        sx={{
          position: 'relative'
        }}
      >
        <thead
          style={{
            ...getWhiteBackgroundColor(theme),
            position: 'sticky',
            top: 0,
            zIndex: 100
          }}
        >
          <tr
            style={{
              borderBottom: 1
            }}
          >
            <th>Asset Name</th>
            <th>Geozones</th>
            <th
              style={{
                width: 180
              }}
            >
              Date
            </th>
            <th
              style={{
                width: 100
              }}
            >
              Count
            </th>
            <th>Driver</th>
            <th>Group</th>
            <th
              style={{
                width: 180
              }}
            />
          </tr>
        </thead>
        <tbody>
          <Rows {...props} data={filteredDataById} />
        </tbody>
      </MantineTable>
    </Paper>
  )
}
