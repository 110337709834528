/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useMemo } from 'react'
import { Paper } from '@mantine/core'
import { type Report, type ReportData } from '@venturi-io/api/src/config/report'
import { dateFormat, uiDateFormatUniversal } from 'src/utils/dates'
import { type Range } from '@venturi-io/api'
import dayjs from 'dayjs'
import { convertStrToFloat } from 'src/AssetTemplate/shared'
import { type TimePeriod } from 'src/Buttons/TimePeriodButton'
import Content from './Content'

interface Site {
  site_id: string
  site_name: string
}

export interface ListItem extends Site {
  agent_id: string
  agent_name: string
  agent_liquid_type: string
  agent_groups_ids: string
  tank_capacity: string
  volume_percentage: string
  start_volume: string
  end_volume: string
  start_time: string
  end_time: string
}

interface GraphItem extends Site {
  agent_id: string
  agent_name: string
  agent_groups_ids: string
  numeric_value: string
  timestamp: string
}

export interface SiteTankLevels extends Site {
  list: Array<Omit<ListItem, 'agent_groups_ids'>>
  graph: Array<Omit<GraphItem, 'agent_groups_ids'>>
  chart_url?: string | null
}

interface Props {
  report: Report
  timePeriod: TimePeriod['value'] | null
  range: Range | null
  data: ReportData
  maxWidth: number
  setTankLevelReportData: (data: SiteTankLevels[]) => void
  selectedAgentIds: string[]
  selectedAgentGroupIds: string[]
}

export default function TankLevelReportView ({
  report,
  timePeriod,
  range,
  data,
  setTankLevelReportData,
  selectedAgentIds,
  selectedAgentGroupIds
}: Props) {
  const agentGroupIds = selectedAgentGroupIds.join(',') ?? ''
  const queryIds = Object.keys(data)
  const listQueryId = queryIds[0]
  const graphQueryId = queryIds[1]
  const listItems = data[listQueryId] as unknown as ListItem[] ?? []
  const graphItems = data[graphQueryId] as unknown as GraphItem[] ?? []

  const sites: Site[] = useMemo(() => (
    listItems
      .reduce((uniqueSites: Site[], { site_id, site_name }) => {
        const isExisting = uniqueSites.find((site) => site.site_id === site_id)

        if (!isExisting) {
          return [
            ...uniqueSites,
            {
              site_id,
              site_name
            }
          ]
        }

        return uniqueSites
      }, [])
      .sort((a, b) => (
        a.site_name > b.site_name
          ? 1
          : -1
      ))
  ), [listItems])

  // Single source of truth for tank level history list data
  const listData = useMemo(() => {
    // Filter items by selected Agents first to reduce the number of
    // items to be transformed
    const filteredDataByAgents = selectedAgentIds.length > 0
      ? listItems.filter(({ agent_id }) => selectedAgentIds.includes(agent_id))
      : listItems

    // Parse the values of necessary fields including agent_groups_ids
    const parsedItems = filteredDataByAgents.map(({
      agent_liquid_type,
      tank_capacity,
      start_time,
      start_volume,
      end_time,
      end_volume,
      agent_groups_ids,
      ...rest
    }) => ({
      agent_liquid_type: agent_liquid_type ?? 'N/A',
      tank_capacity: tank_capacity
        ? Math
          .round(convertStrToFloat(tank_capacity))
          .toString()
        : 'N/A',
      start_time: start_time
        ? dayjs(start_time, dateFormat).format(uiDateFormatUniversal)
        : 'N/A',
      start_volume: start_volume
        ? Math
          .round(convertStrToFloat(start_volume))
          .toString()
        : '0',
      end_time: end_time
        ? dayjs(end_time, dateFormat).format(uiDateFormatUniversal)
        : 'N/A',
      end_volume: end_volume
        ? Math
          .round(convertStrToFloat(end_volume))
          .toString()
        : '0',
      agent_groups_ids: agent_groups_ids
        ? agent_groups_ids.split(',')
        : [],
      ...rest
    }))

    // Filter items by selected Agent Groups based on parsed
    // agent_groups_ids to reduce the number of items
    const filteredDataByGroups = agentGroupIds.length > 0
      ? parsedItems.filter(({ agent_groups_ids }) => agent_groups_ids.some(id => agentGroupIds.includes(id)))
      : parsedItems

    // Sort transformed items by agent_name in ASC order
    const sortedItems = filteredDataByGroups.sort((a, b) => (
      a.agent_name > b.agent_name
        ? 1
        : -1
    ))

    return sortedItems
  }, [listItems, selectedAgentIds, agentGroupIds])

  // Single source of truth for tank level history graph data
  const graphData = useMemo(() => {
    // Filter items by selected Agents first to reduce the number of
    // items to be transformed
    const filteredDataByAgents = selectedAgentIds.length > 0
      ? graphItems.filter(({ agent_id }) => selectedAgentIds.includes(agent_id))
      : graphItems

    // Parse the values of necessary fields including agent_groups_ids
    const parsedItems = filteredDataByAgents.map(({
      agent_groups_ids,
      ...rest
    }) => ({
      agent_groups_ids: agent_groups_ids
        ? agent_groups_ids.split(',')
        : [],
      ...rest
    }))

    // Filter items by selected Agent Groups based on parsed
    // agent_groups_ids to reduce the number of items
    const filteredDataByGroups = agentGroupIds.length > 0
      ? parsedItems.filter(({ agent_groups_ids }) => agent_groups_ids.some(id => agentGroupIds.includes(id)))
      : parsedItems

    // Sort transformed items by timestamp in ASC order (oldest to newest)
    const sortedItems = filteredDataByGroups.sort((a, b) => (
      a.timestamp > b.timestamp
        ? 1
        : -1
    ))

    return sortedItems
  }, [graphItems, selectedAgentIds, agentGroupIds])

  const sitesTankLevels: SiteTankLevels[] = useMemo(() => (
    sites.map(({ site_id, site_name }) => ({
      site_id,
      site_name,
      list: listData.filter((item) => item.site_id === site_id),
      graph: graphData.filter((item) => item.site_id === site_id)
    }))
  ), [sites, listData, graphData])

  useEffect(() => {
    setTankLevelReportData(sitesTankLevels)
  }, [sitesTankLevels])

  return (
    <Paper p="sm" shadow="xs" mb={18}>
      <Content
        report={report}
        timePeriod={timePeriod}
        range={range}
        reportData={data}
        data={sitesTankLevels}
      />
    </Paper>
  )
}
