import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { createStyles } from '@mantine/core'
import { mq } from 'src/utils/style'
import Paper from 'src/Layout/Paper'
import NotFound from 'src/Router/NotFound'
import Nothing from 'src/Nothing'
import { useApi } from 'src/utils/useApi'
import {
  deleteReportSchedule,
  getReportSchedule,
  type ReportSchedule,
  updateReportSchedule
} from '@venturi-io/api/src/config/report'
import { useUser } from 'src/UserContext'
import Loader from 'src/Layout/Loader'
import Form from './Form'

interface RouteParams extends Record<string, string | undefined> {
  reportScheduleId: string
}

const useStyles = createStyles(theme => ({
  container: {
    flex: 1,
    [mq(theme.breakpoints.xl)]: {
      maxWidth: theme.breakpoints.xl
    }
  }
}))

export default function Edit () {
  const { classes } = useStyles()
  const { token } = useUser()
  const navigate = useNavigate()
  const schedule = useApi(getReportSchedule)
  const update = useApi(updateReportSchedule)
  const remove = useApi(deleteReportSchedule)
  const { reportScheduleId: id } = useParams<RouteParams>()

  if (typeof id === 'undefined' || isNaN(parseInt(id))) {
    return <NotFound />
  }

  const handleSubmit = (values: Omit<ReportSchedule, 'reportScheduleId' | 'reportName'>) => {
    if (!id) throw Error('No id found')
    void update
      .fetch({
        ...values,
        reportScheduleId: Number(id)
      }, token, 'Successfully updated report schedule')
  }

  const handleDelete = () => {
    void remove
      .fetch({
        reportScheduleId: Number(id)
      }, token, 'Successfully deleted report schedule')
      .then(() => {
        navigate('/reports/scheduled', { replace: true })
      })
  }

  useEffect(() => {
    void schedule.fetch({ reportScheduleId: Number(id) }, token)
  }, [id])

  return (
    <Paper className={classes.container} relative>
      {schedule.loading && <Loader />}
      {schedule.data.caseOf({
        Nothing: () => (
          <Nothing
            isLoading={schedule.loading}
            nothing={schedule.data.isNothing()}
          />
        ),
        Just: (data) => (
          <Form
            mode="EDIT"
            jobRunTime={data.jobRunTime}
            initialValues={data}
            isLoading={update.loading || remove.loading}
            onUpdate={handleSubmit}
            onDelete={handleDelete}
          />
        )
      })}
    </Paper>
  )
}
