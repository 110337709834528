import {
  type ComponentType,
  type ReactNode,
  type Key,
  Fragment,
  useState,
  useEffect
} from 'react'
import {
  createStyles,
  useMantineTheme,
  Paper,
  Text,
  Center,
  Table
} from '@mantine/core'
import { useElementSize, useMediaQuery } from '@mantine/hooks'
import { mq } from 'src/utils/style'
import { getBackgroundColor, getPrimaryTextColor, getWhiteBackgroundColor } from 'src/utils/theme'
import LoadingOverlay from '../LoadingOverlay'
import Pagination from '../Pagination'

export const useStyles = createStyles(theme => ({
  root: {
    ...getWhiteBackgroundColor(theme)
  },
  header: {
    borderBottom: `1px solid ${
      theme.colorScheme === 'light'
      ? theme.colors.gray[3]
      : theme.colors.dark[4]
    }`
  },
  row: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    borderBottom: `1px solid ${
      theme.colorScheme === 'light'
      ? theme.colors.gray[2]
      : theme.colors.dark[5]
    }`,
    [mq(theme.breakpoints.sm)]: {
      flexDirection: 'row'
    }
  },
  minCol: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    flex: 0.5,
    '& > button': {
      marginRight: 10
    }
  },
  periodCol: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    flex: 1.2,
    '& > button': {
      marginRight: 10
    }
  },
  dateCol: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    flex: 0.6,
    '& > button': {
      marginRight: 10
    }
  },
  col: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    padding: 10,
    '& > button': {
      marginRight: 10
    }
  },
  idCol: {
    flex: 0,
    minWidth: 80
  },
  idText: {
    padding: '3px 10px',
    borderRadius: 15,
    textAlign: 'center'
  },
  actionsCol: {
    flex: 0,
    minWidth: 250,
    [mq(theme.breakpoints.sm)]: {
      minWidth: 'unset',
      flex: 1
    }
  },
  noDataPadding: {
    padding: '20px'
  },
  onHover: {
    '&:hover': {
      ...getBackgroundColor(theme, 2)
    }
  },
  link: {
    ...getPrimaryTextColor(theme)
  },
  smallCol: {
    minWidth: '100px',
    flex: 0
  },
  chip: {
    fontSize: 12,
    fontWeight: 500
  }
}))

export type Extra = Record<string, unknown>

export type Callbacks = Record<string, () => void>

export interface IsOdd {
  odd: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
export interface Row<C extends Callbacks = {}, E extends Extra = {}> extends IsOdd {
  actions: C
  extra: E
}

export interface Props<T, C extends Callbacks, E extends Extra = {}> {
  data: T[]
  extra?: E
  actions?: C
  getId: (item: T) => Key
  header?: () => ReactNode
  empty?: () => ReactNode
  card: ComponentType<T & Row<C, E>>
  row: ComponentType<T & Row<C, E>>
  isLoading: boolean
  totalPages: number
  page?: number
  size?: number
  onPageChange?: (page: number) => void
  onSizeChange?: (size: number) => void
  hideShadow?: boolean
  identifier?: string
  didChangeHeight?: (height: number, key: string) => void
  mt?: string
  noDataMessage?: string | null
}

const topHeight = 56
const bottomHeight = 44
const barHeights = topHeight + bottomHeight

export default function ActionList<T, C extends Callbacks, E extends Extra = {}> ({
  mt = 'lg',
  data,
  extra,
  actions,
  header,
  getId,
  empty,
  isLoading,
  totalPages,
  page,
  size,
  onPageChange,
  onSizeChange,
  hideShadow,
  identifier,
  didChangeHeight,
  noDataMessage = 'No data available',
  ...props
}: Props<T, C, E>) {
  const { classes } = useStyles()
  const theme = useMantineTheme()
  const isDesktop = useMediaQuery(mq(theme.breakpoints.sm, false))
  const { ref, height } = useElementSize()
  const [firstLoad, setFirstLoad] = useState(true)

  useEffect(() => {
    setFirstLoad(false)
  }, [])

  useEffect(() => {
    if (didChangeHeight && identifier) {
      didChangeHeight(height + barHeights, identifier)
    }
  }, [height])

  return (
    <Paper
      ref={ref}
      mt={mt}
      className={classes.root}
      p={hideShadow
        ? 0
        : isDesktop
          ? 'md'
          : 0}
      shadow={hideShadow
        ? 'none'
        : isDesktop
          ? 'sm'
          : 'none'}
    >
      <LoadingOverlay visible={isLoading} />
      {!isLoading && (
        <>
          <Table>
            {isDesktop && header && header()}
            {data.map((item, index) => (
              <Fragment key={getId(item)}>
                {!isDesktop
                  ? (
                    <props.card
                      {...item}
                      extra={extra ?? {} as E}
                      actions={actions ?? {} as C}
                      odd={index % 2 === 0}
                    />
                    )
                  : (
                    <props.row
                      {...item}
                      extra={extra ?? {} as E}
                      actions={actions ?? {} as C}
                      odd={index % 2 === 0}
                    />
                    )}
              </Fragment>
            ))}
            {empty && data.length === 0 && empty()}
            {!firstLoad &&
              data &&
              data.length === 0 &&
              noDataMessage !== null &&
              (
                <Center className={classes.noDataPadding}>
                  <Text
                    mt={20}
                    size={12}
                    weight={400}
                    color="silver"
                    align="center"
                  >
                    {noDataMessage}
                  </Text>
                </Center>
              )}
          </Table>
          <Pagination
            mt="sm"
            value={page}
            sizeValue={size}
            total={totalPages}
            onChange={onPageChange}
            onChangeSize={onSizeChange}
          />
        </>
      )}
    </Paper>
  )
}
