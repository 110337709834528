import { type CenterProps, Center, Text } from '@mantine/core'

interface Props {
  padding?: CenterProps['p']
  message?: string | null
}

export default function EmptyPlaceholder ({
  padding = 20,
  message = 'No data available'
}: Props) {
  return (
    <Center p={padding}>
      <Text
        size={12}
        weight={400}
        color="silver"
        align="center"
      >
        {message}
      </Text>
    </Center>
  )
}
