import { type Sensor } from '@venturi-io/api/src/config/agent'

export const generateRandomNumber = (min: number, max: number) => Math.floor(Math.random() * ((max - min) + 1) + min)

export const round = (num: number) => Math.round((num + Number.EPSILON) * 100) / 100

export const roundToKm = (num?: number) => Math.round((num ?? 0) / 1000)

export const computeSensorPercentage = (sensor: Sensor) => {
  const currentVal = sensor?.currentValue
    ? parseFloat(sensor.currentValue)
    : 0

  const maxVal = sensor?.maxValue
    ? sensor.maxValue
    : 100

  return round((currentVal / maxVal) * 100)
}

export const computeIdleInMins = (idlingDuration?: number, withSeconds?: boolean) => {
  let idlingInMins: string | undefined = ''
  if (idlingDuration) {
    const minutes = Math.floor(idlingDuration / 60)
    const lessThan = minutes < 1
      ? '~'
      : ''
    idlingInMins = idlingDuration
      ? `${lessThan}${lessThan === '~'
        ? '1'
        : minutes} min`
      : undefined

    if (withSeconds && lessThan !== '~') {
      const seconds = idlingDuration % 60
      idlingInMins = `${idlingInMins} ${seconds === 0
        ? ''
        : `${seconds} sec`}`
    }
  }
  return idlingInMins
}

export const computePercentage = (value: number, max: number) => max > 0
  ? Math.round((value / max) * 100)
  : 0
