import { useState } from 'react'
import { type EitherAsync, Just, Nothing } from 'purify-ts'
import { type PaginatedRequest, type PaginatedResponse } from '@venturi-io/api/src/shared'
import { equals } from 'ramda'
import { type ApiCallable, type ApiResError } from '@venturi-io/api'
import { useNotifications } from './notifications'
import { configureUseApi } from './configure'

export type ApiData<
 T extends (
   request: never,
   token?: string,
   bearerToken?: string,
   signal?: AbortSignal
 ) => EitherAsync<ApiResError, unknown>
> = T extends (
  request: never,
  token?: string,
  bearerToken?: string,
  signal?: AbortSignal
) => EitherAsync<ApiResError, infer U>
  ? U
  : unknown

// TODO: change the implementation of JWT bearerToken once whole token system is configured
export const useApi = configureUseApi(useNotifications)

// TODO: change the implementation of JWT bearerToken once whole token system is configured
export function usePaginatedApi<Req extends PaginatedRequest, Res extends PaginatedResponse> (
  api: ApiCallable<Req, Res>,
  needsToken = true,
  needsNotifications = true,
  needsBearerToken = false,
  defaultSize = 20
) {
  const { fetch: internalFetch, ...api_ } = useApi(
    api,
    needsToken,
    needsNotifications,
    needsBearerToken
  )
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(defaultSize)

  const fetch = async (request: Req, token: string, success?: string, bearerToken?: string) => {
    setPage(request.page)
    setSize(request.size)

    return await internalFetch(request, token, success, bearerToken)
  }

  return {
    ...api_,
    fetch,
    page,
    size,
    setPage,
    setSize
  }
}

async function wait (ms: number) {
  return await new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

type ReqRes<Req extends Record<string, unknown>, Res> = Array<{ req: Req, res: Res }>

function isReqRes <Req extends Record<string, unknown>, Res> (data: Res | ReqRes<Req, Res>): data is ReqRes<Req, Res> {
  if (Array.isArray(data)) {
    return data.some(v => v.req !== undefined)
  }

  return false
}

export function useMockApi<Req extends Record<string, unknown>, Res> (
  api: (
    request: Req,
    token?: string,
    bearerToken?: string,
    signal?: AbortSignal
  ) => EitherAsync<ApiResError, Res>,
  data: Res | ReqRes<Req, Res>
) {
  const { fetch: _, ...api_ } = useApi(api, true, true, false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fetch = async (request: Req, _token: string, timeout = 500) => {
    api_.__set(api_.data, api_.error, true)
    await wait(timeout)

    let matches = false
    if (isReqRes(data)) { // handle the set of req-res pairs
      for (const d of data) {
        if (equals(d.req, request)) {
          matches = true
          api_.__set(Just(d.res), Nothing, false)
          break
        }
      }
    } else { // or just set the data if its passed as an object
      matches = true
      api_.__set(Just(data), Nothing, false)
    }

    if (!matches) {
      api_.__set(Nothing, Just(new Error('No matched request given')), false)
    }
  }

  return {
    ...api_,
    fetch
  }
}

export function useMockPaginatedApi<Req extends PaginatedRequest, Res extends PaginatedResponse> (
  api: ApiCallable<Req, Res>,
  data: Res
) {
  const { fetch: _, ...api_ } = useApi(api, true, true, false)
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)

  const fetch = async (request: Req, _token: string, timeout = 500) => {
    api_.__set(api_.data, api_.error, true)
    await wait(timeout)

    setPage(request.page)
    setSize(request.size)
    api_.__set(Just(data), Nothing, false)
  }

  return {
    ...api_,
    fetch,
    page,
    size,
    setPage
  }
}
