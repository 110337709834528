/* eslint-disable @typescript-eslint/naming-convention */
import { useNotifications } from 'src/utils/notifications'
import { useCallback, useState } from 'react'
import { Collapse, Text, useMantineTheme } from '@mantine/core'
import { useUser } from 'src/UserContext'
import { useDisclosure } from '@mantine/hooks'
import dayjs from 'dayjs'
import { dateFormat, uiDateFormatUniversal, uiDateOnly, uiTimeOnly } from 'src/utils/dates'
import { secondsToDhms } from 'src/Assets/shared'
import { downloadCSV } from 'src/utils/files'
import { generatePdfDocument } from 'src/Reports/shared'
import { getLinkTextColor, getToggleableColorWBG } from 'src/utils/theme'
import { type GeozoneEvents, getGeozoneEventsForTrip } from '@venturi-io/api/src/analytics/geozone'
import RowExportButton, { type SuccessData } from '../RowExportButton'
import ExportablePdf, { type ParsedGeozone } from './ExportablePdf'
import GeozoneEvent from './GeozoneEvent'
import { type GeozoneItem, type Props } from '.'

interface RowProps extends Pick<Props, 'range' | 'maxWidth'> {
  trip: GeozoneItem
}

const Row = ({ trip }: RowProps) => {
  const {
    agent_id,
    trip_id,
    agent_name,
    geo_zone_names,
    trip_start_time,
    trip_end_time,
    user_groups_names,
    org_user_first_name,
    org_user_last_name,
    total_geo_zone_events
  } = trip
  const theme = useMantineTheme()
  const { showError } = useNotifications()
  const { token } = useUser()
  const [tableHeight, setTableHeight] = useState(200)
  const [open, { toggle }] = useDisclosure(false)

  const driver = org_user_first_name ?? org_user_last_name
    ? `${org_user_first_name ?? ''} ${org_user_last_name ?? ''}`
    : 'N/A'

  const loadGeozoneEvents = async (onSuccess: (idlings: GeozoneEvents) => void) => {
    // I need to call it asynchrounusly and get the result on done fetching.
    // Saving to state causes delay and might not load the info correctly on the pdf
    await getGeozoneEventsForTrip({
      tripId: Number(trip_id),
      startTime: trip_start_time,
      endTime: trip_end_time
    }, token).caseOf({
      Left: err => showError(err ?? 'Error on fetching idle trips'),
      Right: onSuccess
    })
  }

  const downloadableData = useCallback(async (
    type: 'CSV' | 'PDF',
    data: GeozoneEvents
  ) => await Promise.all(
    data.map(async (event, idx) => {
      const {
        geoZoneName,
        timesVisited,
        enteredTime,
        leftTime,
        totalParkedTime,
        totalDurationInsideZone
      } = event

      return type === 'CSV'
        ? {
            'Event #': idx + 1,
            Geozone: geoZoneName,
            'Event Date': dayjs(enteredTime).format(uiDateOnly),
            'Time In': dayjs(enteredTime).format(uiTimeOnly),
            'Time Out': dayjs(leftTime).format(uiTimeOnly),
            'Duration In': secondsToDhms(totalDurationInsideZone),
            'Parked Duration': secondsToDhms(totalParkedTime ?? 0),
            'Times Visited': secondsToDhms(timesVisited)
          }
        : event
    }))
  , [])

  const handleCSV = async (data: SuccessData) => {
    const events = await downloadableData('CSV', data as GeozoneEvents)
    downloadCSV(events, `${agent_name} - Geozone Event Report`)
  }

  const handleDownloadPdf = () => async (data: SuccessData) => {
    const events = await downloadableData(
      'PDF',
      data as GeozoneEvents
    ) as never as ParsedGeozone[]
    await generatePdfDocument((
      <ExportablePdf
        agentName={agent_name}
        data={events}
        count={events.length.toString()}
        date={trip_start_time}
        {...trip}
      />
    ), `${agent_name} - Geozone Event Report`)
  }

  return (
    <>
      <tr
        onClick={toggle}
        style={{
          cursor: 'pointer',
          backgroundColor: getToggleableColorWBG(open),
          borderBottom: 1,
          position: open
            ? 'sticky'
            : 'initial',
          top: 34,
          zIndex: open
            ? 100
            : 'unset'
        }}
      >
        <td>
          <Text
            {...getLinkTextColor(theme)}
            weight={open
              ? 'bold'
              : 'normal'}
          >
            {agent_name}
          </Text>
        </td>
        <td>{geo_zone_names}</td>
        <td>{dayjs(trip_start_time, dateFormat).format(uiDateFormatUniversal)}</td>
        <td>{total_geo_zone_events}</td>
        <td>{driver}</td>
        <td>{user_groups_names ?? 'N/A'}</td>
        <RowExportButton
          skipMapBuild
          trip_id={trip_id}
          agent_id={agent_id}
          agent_name={agent_name}
          trip_end_time={trip_end_time}
          trip_start_time={trip_start_time}
          loadData={loadGeozoneEvents}
          onDownloadCsv={handleCSV}
          onDownloadPdf={handleDownloadPdf}
        />
      </tr>
      <tr>
        <td
          colSpan={7}
          style={{
            padding: 0,
            borderTop: 0,
            borderBottom: open
              ? 1
              : 0
          }}
        >
          <Collapse in={open}>
            {open && (
              <GeozoneEvent
                showTitle={false}
                startTime={trip_start_time}
                endTime={trip_end_time}
                tripId={parseInt(trip_id ?? '0')}
                style={{
                  width: '100%',
                  height: tableHeight,
                  margin: 0
                }}
                stickyTop={78}
                didChangeHeight={(height) => {
                  setTableHeight(height)
                }}
              />
            )}
          </Collapse>
        </td>
      </tr>
    </>
  )
}

export default Row
