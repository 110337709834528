import { type CSSProperties } from 'react'
import {
  createStyles,
  Box,
  Group,
  Paper,
  Text
} from '@mantine/core'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import { mq } from 'src/utils/style'
import { hasAlarm, hasWarning } from 'src/AssetTemplate/shared'
import AlarmIndicator from 'src/AssetTemplate/Components/AlarmIndicator'
import { getTextColorAsProp } from 'src/utils/theme'
import colors from 'src/AssetTemplate/colors'
import FuelTank from './FuelTank'
import FuelDetails from './FuelDetails'

const useStyles = createStyles(theme => ({
  content: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    [mq(theme.breakpoints.md)]: {
      flexDirection: 'row'
    }
  },
  titleContainer: {
    position: 'relative'
  },
  alarmIndicatorContainer: {
    position: 'absolute',
    top: -8,
    left: -16
  },
  alarmIndicatorLine: {
    height: 32,
    width: 4,
    background: '#FFE066'
  },
  leftSection: {
    flex: 1,
    alignContent: 'center'
  },
  rightSection: {
    flex: 3,
    alignContent: 'center'
  }
}))

interface Props {
  fuelLevelLiter: Sensor | null
  fuelLevelLiterValue: number
  fuelLevelPercent: number
  fuelRemainingPercent: number
  fuelCapacityLiter: number
  fuelConsumption: Sensor | null
  fuelConsumptionLiter: number
}

export default function FuelStatus ({
  fuelLevelLiter,
  fuelLevelLiterValue,
  fuelLevelPercent,
  fuelRemainingPercent,
  fuelCapacityLiter,
  fuelConsumption,
  fuelConsumptionLiter
}: Props) {
  const { classes } = useStyles()
  const fuelSensors = [
    fuelConsumption,
    fuelLevelLiter
  ]
  const withWarning = hasWarning(fuelSensors)
  const withAlarm = hasAlarm(fuelSensors)
  const statusColor: CSSProperties['color'] = withAlarm
    ? colors.red
    : withWarning
      ? colors.yellow
      : colors.blue

  return (
    <Paper p={16} radius={4} shadow="xs">
      <Box className={classes.titleContainer}>
        <Text
          size={14}
          weight={600}
          {...getTextColorAsProp()}
        >
          Fuel Status
        </Text>
        {withAlarm && (
          <Group
            className={classes.alarmIndicatorContainer}
            position="center"
            align="center"
            spacing={88}
          >
            <span className={classes.alarmIndicatorLine} />
            <AlarmIndicator size="md" />
          </Group>
        )}
      </Box>
      <Box className={classes.content}>
        <Box className={classes.leftSection}>
          <FuelTank
            fuelLevelPercent={fuelLevelPercent}
            fuelRemainingPercent={fuelRemainingPercent}
            fuelLevelLiterValue={fuelLevelLiterValue}
            fuelCapacityLiter={fuelCapacityLiter}
            statusColor={statusColor}
            disabled={fuelLevelLiter === null}
          />
        </Box>
        <Box className={classes.rightSection}>
          <FuelDetails
            fuelLevelLiter={fuelLevelLiter}
            fuelLevelLiterValue={fuelLevelLiterValue}
            fuelConsumption={fuelConsumption}
            fuelConsumptionLiter={fuelConsumptionLiter}
          />
        </Box>
      </Box>
    </Paper>
  )
}
