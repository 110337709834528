import { type CSSProperties } from 'react'
import {
  createStyles,
  Box,
  Group,
  Paper,
  Stack,
  Text
} from '@mantine/core'
import Bar from 'src/Charts/Bar'
import colors from 'src/AssetTemplate/colors'
import { formatNumber } from 'src/utils/numbers'
import { getTextColor } from 'src/utils/theme'

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    margin: '0 auto',
    ...getTextColor(theme)
  },
  barContainer: {
    width: 50
  }
}))

interface Props {
  fuelLevelPercent: number
  fuelRemainingPercent: number
  fuelLevelLiterValue: number
  fuelCapacityLiter: number
  statusColor?: CSSProperties['color']
  disabled?: boolean
}

export default function FuelTank ({
  fuelLevelPercent,
  fuelRemainingPercent,
  fuelLevelLiterValue,
  fuelCapacityLiter,
  statusColor = colors.blue,
  disabled = false
}: Props) {
  const { classes } = useStyles()

  return (
    <Box className={classes.container}>
      <Group position="center" align="flex-end" spacing={16} noWrap>
        <Stack spacing={20} align="flex-end" justify="center">
          <Stack spacing={0}>
            <Text
              size={24}
              weight={600}
              align="right"
              color={disabled
                ? 'dimmed'
                : undefined}
            >
              {!disabled
                ? `${fuelLevelPercent}%`
                : 'N/A'}
            </Text>
            <Text
              size={10}
              weight={500}
              align="right"
            >
              Capacity
            </Text>
          </Stack>
          {!disabled && (
            <Text
              size={12}
              weight={500}
              align="right"
            >
              {`${formatNumber(fuelLevelLiterValue)} L / ${formatNumber(fuelCapacityLiter)} L`}
            </Text>
          )}
        </Stack>
        <Box className={classes.barContainer}>
          <Bar
            height={124}
            data={[
              {
                sensor: 'Fuel Tank',
                value: fuelLevelPercent,
                remainingValue: fuelRemainingPercent
              }
            ]}
            keys={['value', 'remainingValue']}
            colors={[statusColor, colors.lightGray]}
            indexBy="sensor"
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            }}
            padding={0.1}
            minValue={0}
            maxValue={100}
            layout="vertical"
            valueScale={{ type: 'linear' }}
            indexScale={{
              type: 'band',
              round: true
            }}
            borderColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.6
                ]
              ]
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableLabel={false}
            totalsOffset={0}
            labelSkipWidth={15}
            labelSkipHeight={16}
            labelTextColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.6
                ]
              ]
            }}
            legends={[]}
            tooltip={({ id, formattedValue }) => (id === 'value' &&
              <Paper px={12} py={8} radius="sm" shadow="sm">
                <Text size={12}>{`${formattedValue}%`}</Text>
              </Paper>
            )}
            enableGridY={false}
            enableGridX={false}
          />
        </Box>
      </Group>
    </Box>
  )
}
