/* eslint-disable import/order */
import { useEffect, useMemo, useState } from 'react'
import { Button, Group, Stack } from '@mantine/core'
import { usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import ActionList from 'src/Layout/ActionList'
import { IconPlus } from '@tabler/icons-react'
import { Header, Row, Card } from './List'
import CreateSchedule from './Create'
import { getReportSchedules } from '@venturi-io/api/src/config/report'
import ReportRunTime, { defaultRunTime } from './ReportRunTime'

export default function Scheduled () {
  const { token } = useUser()
  const schedules = usePaginatedApi(getReportSchedules)
  const [showCreate, setShowCreate] = useState(false)

  const jobRunTime = useMemo(() => {
    const schedule = schedules.data.mapOrDefault(({ items }) => items[0], undefined)

    return schedule
      ? schedule.jobRunTime
      : defaultRunTime
  }, [schedules])

  const loadSchedules = async (page?: number, size?: number): Promise<void> => {
    void schedules
      .fetch({
        page: page ?? schedules.page,
        size: size ?? schedules.size,
        sort: 'scheduleStartDate',
        order: 'desc'
      }, token)
  }

  useEffect(() => {
    void loadSchedules(1)
  }, [])

  useEffect(() => {
    void loadSchedules()
  }, [schedules.page])

  return (
    <Stack spacing="sm">
      <Group w="100%" position="apart">
        <ReportRunTime inline jobRunTime={jobRunTime} />
        <Button
          disabled={showCreate}
          title="Create Role"
          color="primary"
          leftIcon={<IconPlus size={24} />}
          onClick={() => setShowCreate(true)}
        >
          New Schedule
        </Button>
      </Group>
      <ActionList
        isLoading={schedules.loading}
        data={schedules.data.mapOrDefault(({ items }) => items, [])}
        getId={({ reportScheduleId }) => reportScheduleId}
        header={() => <Header />}
        row={Row}
        card={Card}
        totalPages={schedules.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
        page={schedules.page}
        onPageChange={schedules.setPage}
      />
      <CreateSchedule
        jobRunTime={jobRunTime}
        show={showCreate}
        close={() => setShowCreate(false)}
        onCreate={loadSchedules}
      />
    </Stack>
  )
}
