/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useMemo } from 'react'
import { Table as MantineTable, Paper, useMantineTheme } from '@mantine/core'
import { type ReportData } from '@venturi-io/api/src/config/report'
import { secondsToDhms } from 'src/Assets/shared'
import { round } from 'src/utils/math'
import { type Range } from '@venturi-io/api'
import { getWhiteBackgroundColor } from 'src/utils/theme'
import Row from './Row'

export interface Props {
  data: ReportData
  queryId: number
  range: Range | null
  maxWidth: number
  setTripReportData: (data: ParsedTripItem[]) => void
  selectedAgentIds: string[]
  selectedAgentGroupIds: string[]
}

export interface TripItem {
  agent_id: string
  agent_name: string
  alarm_status: string
  agent_groups_ids: string
  total_distance_in_metres: string
  total_duration_in_seconds: string
  total_trips: string
}

export interface ParsedTripItem extends Omit<TripItem,
'agent_groups_ids' |
'total_distance_in_metres' |
'total_duration_in_seconds'
> {
  total_distance_in_kilometres: string
  total_duration: string
}

export interface AgentTripItem {
  trip_id: number
  start_time: string
  end_time: string
  asset: string
  driver: string
  start_location: string
  end_location: string
  driving_duration: string
  idling_duration: string
  distance_travelled: string
  average_speed: string
  max_speed: string
  max_x_acceleration: string
  max_y_acceleration: string
  max_z_acceleration: string
}

interface RowsProps extends Omit<Props, 'data'> {
  data: ParsedTripItem[]
}

const Rows = ({ data, range, maxWidth }: RowsProps) => {
  return data
    ? data.map(trip => (
      <Row
        key={trip.agent_id}
        trip={trip}
        range={range}
        maxWidth={maxWidth}
      />
    ))
    : null
}

export default function TripReportView (props: Props) {
  const {
    data,
    queryId,
    setTripReportData,
    selectedAgentIds,
    selectedAgentGroupIds
  } = props
  const theme = useMantineTheme()
  const tripData = data[`${queryId}`] as unknown as TripItem[] ?? []

  // Convert agent group ids into array of string
  const updatedTripData = tripData.map(({ agent_groups_ids, ...props }) => ({
    ...props,
    agent_groups_ids: agent_groups_ids ? agent_groups_ids.split(',') : []
  }))

  // Convert selected agent group ids into string
  const agentGroupIds = selectedAgentGroupIds.join(',') ?? ''

  // Filtered updatedTripData by selected agent group id/ids
  const filteredDataByGroups = useMemo(() =>
    agentGroupIds.length > 0
      ? updatedTripData.filter(({ agent_groups_ids }) => agent_groups_ids.some(id => agentGroupIds.includes(id)))
      : updatedTripData,
  [updatedTripData, agentGroupIds])

  // Filtered updatedTripData/filteredDataByGroups by selected agent id/ids
  const filteredDataById = useMemo(() =>
    selectedAgentIds.length > 0
      ? filteredDataByGroups.filter(({ agent_id }) => selectedAgentIds.includes(agent_id))
      : filteredDataByGroups,
  [filteredDataByGroups, selectedAgentIds])

  const parsedTripData: ParsedTripItem[] = filteredDataById.map(({
    agent_id,
    agent_name,
    alarm_status,
    total_trips,
    total_duration_in_seconds,
    total_distance_in_metres
  }) => {
    return {
      agent_id,
      agent_name,
      alarm_status,
      total_trips,
      total_distance_in_kilometres: round(parseInt(total_distance_in_metres) / 1000).toString(),
      total_duration: secondsToDhms(parseInt(total_duration_in_seconds)).toString()
    }
  })

  useEffect(() => {
    setTripReportData(parsedTripData)
  }, [queryId, props.range, selectedAgentIds, selectedAgentGroupIds])

  return (
    <Paper
      p="sm"
      shadow="xs"
      sx={{
        width: '100%',
        scrollbarWidth: 'thin'
      }}
    >
      <MantineTable
        sx={{
          ...getWhiteBackgroundColor(theme),
          position: 'relative'
        }}
      >
        <thead
          style={{
            ...getWhiteBackgroundColor(theme),
            position: 'sticky',
            top: 0,
            zIndex: 100
          }}
        >
          <tr
            style={{
              borderBottom: 1
            }}
          >
            <th >
              Assets
            </th>
            <th
              style={{
                width: 150
              }}
            >
              Alarm Status
            </th>
            <th
              style={{
                width: 150
              }}
            >
              # Trips
            </th>
            <th
              style={{
                width: 150
              }}
            >
              Total Duration
            </th>
            <th
              style={{
                width: 150
              }}
            >
              Total Distance KMs
            </th>
            <th
              style={{
                width: 80
              }}
            />
          </tr>
        </thead>
        <tbody>
          <Rows {...props} data={parsedTripData} />
        </tbody>
      </MantineTable>
    </Paper>
  )
}
