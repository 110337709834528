import {
  Group,
  Paper,
  Progress,
  SimpleGrid,
  Stack,
  Text
} from '@mantine/core'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import { type Agent } from '@venturi-io/api/src/config/agent'
import { convertStrToFloat, findSensor } from 'src/AssetTemplate/shared'
import { parseEmpty } from 'src/utils/parseEmpty'
import AgentAlarmStatus from 'src/Assets/shared/AgentAlarmStatus'
import dayjs from 'dayjs'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'
import { secondsToHours } from 'src/utils/dates'
import { computePercentage } from 'src/utils/math'

export default function Card ({
  agentId,
  agentName,
  alarmStatus,
  agentStatus,
  connectionStatus,
  sensors,
  metadata,
  lastSeenTime
}: Agent & ListRow) {
  const controlMode = findSensor('CONTROL_MODE', sensors)
  const engineState = findSensor('ENGINE_OPERATE_MODE', sensors)
  const engineRpm = findSensor('ENGINE_SPEED', sensors)
  const engineRuntime = findSensor('ENGINE_RUN_TIME', sensors)
  const engineRuntimeValue = convertStrToFloat(engineRuntime?.currentValue)
  const totalRuntime = engineRuntimeValue > 0
    ? `${secondsToHours(convertStrToFloat(engineRuntime?.currentValue)).toFixed(2)} hr`
    : '0 hr'
  const fuelLevelLiter = findSensor('FUEL_LEVEL_L', sensors)
  const fuelLevelLiterValue = Math.round(convertStrToFloat(fuelLevelLiter?.currentValue))
  const fuelCapacity = metadata?.FUEL_CAPACITY?.value ?? '0'
  const fuelCapacityLiterValue = Math.round(convertStrToFloat(fuelCapacity))
  const fuelLevelPercentValue = computePercentage(fuelLevelLiterValue, fuelCapacityLiterValue)
  const batteryVoltage = findSensor('ENGINE_BATTERY_VOLTAGE', sensors)

  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint)

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <SimpleGrid cols={2} spacing="lg">
          <TextGroup
            label="Asset Name"
            value={parseEmpty(agentName)}
            maxLength={textMaxLength}
            to={`/assets/genset/${agentId}`}
          />
          <Group
            spacing={10}
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <TextGroup label="Status" value="" />
            <AgentAlarmStatus
              alarmStatus={alarmStatus}
              agentStatus={agentStatus}
              connectionStatus={connectionStatus}
              lastSeenTime={lastSeenTime}
            />
          </Group>
          <TextGroup
            label="Control Mode"
            value={controlMode?.currentValue
              ? `${controlMode.currentValue}`
              : '-'}
            maxLength={25}
          />
          <Group
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '0.0625rem'
            }}
          >
            <TextGroup label="Engine State" value="" />
            <Text
              size="sm"
              color={engineState?.currentValue === 'Running'
                ? 'green'
                : 'yellow'}
            >
              {engineState?.currentValue
                ? `${engineState.currentValue}`
                : '-'}
            </Text>
          </Group>
          <TextGroup
            label="Engine Speed"
            value={engineRpm?.currentValue
              ? `${engineRpm.currentValue} RPM`
              : '-'}
            maxLength={25}
          />
          <TextGroup
            label="Engine Runtime"
            value={totalRuntime}
            maxLength={25}
          />
          <Group
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <TextGroup label="Fuel" value="" />
            <SimpleGrid
              cols={2}
              sx={{
                alignItems: 'center '
              }}
            >
              <Progress
                color={fuelLevelPercentValue < 33
                  ? 'red'
                  : 'green'}
                radius="xs"
                size="lg"
                value={fuelLevelPercentValue}
              />
              <Text size="xs">
                {isNaN(fuelLevelPercentValue)
                  ? 'N/A'
                  : `${fuelLevelPercentValue}%`}
              </Text>
            </SimpleGrid>
          </Group>
          <TextGroup
            label="Battery Voltage"
            value={batteryVoltage?.currentValue
              ? `${batteryVoltage.currentValue} ${batteryVoltage.unit}`
              : '-'}
            maxLength={25}
          />
          <TextGroup
            label="Last Seen"
            value={dayjs(lastSeenTime).fromNow()}
            maxLength={25}
          />
        </SimpleGrid>
      </Stack>
    </Paper>
  )
}
