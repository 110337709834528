import { type BBox } from '@turf/turf'
import { type TripDetails } from '@venturi-io/api/src/collector/trip'
import React, { type RefObject, useMemo } from 'react'
import { generateIdleForCluster, type IdlePointProps } from 'src/Maps/GeoZoneMap/mapbox'
import { computeIdleInMins } from 'src/utils/math'
import useSupercluster from 'use-supercluster'
import { type MapRef } from 'react-map-gl'
import ClusterMarker from './ClusterMarker'
import Marker from './Marker'
import type Supercluster from 'supercluster'

interface Props {
  mapRef: RefObject<MapRef>
  rawPath: TripDetails['rawPath']
  bounds: BBox
  zoom: number
  showTimestamps?: boolean
}

function IdleMarker ({
  mapRef,
  rawPath,
  bounds,
  zoom,
  showTimestamps
}: Props) {
  const idlingMarkers = useMemo(() => generateIdleForCluster(rawPath), [rawPath])

  const {
    clusters: idleClusterPoints,
    supercluster: idleCluster
  } = useSupercluster({
    points: idlingMarkers,
    bounds,
    zoom,
    options: {
      radius: 100,
      minPoints: 2,
      maxZoom: 25
    }
  })

  const idleMinutesClusterPoints = useMemo(() => (
    idleClusterPoints.map((cluster) => {
      const {
        id,
        properties: {
          cluster: isCluster
        }
      } = cluster
      let leaves: Array<Supercluster.PointFeature<IdlePointProps>> = []
      if (idleCluster && isCluster && id) {
        leaves = idleCluster.getLeaves(Number(id), Infinity)
      }
      const totalDuration = isCluster
        ? leaves.reduce((total, { properties: { idlingDuration } }) =>
          total + (idlingDuration ?? 0)
        , 0)
        : undefined
      const totalMins = isCluster
        ? computeIdleInMins(totalDuration)
        : undefined
      const children = leaves.map(({ properties: { tripPoint } }) => tripPoint)

      return isCluster
        ? {
            ...cluster,
            properties: {
              ...cluster.properties,
              children,
              totalDuration,
              totalMins
            }
          }
        : cluster
    })
  ), [
    idleClusterPoints,
    idleCluster,
    zoom,
    bounds
  ])

  const handleMarkerClick = (longitude: number, latitude: number) => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        zoom: 20, // focus zoom
        center: [longitude, latitude],
        duration: 300
      })
    }
  }

  const handleDoubleClick = (
    clusterId: number | string,
    longitude: number,
    latitude: number
  ) => {
    if (idleCluster && mapRef.current) {
      const zoom = idleCluster.getClusterExpansionZoom(Number(clusterId))
      mapRef.current.easeTo({
        zoom,
        center: [longitude, latitude]
      })
    }
  }

  return idleMinutesClusterPoints.map(({ id, geometry, properties }, idx) => {
    const [longitude, latitude] = geometry.coordinates
    const {
      cluster: isCluster,
      totalMins,
      children,
      point_count: pointCount,
      idlingInMins,
      tripPoint
    } = properties as IdlePointProps

    return isCluster
      ? (
        <ClusterMarker
          key={`${id}-${idx}`}
          clusterId={id}
          latitude={latitude}
          longitude={longitude}
          pointCount={pointCount ?? 0}
          totalMins={totalMins}
          onSelect={handleMarkerClick}
          onDoubleClick={handleDoubleClick}
          list={children}
        />
        )
      : (
        <Marker
          key={tripPoint?.time}
          latitude={latitude}
          longitude={longitude}
          idleInMins={idlingInMins}
          timestamp={tripPoint?.time}
          showTimestamps={showTimestamps}
        />
        )
  })
}

export default IdleMarker
