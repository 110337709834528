import { configure } from '@venturi-io/api/src/configure'
import {
  type Context,
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { useUser } from 'src/UserContext'
import { getHost } from 'src/utils/host'

interface LogoContextProps {
  logo?: string
}

let logoContext: Context<LogoContextProps> | undefined

export function getLogoContext (logo?: LogoContextProps) {
  if (logoContext) return logoContext

  logoContext = logo
    ? createContext(logo)
    : undefined

  return logoContext
}

export function useLogo () {
  const ctx = getLogoContext()
  const state = ctx
    ? useContext(ctx)
    : null

  return state
}

interface Props {
  children: ReactNode | JSX.Element
}

function LogoContext ({ children }: Props) {
  const { orgId } = useUser()
  const [imageUrl, setImageUrl] = useState<string>()

  useEffect(() => {
    void configure(getHost())
      .then(({ user }) => {
        setImageUrl(`${user}/api/usermanager/image/org/${orgId}/logo.png?type=topLogo&time=${Date.now()}`)
      })
  }, [])

  const values = imageUrl
    ? {
        logo: imageUrl
      }
    : undefined
  const Context = getLogoContext(values)

  return values && Context && (
    <Context.Provider value={values}>
      {children}
    </Context.Provider>
  )
}

export default LogoContext
