import React, { forwardRef, useImperativeHandle } from 'react'
import { useDisclosure } from '@mantine/hooks'
import { Modal } from '@mantine/core'
import MapToPdf, { type MapToPdfProps } from '.'

export interface BuilderProps {
  open: () => void
}

function Builder (props: MapToPdfProps, ref: React.Ref<BuilderProps>) {
  const [isOpen, { close, open }] = useDisclosure(false)
  const { didFinishLoading } = props

  useImperativeHandle(ref, () => ({
    open
  }), [open])

  const handleDidFinish = (uri: string) => {
    close()
    if (didFinishLoading) {
      didFinishLoading(uri)
    }
  }

  return (
    <Modal
      closeOnClickOutside={false}
      closeOnEscape={false}
      withCloseButton={false}
      opened={isOpen}
      onClose={close}
      title="Building map, please wait..."
      size="auto"
    >
      <MapToPdf
        {...props}
        didFinishLoading={handleDidFinish}
      />
    </Modal>
  )
}

const MapBuilderModal = forwardRef(Builder)

export default MapBuilderModal
