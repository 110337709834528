import { Stack, Group, Text, createStyles } from '@mantine/core'
import dayjs from 'dayjs'
import {
  type ReactNode,
  type Dispatch,
  type SetStateAction,
  useState,
  useEffect
} from 'react'
import { Popup as MapPopup } from 'react-map-gl'
import { uiDateFormatUniversal } from 'src/utils/dates'
import { type AgentData } from '@venturi-io/api/src/collector/agent'
import { getWhiteBackgroundColor } from 'src/utils/theme'
import { reverseGeocode } from 'src/Maps/GeoZoneMap/mapbox'
import { type IncidentBreach } from '@venturi-io/api/src/config/alarmHistory'
import { type RawPointSourceProps } from './BreachMap'
import type mapboxgl from 'mapbox-gl'

const useStyles = createStyles((theme) => ({
  popup: {
    zIndex: 2,
    padding: theme.spacing.xs,
    '& .mapboxgl-popup-content': {
      ...getWhiteBackgroundColor(theme)
    },
    '& .mapboxgl-popup-tip': {
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent', /* 40px height (20+20) */
      borderLeft: '10px solid white'
    }
  }
}))

export interface DataProps {
  coordinates: number[]
  timestamp: string
  position: number
  agentId: number
  pathId: string
  color: string
}

export interface PositionDetails {
  details?: AgentData
  coordinates?: number[]
}

interface PopupProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>
  properties: RawPointSourceProps | null
  isOpen: boolean
  map?: mapboxgl.Map
}

interface LabelValueProps {
  label: string
  value?: string | number
  icon?: ReactNode
  location?: IncidentBreach['agentLocationPoint']
}

function LabelValue ({ label, value, icon, location }: LabelValueProps) {
  const [address, setAddress] = useState('...')

  const loadAddress = async () => {
    if (location?.longitude && location?.latitude) {
      const { longitude, latitude } = location
      const result = await reverseGeocode([longitude, latitude])
      setAddress(result)
    }
  }

  useEffect(() => {
    void loadAddress()
  }, [location])

  return (
    <Stack spacing={0}>
      <Text weight="bold">
        {`${label}:`}
      </Text>
      <Group spacing={4}>
        <Text>{value ?? address}</Text>
        {icon && icon}
      </Group>
    </Stack>
  )
}

function Popup ({
  isOpen,
  setIsOpen,
  properties
}: PopupProps) {
  const { classes } = useStyles()
  if (!properties || !isOpen) return null

  const {
    longitude,
    latitude,
    data
  } = properties

  const {
    agentLocationPoint,
    detected,
    speed,
    value,
    orgUser
  } = JSON.parse(data as never as string) as IncidentBreach

  const hasSpeed = typeof speed !== 'undefined' && speed !== 0
  const trueValue = hasSpeed
    ? speed
    : value

  return (
    <MapPopup
      key={detected}
      closeButton
      closeOnClick={false}
      latitude={latitude ?? 0}
      longitude={longitude ?? 0}
      anchor="left"
      className={classes.popup}
      offset={16}
      onClose={() => setIsOpen(false)}
      style={{
        zIndex: 95
      }}
    >
      <Stack
        spacing={4}
        pt="sm"
        pb={8}
        px={8}
      >
        {trueValue && (
          <LabelValue
            label="Value"
            value={trueValue}
          />
        )}
        {orgUser && (
          <LabelValue
            label="User"
            value={`${orgUser.firstName} ${orgUser.lastName}`}
          />
        )}
        {agentLocationPoint && (
          <LabelValue
            label="Location"
            location={agentLocationPoint}
          />
        )}
        {detected && (
          <LabelValue
            label="Detected"
            value={dayjs(detected).format(uiDateFormatUniversal)}
          />
        )}
      </Stack>
    </MapPopup>
  )
}

export default Popup
