import { useCallback, useEffect, useState } from 'react'
import {
  type AutocompleteItem,
  Grid,
  Col,
  Paper,
  Text,
  Group,
  ActionIcon,
  Tooltip,
  createStyles,
  useMantineTheme,
  Popover,
  Stack,
  Button
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getAgentDetails } from '@venturi-io/api/src/config/agent'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import SelectAgent from 'src/Input/Select/SelectAgent'
import Select from 'src/Input/Select'
import dayjs from 'dayjs'
import { dateFormat } from 'src/utils/dates'
import DateTimeRangePicker from 'src/Input/DateTimeRangePicker'
import { type DateValue, type DateTimePickerProps, type DatesRangeValue } from '@mantine/dates'
import { type Range } from '@venturi-io/api'
import { IconDots, IconFileTypeCsv } from '@tabler/icons-react'
import { downloadCSV } from 'src/utils/files'
import { useMediaQuery } from '@mantine/hooks'
import { mq } from 'src/utils/style'
import { getAllSensorDataLogs } from '@venturi-io/api/src/collector/sensor'
import { formatLogsToCsv } from './shared'
import { type FormattedSensorLog } from '.'

const useStyles = createStyles(({ breakpoints }) => ({
  csvButton: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start'
  },
  csv: {
    width: 30,
    height: 30,
    [mq(breakpoints.sm)]: {
      width: 36,
      height: 36
    }
  }
}))

const ClearIcon = () => <FontAwesomeIcon icon={['fas', 'close']} size="sm" />

interface Props {
  logs: FormattedSensorLog[]
  totalLogs: number | null
  agentId: string | null
  sensorId: string | null
  setSelectedAgentId: (value: string | null) => void
  setSelectedSensorInstanceId: (value: string | null) => void
  setDateRange: (value: Range | null) => void
  dateRange: Range | null
}

const inputWrapperOrder: Array<'label' | 'error' | 'input' | 'description'> = ['label', 'error', 'input', 'description']
const sharedProps: Partial<DateTimePickerProps> = {
  inputWrapperOrder,
  descriptionProps: {
    style: {
      fontStyle: 'italic'
    }
  },
  maxDate: dayjs().toDate()
}

const format = `${dateFormat}`

export default function SearchBar ({
  logs,
  agentId,
  sensorId,
  totalLogs,
  setSelectedAgentId,
  setSelectedSensorInstanceId,
  setDateRange,
  dateRange
}: Props) {
  const theme = useMantineTheme()
  const isDesktop = useMediaQuery(mq(theme.breakpoints.sm, false))
  const { classes } = useStyles()
  const { token } = useUser()

  const agentDetails = useApi(getAgentDetails)
  const getAllLogs = useApi(getAllSensorDataLogs)
  const [searchAgent, setSearchAgent] = useState<string | null>(null)
  const [searchSensorInstance, setSearchSensorInstance] = useState<string | null>(null)

  const { from, to } = dateRange
    ? {
        from: dayjs(dateRange.from, dateFormat),
        to: dayjs(dateRange.to, dateFormat)
      }
    : {
        from: null,
        to: null
      }

  const range: DatesRangeValue = [
    from?.toDate() ?? null,
    to?.toDate() ?? null
  ]

  const isValidRange = from && to
    ? from.isBefore(to)
    : true

  const isMoreThanAWeek = to?.isBefore(from.add(7, 'days').add(1, 'minute'))

  const pickerProps = {
    ...sharedProps,
    size: isDesktop
      ? 'sm'
      : 'xs',
    error: !isValidRange || !isMoreThanAWeek
  }

  const errorMes = () => {
    if (!isValidRange) {
      return (
        <Text
          italic
          size="xs"
          color="red"
        >
          Please provide a valid date range
        </Text>
      )
    } else if (!isMoreThanAWeek) {
      return (
        <Text
          italic
          size="xs"
          color="red"
        >
          Date range must be maximum of 1 week only
        </Text>
      )
    }
  }

  const selectSensorInstances: AutocompleteItem[] = agentDetails.data.mapOrDefault(
    ({ sensors }) => sensors.map(({ sensorInstanceId, name }) => ({
      value: sensorInstanceId.toString(),
      label: name
    }))
    , [])

  const onSelectAgent = useCallback((agentId: string | null) => {
    setSearchAgent(agentId)
    setSelectedAgentId(agentId)
    setSearchSensorInstance(null)
    setSelectedSensorInstanceId(null)
  }, [])

  const onSelectSensorInstance = useCallback((sensorId: string | null) => {
    setSearchSensorInstance(sensorId)
    setSelectedSensorInstanceId(sensorId)
  }, [])

  const onClearAgent = useCallback(() => {
    setSearchAgent('')
    setSelectedAgentId(null)
    setSearchSensorInstance('')
    setSelectedSensorInstanceId(null)
  }, [])

  const onClearSensorInstance = useCallback(() => {
    setSearchSensorInstance('')
    setSelectedSensorInstanceId(null)
  }, [])

  const handleExportAsCSV = useCallback(() => {
    downloadCSV(logs as never as Array<Record<string, number>>, 'Event logs')
  }, [logs])

  const handleExportAllAsCSV = useCallback(async () => {
    if (totalLogs) {
      await getAllLogs.fetch({
        agentId: agentId
          ? Number(agentId)
          : undefined,
        sensorInstanceId: sensorId
          ? Number(sensorId)
          : undefined,
        page: 1,
        size: totalLogs,
        startTime: dayjs(from).format(`${dateFormat}Z`),
        endTime: dayjs(to).format(`${dateFormat}Z`)
      }, token)
    }
  }, [
    totalLogs,
    agentId,
    sensorId,
    from,
    to
  ])

  const toFlatSeconds = (date: DateValue) => dayjs(date)
    .set('seconds', 0)
    .format(format)

  useEffect(() => {
    getAllLogs.data.ifJust(({ items }) => {
      const formatted = formatLogsToCsv(items)
      downloadCSV(formatted as never as Array<Record<string, number>>, 'All event logs')
      getAllLogs.clearAll()
    })
  }, [getAllLogs])

  useEffect(() => {
    if (!searchAgent) return

    void agentDetails.fetch({ agentId: Number(searchAgent) }, token)
  }, [searchAgent])

  useEffect(() => {
    if (agentId) {
      setSearchAgent(agentId)
    }
  }, [agentId])

  useEffect(() => {
    if (sensorId) {
      setSearchSensorInstance(sensorId)
    }
  }, [sensorId])

  return (
    <Paper p="md" shadow="xs" mb="md">
      <Grid align="flex-end">
        <Col span={12} sm={3}>
          <SelectAgent
            placeholder="Choose an agent"
            value={searchAgent}
            onChange={onSelectAgent}
            rightSection={<ClearIcon />}
            rightSectionProps={{
              onClick: onClearAgent
            }}
            searchable
          />
        </Col>
        <Col span={12} sm={3}>
          <Select
            searchable
            placeholder="Choose a sensor"
            value={searchSensorInstance}
            onChange={onSelectSensorInstance}
            data={selectSensorInstances}
            rightSection={<ClearIcon />}
            rightSectionProps={{ onClick: onClearSensorInstance }}
            disabled={!searchAgent || agentDetails.loading}
          />
        </Col>
        <Col span={12} sm={6}>
          <Group position="apart" align="flex-end">
            <DateTimeRangePicker
              fromProps={{
                ...pickerProps,
                label: 'From date/time',
                defaultValue: range[0],
                onChange: date => {
                  setDateRange({
                    from: toFlatSeconds(date),
                    to: toFlatSeconds(range[1])
                  })
                }
              }}
              toProps={{
                ...pickerProps,
                label: 'To date/time',
                defaultValue: range[1],
                onChange: date => {
                  setDateRange({
                    from: toFlatSeconds(range[0]),
                    to: toFlatSeconds(date)
                  })
                }
              }}
              error={errorMes()}
            />
            <Popover
              position="bottom-end"
              withArrow
              arrowOffset={12}
            >
              <Popover.Target>
                <Tooltip label="Export">
                  <ActionIcon className={classes.csv}>
                    <IconDots size={isDesktop
                      ? 20
                      : 16}
                    />
                  </ActionIcon>
                </Tooltip>
              </Popover.Target>
              <Popover.Dropdown>
                <Stack spacing="xs">
                  <Text color="dimmed" size="xs">Export data</Text>
                  <Button
                    className={classes.csvButton}
                    variant="subtle"
                    color="primary"
                    disabled={getAllLogs.loading}
                    leftIcon={<IconFileTypeCsv size={20} />}
                    onClick={handleExportAsCSV}
                  >
                    Export current page as CSV
                  </Button>
                  <Button
                    className={classes.csvButton}
                    variant="subtle"
                    color="primary"
                    loading={getAllLogs.loading}
                    leftIcon={<IconFileTypeCsv size={20} />}
                    onClick={handleExportAllAsCSV}
                  >
                    Export all as CSV
                  </Button>
                </Stack>
              </Popover.Dropdown>
            </Popover>
          </Group>
        </Col>
      </Grid>
    </Paper>
  )
}
