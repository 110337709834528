/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
  Box,
  createStyles,
  Group,
  Stack,
  Text,
  Transition,
  useMantineTheme
} from '@mantine/core'
import { useClickOutside, useDisclosure } from '@mantine/hooks'
import { Marker } from 'react-map-gl'
import { getTextColorAsProp, getWhiteBackgroundColor } from 'src/utils/theme'
import { IconHourglass } from '@tabler/icons-react'
import useDoubleClick from 'src/utils/useDoubleClick'
import { type TripAgentLocation } from '@venturi-io/api/src/collector/trip'
import { useViewTypes } from 'src/Maps/GeoZoneMap/AgentAttendanceContext'
import IdleList from './IdleList'

interface StyleParams {
  expanded: boolean
}

const useStyles = createStyles((theme, { expanded }: StyleParams) => {
  const borderRadius = 35
  const smallCircle = 54
  const bigCircle = 70
  const dimensions = expanded
    ? {
        width: 240,
        height: 240
      }
    : {
        width: bigCircle,
        height: bigCircle
      }
  const pinBorders = expanded
    ? {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 27,
        borderBottomRightRadius: 0
      }
    : {
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
        borderBottomRightRadius: borderRadius
      }
  const containerBorders = expanded
    ? {
        borderTopLeftRadius: 27,
        borderTopRightRadius: 27,
        borderBottomRightRadius: 27
      }
    : {
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
        borderBottomRightRadius: borderRadius
      }
  const transition = {
    transition: 'all ease-in-out 0.25s'
  }
  const pinContentDimensions = {
    width: smallCircle,
    height: smallCircle,
    borderRadius: 27
  }
  const { colors, colorScheme } = theme
  const isLight = colorScheme === 'light'

  return {
    container: {
      ...dimensions,
      ...transition,
      ...containerBorders,
      ...getWhiteBackgroundColor(theme),
      overflow: 'hidden',
      position: 'relative'
    },
    pin: {
      ...pinBorders,
      ...transition,
      width: bigCircle,
      height: bigCircle,
      position: 'absolute',
      left: 0,
      top: 0,
      background: expanded
        ? '#fff'
        : '#D9CD74',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      border: `2px solid ${
        isLight
          ? colors.white[0]
          : colors.dark[6]
      }`,
      '&::before': {
        content: "''",
        position: 'absolute',
        backgroundColor: '#fff',
        top: 6,
        left: 6,
        ...transition,
        ...pinContentDimensions
      }
    },
    pinExpanded: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      padding: 12,
      borderBottom: `1px solid ${colors.gray[1]}`
    },
    label: {
      fontSize: 14,
      fontWeight: 'bold',
      zIndex: 2
    },
    subtitle: {
      fontWeight: 'bold',
      fontSize: 9,
      zIndex: 3
    }
  }
})

export interface AgentCluster {
  clusterId?: number | string
  latitude: number
  longitude: number
}

interface Props extends AgentCluster {
  pointCount: number
  totalMins?: string
  list?: TripAgentLocation[]
  onSelect?: (longitude: number, latitude: number) => void
  onDoubleClick?: (clusterId: number | string, longitude: number, latitude: number) => void
}

function ClusterMarker ({
  clusterId,
  longitude,
  latitude,
  pointCount,
  totalMins,
  list,
  onSelect,
  onDoubleClick
}: Props) {
  const { setPeekedClusterId } = useViewTypes()

  const { colors } = useMantineTheme()
  const [markerNode, setMarkerNode] = useState<HTMLDivElement | null>(null)
  const [expanded, { toggle, close }] = useDisclosure(false)
  const { classes } = useStyles({ expanded })

  useDoubleClick({
    ref: markerNode,
    latency: 250,
    onSingleClick: toggle,
    onDoubleClick: () => {
      if (!expanded && onDoubleClick && clusterId) {
        onDoubleClick(clusterId, longitude, latitude)
      }
    }
  })

  const clickRef = useClickOutside(close)

  useEffect(() => {
    if (clusterId) {
      setPeekedClusterId(expanded
        ? Number(clusterId)
        : undefined)
    }
  }, [expanded])

  return (
    <Marker
      latitude={latitude}
      longitude={longitude}
      anchor="bottom-left"
      style={{
        zIndex: expanded
          ? 99
          : 0
      }}
    >
      <Box ref={clickRef}>
        <Box
          ref={setMarkerNode}
          className={classes.container}
        >
          <Transition
            mounted={!expanded}
            transition="fade"
            duration={250}
            timingFunction="ease"
          >
            {(style) => (
              <Box className={classes.pin} style={style}>
                <Stack spacing={0} justify="center" pos="relative">
                  <Group spacing={4}>
                    <IconHourglass size={14} color={colors.dark[6]} />
                    <Text className={classes.label} color={colors.dark[6]}>{pointCount}</Text>
                  </Group>
                  <Text className={classes.subtitle} color={colors.dark[6]}>{`(${totalMins})`}</Text>
                </Stack>
              </Box>
            )}
          </Transition>
          <Transition
            mounted={expanded}
            transition="fade"
            duration={250}
            timingFunction="ease"
          >
            {(style) => (
              <>
                <Group
                  className={classes.pinExpanded}
                  style={style}
                  spacing={4}
                >
                  <IconHourglass size={24} />
                  <Text className={classes.label} {...getTextColorAsProp()}>{`${pointCount} idle events`}</Text>
                  <Text className={classes.label} {...getTextColorAsProp()}>{`(${totalMins})`}</Text>
                </Group>
                <Box style={style}>
                  <IdleList onSelect={onSelect} list={list} />
                </Box>
              </>
            )}
          </Transition>
        </Box>
      </Box>
    </Marker>
  )
}

export default ClusterMarker
