import {
  Group,
  Paper,
  SimpleGrid,
  Stack,
  Text
} from '@mantine/core'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import { type Agent } from '@venturi-io/api/src/config/agent'
import { checkIfAlarm } from 'src/utils/status'
import { parseEmpty } from 'src/utils/parseEmpty'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AgentAlarmStatus from 'src/Assets/shared/AgentAlarmStatus'
import Link from 'src/Layout/Link'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'

export default function Card ({
  agentId,
  agentName,
  alarmStatus,
  agentStatus,
  connectionStatus,
  lastSeenTime,
  assignedTo
}: Agent & ListRow) {
  const isAlert = checkIfAlarm(alarmStatus)
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint)

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <SimpleGrid cols={2} spacing="lg">
          <TextGroup
            label="Asset Name"
            value={parseEmpty(agentName)}
            maxLength={textMaxLength}
            to={`/assets/duress/${agentId}`}
          />
          <Group
            spacing={10}
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <TextGroup label="Status" value="" />
            <AgentAlarmStatus
              alarmStatus={alarmStatus}
              agentStatus={agentStatus}
              connectionStatus={connectionStatus}
              lastSeenTime={lastSeenTime}
            />
          </Group>
          <Group
            spacing={4}
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <TextGroup label="Alert" value="" />
            <FontAwesomeIcon
              color={isAlert ? 'red' : 'gray'}
              icon={['fas', 'warning']}
              size="lg"
            />
          </Group>
          <Group
            spacing={4}
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <TextGroup label="Location" value="" />
            <Link to={`/assets/duress/${agentId}/map`}>
              <Text size="sm">View Map</Text>
            </Link>
          </Group>
          <TextGroup
            label="Assigned to"
            value={parseEmpty(assignedTo)}
            maxLength={textMaxLength}
          />
        </SimpleGrid>
      </Stack>
    </Paper>
  )
}
