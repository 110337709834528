import { IconFileTypePdf } from '@tabler/icons-react'
import { Button } from '@mantine/core'
import { type DocumentProps } from '@react-pdf/renderer'
import { type ReactElement, useEffect, useState } from 'react'
import { generatePdfDocument } from 'src/Reports/shared'

interface Props {
  fileName: string
  isBuilding?: boolean
  onBuild?: () => void
  renderDocument: () => ReactElement<DocumentProps>
  disabled?: boolean
}

function DownloadAsPdfButton ({
  fileName,
  isBuilding = false,
  onBuild,
  renderDocument,
  disabled = false
}: Props) {
  const [isDownloading, setIsDownloading] = useState(false)

  const handleBuild = () => {
    // Trigger build process first if necessary
    if (onBuild) {
      void onBuild()
    }
  }

  const handleDownload = async () => {
    const document = renderDocument()

    await generatePdfDocument(document, fileName)
    setIsDownloading(false)
  }

  const handleClick = () => {
    void handleBuild()
    setIsDownloading(true)
  }

  useEffect(() => {
    if (!isBuilding && isDownloading) {
      // Trigger download function once build process is completed
      void handleDownload()
    }
  }, [isBuilding, isDownloading])

  return (
    <Button
      loading={isDownloading || isBuilding}
      leftIcon={<IconFileTypePdf size={16} />}
      variant="subtle"
      color="primary"
      onClick={handleClick}
      disabled={disabled}
    >
      Download as PDF
    </Button>
  )
}

export default DownloadAsPdfButton
