import { type Agent, type Sensor } from '@venturi-io/api/src/config/agent'
import { Box, Stack } from '@mantine/core'
import { findSensor } from 'src/AssetTemplate/shared'
import SensorHistoryLineChart from 'src/AssetTemplate/Components/Charts/SensorHistoryLineChart'
import SensorHistoryStackedLineChart from 'src/AssetTemplate/Components/Charts/SensorHistoryStackedLineChart'
import SensorHistoryStackedBarChart from 'src/AssetTemplate/Components/Charts/SensorHistoryStackedBarChart'

interface Props {
  agentId: Agent['agentId']
  sensors: Sensor[]
}

export default function Power ({ agentId, sensors }: Props) {
  const apparentPower = findSensor('APPARENT_POWER', sensors)
  const powerFactor = findSensor('POWER_FACTOR_THREE_PHASE', sensors)
  const powerFrequency = findSensor('FREQUENCY', sensors)
  const voltageLNA = findSensor('RMS_VOLTAGE_L1N', sensors)
  const voltageLNB = findSensor('RMS_VOLTAGE_L2N', sensors)
  const voltageLNC = findSensor('RMS_VOLTAGE_L3N', sensors)
  const voltageLLA = findSensor('RMS_LINE_VOLTAGE_L1_L2', sensors)
  const voltageLLB = findSensor('RMS_LINE_VOLTAGE_L2_L3', sensors)
  const voltageLLC = findSensor('RMS_LINE_VOLTAGE_L3_L1', sensors)
  const currentA = findSensor('RMS_LINE_CURRENT_L1', sensors)
  const currentB = findSensor('RMS_LINE_CURRENT_L2', sensors)
  const currentC = findSensor('RMS_LINE_CURRENT_L3', sensors)
  const disabledVoltageLN = voltageLNA === null ||
    voltageLNB === null ||
    voltageLNC === null
  const disabledVoltageLL = voltageLLA === null ||
    voltageLLB === null ||
    voltageLLC === null
  const disabledCurrent = currentA === null ||
    currentB === null ||
    currentC === null

  return (
    <Box>
      <Stack spacing={28}>
        <SensorHistoryLineChart
          title="Power Capacity (KVA)"
          sensorInstanceId={apparentPower?.sensorInstanceId}
          unit={apparentPower?.unit}
          height={300}
          margin={{
            top: 30,
            right: 20,
            bottom: 50,
            left: 40
          }}
          min="auto"
          max="auto"
          roundedValues
        />
        <SensorHistoryLineChart
          title="Power Factor"
          sensorInstanceId={powerFactor?.sensorInstanceId}
          unit={powerFactor?.unit}
          height={300}
          margin={{
            top: 30,
            right: 20,
            bottom: 50,
            left: 40
          }}
          min="auto"
          max="auto"
        />
        <SensorHistoryLineChart
          title="Power Frequency"
          sensorInstanceId={powerFrequency?.sensorInstanceId}
          unit={powerFrequency?.unit}
          height={300}
          margin={{
            top: 30,
            right: 20,
            bottom: 50,
            left: 40
          }}
        />
        <SensorHistoryStackedLineChart
          title="Voltage (L-N)"
          agentId={agentId}
          sensorInstanceIds={[
            voltageLNA?.sensorInstanceId ?? -1,
            voltageLNB?.sensorInstanceId ?? -1,
            voltageLNC?.sensorInstanceId ?? -1
          ]}
          unit="V"
          height={300}
          margin={{
            top: 30,
            right: 20,
            bottom: 50,
            left: 40
          }}
          unstacked
          disabled={disabledVoltageLN}
        />
        <SensorHistoryStackedLineChart
          title="Voltage (L-L)"
          agentId={agentId}
          sensorInstanceIds={[
            voltageLLA?.sensorInstanceId ?? -1,
            voltageLLB?.sensorInstanceId ?? -1,
            voltageLLC?.sensorInstanceId ?? -1
          ]}
          unit="V"
          height={300}
          margin={{
            top: 30,
            right: 20,
            bottom: 50,
            left: 40
          }}
          unstacked
          disabled={disabledVoltageLL}
        />
        <SensorHistoryStackedBarChart
          title="Current"
          agentId={agentId}
          sensorInstanceIds={[
            currentA?.sensorInstanceId ?? -1,
            currentB?.sensorInstanceId ?? -1,
            currentC?.sensorInstanceId ?? -1
          ]}
          unit="A"
          height={300}
          margin={{
            top: 30,
            right: 20,
            bottom: 50,
            left: 40
          }}
          disabled={disabledCurrent}
        />
      </Stack>
    </Box>
  )
}
