import {
  createStyles,
  Group,
  Pagination as MantinePagination,
  Select,
  type PaginationProps
} from '@mantine/core'
import { useEffect, useState } from 'react'

const useStyles = createStyles(theme => ({
  sizeInput: {
    height: 32,
    fontSize: '1rem'
  },
  item: {
    '&[data-selected]': {
      '&, &:hover': {
        background: theme.colors.primary[5]
      },
      '& .mantine-Text-root, & svg': {
        color: 'white'
      }
    }
  }
}))

interface Props extends PaginationProps {
  sizeValue?: number
  onChangeSize?: (size: number) => void
}

export default function Pagination ({
  color = 'primary',
  total = 0,
  sizeValue: _size,
  onChangeSize,
  ...props
}: Props) {
  const { classes } = useStyles()
  const [size, setSize] = useState<string | null>(_size?.toString() ?? '50')

  useEffect(() => {
    if (onChangeSize) {
      onChangeSize(Number(size))
    }
  }, [size, onChangeSize])

  const hasTotal = total > 0

  return (
    <Group>
      <MantinePagination
        color={color}
        total={total}
        withControls={hasTotal}
        {...props}
      />
      {onChangeSize && hasTotal && (
        <Select
          color="primary"
          classNames={{
            input: classes.sizeInput,
            item: classes.item
          }}
          size="xs"
          mt={props.mt}
          value={size}
          onChange={setSize}
          data={[
            {
              value: '50',
              label: '50'
            },
            {
              value: '100',
              label: '100'
            }
          ]}
          sx={{
            width: 75
          }}
        />
      )}
    </Group>
  )
}
