import {
  createStyles,
  Box,
  Grid,
  Group,
  Stack,
  Text
} from '@mantine/core'
import { type Sensor } from '@venturi-io/api/src/config/agent'
import SensorHistorySparkLineChart from 'src/AssetTemplate/Components/Charts/SensorHistorySparkLineChart'
import AlarmIndicator from 'src/AssetTemplate/Components/AlarmIndicator'
import { formatNumber } from 'src/utils/numbers'
import { mq } from 'src/utils/style'
import { getTextColor, getTextColorAsProp } from 'src/utils/theme'

const useStyles = createStyles((theme) => ({
  container: {
    margin: '0 auto',
    ...getTextColor(theme),
    padding: '24px 28px',
    [mq(theme.breakpoints.md)]: {
      padding: '32px 44px'
    }
  },
  itemContainer: {
    position: 'relative',
    height: '100%'
  },
  textContainer: {
    [mq('1800px')]: {
      maxWidth: 200
    }
  },
  chartContainer: {
    minWidth: 0
  },
  alarmIndicatorContainer: {
    position: 'absolute',
    left: -28,
    bottom: 8
  }
}))

interface Props {
  fuelLevelLiter: Sensor | null
  fuelLevelLiterValue: number
  fuelConsumption: Sensor | null
  fuelConsumptionLiter: number
}

export default function FuelDetails ({
  fuelLevelLiter,
  fuelLevelLiterValue,
  fuelConsumption,
  fuelConsumptionLiter
}: Props) {
  const { classes } = useStyles()
  const fuelLevelLiterHasAlarm = fuelLevelLiter?.alarmStatus === 'ALARM'
  const fuelConsumptionHasAlarm = fuelConsumption?.alarmStatus === 'ALARM'
  const currentLoad = fuelConsumptionLiter > 0
    ? Math.round(fuelLevelLiterValue / fuelConsumptionLiter)
    : '0'
  const disabledFuelLevel = fuelLevelLiter === null
  const disabledFuelConsumption = fuelConsumption === null

  return (
    <Box className={classes.container}>
      <Grid gutterXs={24} gutterXl={48} grow>
        <Grid.Col span={12} xs={6}>
          <Stack className={classes.itemContainer} spacing={4}>
            <Text size={12} weight={500}>
              Fuel Remaining
            </Text>
            <Group spacing={8} grow>
              <Text
                className={classes.textContainer}
                size={24}
                weight={600}
                color={disabledFuelLevel
                  ? 'dimmed'
                  : undefined}
              >
                {!disabledFuelLevel
                  ? `${formatNumber(fuelLevelLiterValue)} L`
                  : 'N/A'}
              </Text>
              <Box className={classes.chartContainer}>
                {fuelLevelLiter?.sensorInstanceId && fuelLevelLiter?.sensorInstanceId !== -1 && (
                  <SensorHistorySparkLineChart
                    sensorInstanceId={fuelLevelLiter.sensorInstanceId}
                    height={50}
                    minValue={0}
                  />
                )}
              </Box>
            </Group>
            {fuelLevelLiterHasAlarm && (
              <Box className={classes.alarmIndicatorContainer}>
                <AlarmIndicator size="sm" />
              </Box>
            )}
          </Stack>
        </Grid.Col>
        <Grid.Col span={12} xs={6}>
          <Stack className={classes.itemContainer} spacing={4}>
            <Text size={12} weight={500}>
              at Current Load
            </Text>
            <Group spacing={8} grow>
              <Text
                className={classes.textContainer}
                size={24}
                weight={600}
                color={disabledFuelLevel
                  ? 'dimmed'
                  : undefined}
              >
                {!disabledFuelLevel
                  ? `${currentLoad} h`
                  : 'N/A'}
              </Text>
            </Group>
          </Stack>
        </Grid.Col>
        <Grid.Col span={12} xs={6}>
          <Stack className={classes.itemContainer} spacing={4}>
            <Text size={12} weight={500}>
              Fuel Consumption
            </Text>
            <Group spacing={8} grow>
              <Text
                className={classes.textContainer}
                size={24}
                weight={600}
                color={disabledFuelConsumption
                  ? 'dimmed'
                  : undefined}
              >
                {!disabledFuelConsumption
                  ? `${formatNumber(fuelConsumptionLiter)} L/h`
                  : 'N/A'}
              </Text>
              <Box className={classes.chartContainer}>
                {fuelConsumption?.sensorInstanceId && fuelConsumption?.sensorInstanceId !== -1 && (
                  <SensorHistorySparkLineChart
                    sensorInstanceId={fuelConsumption.sensorInstanceId}
                    height={50}
                    minValue={0}
                  />
                )}
              </Box>
            </Group>
            {fuelConsumptionHasAlarm && (
              <Box className={classes.alarmIndicatorContainer}>
                <AlarmIndicator size="sm" />
              </Box>
            )}
          </Stack>
        </Grid.Col>
        <Grid.Col span={12} xs={6}>
          <Stack className={classes.itemContainer} spacing={4}>
            <Text
              {...getTextColorAsProp()}
              size={12}
              weight={500}
            >
              at Max Load
            </Text>
            <Group spacing={8} grow>
              {/* TODO: Map proper value for max load once data is available */}
              <Text
                size={24}
                weight={600}
                color={disabledFuelConsumption
                  ? 'dimmed'
                  : undefined}
              >
                {!disabledFuelConsumption
                  ? '-'
                  : 'N/A'}
              </Text>
            </Group>
          </Stack>
        </Grid.Col>
      </Grid>
    </Box>
  )
}
