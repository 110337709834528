import { type ReactNode } from 'react'
import { type Agent } from '@venturi-io/api/src/config/agent'
import {
  type HoverCardProps,
  Group,
  HoverCard,
  Stack,
  Text
} from '@mantine/core'
import {
  type Icon,
  IconAtom2,
  IconBellRinging,
  IconCalendarStats,
  IconPlugConnected
} from '@tabler/icons-react'
import dayjs from 'dayjs'
import { toProperCase } from 'src/utils/strings'

interface DetailProps {
  Icon: Icon
  title: string
  value?: string
  formattedValue?: boolean
}

const Detail = ({
  Icon,
  title,
  value,
  formattedValue = false
}: DetailProps) => (
  <Stack align="start" spacing={4}>
    <Group spacing={6}>
      <Icon size={12} stroke={2} />
      <Text size={10} weight={600}>
        {title}
      </Text>
    </Group>
    <Text size={10} weight={400}>
      {typeof value === 'string'
        ? formattedValue
          ? toProperCase(value)
          : value
        : 'N/A'}
    </Text>
  </Stack>
)

interface Props extends HoverCardProps,
  Pick<Agent, 'alarmStatus' | 'agentStatus' | 'connectionStatus' | 'lastSeenTime'> {
  children: ReactNode
}

export default function AgentStatusDetails ({
  alarmStatus,
  agentStatus,
  connectionStatus,
  lastSeenTime,
  children,
  ...props
}: Props) {
  return (
    <HoverCard {...props} withArrow withinPortal>
      <HoverCard.Target>
        {children}
      </HoverCard.Target>
      <HoverCard.Dropdown p={8}>
        <Stack align="start" spacing={8}>
          <Detail
            Icon={IconAtom2}
            title="Agent Status"
            value={agentStatus}
            formattedValue
          />
          <Detail
            Icon={IconBellRinging}
            title="Alarm Status"
            value={alarmStatus}
            formattedValue
          />
          <Detail
            Icon={IconPlugConnected}
            title="Connectivity Status"
            value={connectionStatus}
            formattedValue
          />
          <Detail
            Icon={IconCalendarStats}
            title="Last Data Transfer"
            value={lastSeenTime
              ? dayjs(lastSeenTime).fromNow()
              : 'N/A'}
          />
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  )
}
