import { Stack, Text } from '@mantine/core'
import TextTooltip from 'src/Layout/TextTooltip'
import Link from '../Link'

interface Props {
  to?: string
  label: string
  value?: string
  maxLength?: number
  isKey?: boolean
}

const TextGroup = ({
  to,
  label,
  value,
  isKey = false,
  maxLength
}: Props) => {
  return (
    isKey
      ? <Text size="sm">{value ?? 'N/A'}</Text>
      : (
        <Stack spacing={1}>
          <Text weight={600} size="sm">{label}</Text>
          {to
            ? (
              <Link to={to}>
                <TextTooltip
                  size="sm"
                  value={value ?? 'N/A'}
                  maxLength={maxLength}
                />
              </Link>
              )
            : (
              <TextTooltip
                size="sm"
                value={value ?? 'N/A'}
                maxLength={maxLength}
              />
              )}
        </Stack>
        )
  )
}

export default TextGroup
