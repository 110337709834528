/* eslint-disable @typescript-eslint/naming-convention */
import { useMantineTheme, Box, Divider } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { mq } from 'src/utils/style'
import { toProperCase } from 'src/utils/strings'
import { type SiteTankLevels } from '../..'
import Table from './Table'
import Cards from './Cards'
import EmptyPlaceholder from './EmptyPlaceholder'

export const formatList = (list: SiteTankLevels['list']): Array<Record<string, unknown>> => (
  list.map(({
    site_name,
    agent_name,
    agent_liquid_type,
    tank_capacity,
    start_time,
    start_volume,
    end_time,
    end_volume
  }) => ({
    Site: toProperCase(site_name),
    Tank: agent_name,
    Fluid: agent_liquid_type,
    'Tank Capacity (L)': tank_capacity,
    'Start Date/Time': start_time,
    'Start Volume (L)': start_volume,
    'End Date/Time': end_time,
    'End Volume (L)': end_volume
  }))
)

export interface ItemProps {
  data: SiteTankLevels['list'][0]
}

export interface Props {
  items: SiteTankLevels['list']
}

export default function List ({ items }: Props) {
  const theme = useMantineTheme()
  const isDesktop = useMediaQuery(mq(theme.breakpoints.sm, false))

  return (
    <Box>
      {isDesktop
        ? <Table items={items} />
        : <Cards items={items} />}
      {items.length === 0 && <EmptyPlaceholder />}
      {isDesktop && <Divider mt={0} mb={4} variant="solid" />}
    </Box>
  )
}
