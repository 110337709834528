/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo } from 'react'
import {
  type ColorScheme,
  createStyles,
  useMantineTheme,
  Box,
  Paper,
  Stack,
  Text
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { type LineSvgProps } from '@nivo/line'
import { mq } from 'src/utils/style'
import Line from 'src/Charts/Line'
import NotApplicableOverlay from 'src/AssetTemplate/Components/NotApplicableOverlay'
import { type TimePeriod } from 'src/Buttons/TimePeriodButton'
import dayjs from 'dayjs'
import { type SiteTankLevels } from '..'

interface DataPoint {
  x: Date
  y?: number
}

const useStyles = createStyles(() => ({
  chartContainer: {
    margin: '24px 4px 8px 4px',
    minWidth: 0,
    position: 'relative'
  },
  tooltip: {
    background: '#212121',
    color: '#FFFFFF'
  }
}))

interface Serie {
  id: string
  data: DataPoint[]
}

interface Props {
  timePeriod: TimePeriod['value'] | null
  items: SiteTankLevels['graph']
  unit?: string
  colorScheme?: ColorScheme
  height: number
  margin?: LineSvgProps['margin']
  unstacked?: boolean
  roundedValues?: boolean
  showLegend?: boolean
  disabled?: boolean
}

export default function Chart ({
  timePeriod,
  items,
  unit,
  colorScheme = 'light',
  height,
  margin,
  unstacked = false,
  roundedValues = false,
  showLegend = false,
  disabled = false
}: Props) {
  const { classes } = useStyles()
  const { breakpoints, colors } = useMantineTheme()
  const isDesktop = useMediaQuery(mq(breakpoints.sm, false))
  const isLight = colorScheme === 'light'
  const tooltipDateFormat = 'MMM D h:mm A'
  const isThirtyDays = timePeriod !== null && timePeriod.includes('30')
  const isNinetyDays = timePeriod !== null && timePeriod.includes('90')
  const legends: LineSvgProps['legends'] = showLegend || isDesktop
    ? [
        {
          anchor: 'center',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 170,
          itemWidth: 180,
          itemHeight: 20,
          itemsSpacing: 8,
          symbolSize: 14,
          symbolShape: 'circle',
          itemDirection: 'left-to-right',
          itemTextColor: '#777'
        }
      ]
    : []
  const data = useMemo(() => (
    items.reduce((
      series: Serie[],
      {
        agent_name,
        timestamp,
        numeric_value
      }) => {
      const existingSerie = series.find(({ id }) => id === agent_name)
      const parsedNumValue = parseFloat(numeric_value)
      const yValue = isNaN(parsedNumValue)
        ? 0
        : parsedNumValue
      const newDataPoint: DataPoint = {
        x: new Date(timestamp),
        y: roundedValues
          ? Math.round(yValue)
          : yValue
      }

      if (existingSerie) {
        return series.map((serie) => (serie.id === existingSerie.id
          ? {
              ...existingSerie,
              data: [
                ...existingSerie.data,
                newDataPoint
              ].sort((a, b) => (
                a.x > b.x
                  ? 1
                  : -1
              ))
            }
          : serie
        ))
      }

      return [
        ...series,
        {
          id: agent_name,
          data: [newDataPoint]
        }
      ]
    }, [])
  ), [items])

  return (
    <Box className={classes.chartContainer}>
      {disabled && (
        <NotApplicableOverlay
          colorScheme={colorScheme}
          size="md"
        />
      )}
      <Line
        data={data}
        height={height}
        colors={{
          scheme: 'tableau10'
        }}
        margin={margin}
        xScale={{
          type: 'time',
          format: '%Y-%M-%d'
        }}
        yScale={{
          type: 'linear',
          min: 0,
          max: 'auto',
          stacked: !unstacked,
          reverse: false
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          format: (value) => dayjs(value).format('DD/MM'),
          tickValues: isThirtyDays
            ? 'every 3 days'
            : isNinetyDays
              ? 'every 6 days'
              : 'every 1 day',
          tickSize: 0,
          tickPadding: 12,
          tickRotation: isDesktop
            ? 0
            : -50,
          legendOffset: 36,
          legendPosition: 'middle',
          truncateTickAt: 0
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 12,
          tickRotation: 0,
          legendOffset: -40,
          legendPosition: 'middle',
          truncateTickAt: 0
        }}
        enableGridX={false}
        pointSize={8}
        pointColor={{
          from: 'color',
          modifiers: []
        }}
        pointBorderWidth={1}
        pointBorderColor="#ffffff"
        pointLabel="data.yFormatted"
        pointLabelYOffset={-11}
        enableArea
        areaOpacity={0.15}
        enableTouchCrosshair={true}
        crosshairType="x"
        useMesh={data.length > 1}
        legends={legends}
        motionConfig="gentle"
        curve="monotoneX"
        theme={{
          axis: {
            ticks: {
              text: {
                fontSize: 10,
                fontWeight: 400,
                fill: '#909296'
              }
            }
          },
          grid: {
            line: {
              stroke: isLight
                ? colors.gray[2]
                : colors.dark[4]
            }
          },
          crosshair: {
            line: {
              stroke: isLight
                ? colors.dark[4]
                : colors.gray[2]
            }
          }
        }}
        tooltip={({ point }) => (
          <Paper
            className={classes.tooltip}
            px={12}
            py={8}
            radius="sm"
            shadow="sm"
          >
            <Text size={12} weight={500} color={point.color}>
              {point.serieId}
            </Text>
            <Text size={12} weight={500}>
              {`${dayjs(point.data.x).format(tooltipDateFormat)}`}
            </Text>
            <Text size={16} weight={600}>
              {`${point.data.y ?? 0} ${unit ?? ''}`}
            </Text>
          </Paper>
        )}
        enableSlices="x"
        sliceTooltip={({
          slice: {
            points
          }
        }) => (
          <Paper
            className={classes.tooltip}
            px={12}
            py={8}
            radius="sm"
            shadow="sm"
          >
            <Stack spacing={8}>
              {points.map(({ color, serieId, data }) => (
                <Box key={serieId}>
                  <Text size={12} weight={500} color={color}>
                    {serieId}
                  </Text>
                  <Text size={12} weight={500}>
                    {`${dayjs(data.x).format(tooltipDateFormat)}`}
                  </Text>
                  <Text size={16} weight={600}>
                    {`${data.y ?? 0} ${unit ?? ''}`}
                  </Text>
                </Box>
              ))}
            </Stack>
          </Paper>
        )}
      />
    </Box>
  )
}
