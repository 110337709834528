import React, { type CSSProperties } from 'react'
import {
  Box,
  createStyles,
  Flex,
  Group,
  Stack,
  Text,
  useMantineTheme
} from '@mantine/core'
import { IconPointFilled } from '@tabler/icons-react'
import { type AgentLocation } from '@venturi-io/api/src/collector/agent'
import dayjs from 'dayjs'
import { uiDateFormat } from 'src/utils/dates'
import { getBackgroundColor } from 'src/utils/theme'

const useStyles = createStyles(theme => ({
  container: {
    cursor: 'pointer',
    '&:hover': {
      ...getBackgroundColor(theme, -1)
    }
  }
}))

interface Props {
  data: AgentLocation
  style: CSSProperties
  onClickLocation: (point: AgentLocation) => void
}

interface LabelValueProps {
  label: string
  value?: string | number
}

const LabelValue = ({ label, value }: LabelValueProps) => (
  <Stack spacing={0}>
    <Text
      size="xs"
      color="dimmed"
      weight={500}
    >
      {label}
    </Text>
    <Text size="sm">{value}</Text>
  </Stack>
)

function Row ({
  style,
  data,
  onClickLocation
}: Props) {
  const { classes } = useStyles()
  const { colors } = useMantineTheme()

  const { timestamp, geoLocation } = data
  const { longitude, latitude } = geoLocation

  return (
    <Stack
      className={classes.container}
      spacing={4}
      style={style}
      onClick={() => {
        onClickLocation(data)
      }}
    >
      <Flex my="xs" direction="row" align="center">
        <Box w={34}>
          <IconPointFilled size={24} color={colors.primary[6]} />
        </Box>
        <Stack spacing="xs">
          <Text size="xs" color="dimmed">
            {dayjs(timestamp).format(uiDateFormat)}
          </Text>
          <Group
            sx={{
              flexGrow: 1
            }}
          >
            <LabelValue
              label="Longitude"
              value={longitude}
            />
            <LabelValue
              label="Latitude"
              value={latitude}
            />
          </Group>
        </Stack>
      </Flex>
    </Stack>
  )
}

export default Row
