import { Box, createStyles } from '@mantine/core'
import { type AgentLocation } from '@venturi-io/api/src/collector/agent'
import React, { useEffect, useState } from 'react'
import { AutoSizer, List, type ListRowProps } from 'react-virtualized'
import Row from './Row'

interface Props {
  items: AgentLocation[]
  onClickLocation: (point: AgentLocation) => void
}

const useStyle = createStyles(() => ({
  list: {
    scrollbarWidth: 'thin'
  }
}))

const LocationsTable = ({ items, onClickLocation }: Props) => {
  const [cursor, setCursor] = useState(0)
  const { classes } = useStyle()

  useEffect(() => {
    setCursor(0)
  }, [items])

  return (
    <Box
      sx={{
        height: '100%',
        scrollbarGutter: 'stable',
        flex: 1
      }}
    >
      <AutoSizer>
        {({ width, height }) => (
          <List
            className={classes.list}
            height={height}
            width={width + 15}
            rowCount={items.length}
            rowHeight={89}
            itemData={items}
            scrollToIndex={cursor}
            rowRenderer={({ index, key, style }: ListRowProps) => {
              const row = items[index]

              return (
                <Row
                  key={`${row.timestamp}-${key}`}
                  data={row}
                  onClickLocation={onClickLocation}
                  style={{
                    ...style,
                    paddingTop: 7,
                    width: width - 7
                  }}
                />
              )
            }}
          />
        )}
      </AutoSizer>
    </Box>
  )
}

export default LocationsTable
