import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { type IncidentBreach } from '@venturi-io/api/src/config/alarmHistory'
import ReactMapGl, {
  Layer as MapLayer,
  type LayerProps,
  Source,
  type SourceProps,
  type MapRef
} from 'react-map-gl'
import { MapType } from 'src/Maps/StylePicker'
import Popup from './Popup'
import type mapboxgl from 'mapbox-gl'

type MouseEvent = mapboxgl.MapMouseEvent & {
  features?: mapboxgl.MapboxGeoJSONFeature[] | undefined
} & mapboxgl.EventData

export interface RawPointSourceProps {
  color: string
  data: IncidentBreach
  longitude: number
  latitude: number
}
interface Props {
  breaches: IncidentBreach[]
}

function BreachMap ({ breaches }: Props) {
  // useRef in this case didn't do good enough job to retrigger once the map was loaded
  // using useState instead
  const [mapRef, setMapRef] = useState<MapRef | null>()
  const filteredPoints = breaches.filter(({ agentLocationPoint }) => agentLocationPoint)
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  const [properties, setProperties] = useState<RawPointSourceProps | null>(null)

  const map = useMemo(() => mapRef?.getMap(), [mapRef])

  const point = useMemo(() => filteredPoints[0]?.agentLocationPoint, [filteredPoints[0]])

  const breachesPoints = useMemo((): SourceProps => {
    const features = filteredPoints.map((data) => {
      const { agentLocationPoint: point } = data
      const properties = {
        color: 'red',
        data
      }

      let longitude = 0
      let latitude = 0
      if (point?.longitude && point?.latitude) {
        const { longitude: lng, latitude: lat } = point
        longitude = lng
        latitude = lat
      }

      return (
        {
          type: 'Feature',
          properties,
          geometry: {
            coordinates: [
              longitude,
              latitude
            ],
            type: 'Point'
          }
        }
      )
    })

    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features
      }
    }
  }, [filteredPoints])

  const circleLayer: LayerProps = {
    id: 'circle-point',
    type: 'circle',
    paint: {
      'circle-color': ['get', 'color'],
      'circle-opacity': 1,
      'circle-radius': 8,
      'circle-stroke-color': '#fff',
      'circle-stroke-opacity': 1,
      'circle-stroke-width': 2
    }
  }

  const handleHoverEvent = useCallback((pointer: string) => () => {
    if (map) {
      map.getCanvas().style.cursor = pointer
    }
  }, [map])

  const handlePointClick = useCallback((e: MouseEvent) => {
    if (map) {
      // Copy coordinates array.
      if (e.features) {
        const feature = e.features[0]
        const { properties, geometry } = feature
        if (properties && geometry.type === 'Point') {
          const props = properties as RawPointSourceProps
          const coordinates = geometry.coordinates.slice()
          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0]
              ? 360
              : -360
          }

          setProperties(() => {
            setIsPopupOpen(true)

            return {
              ...props,
              latitude: coordinates[1],
              longitude: coordinates[0]
            }
          })
        }
      }
    }
  }, [map])

  useEffect(() => {
    if (map) {
      map.on('mouseleave', ['circle-point'], handleHoverEvent(''))
      map.on('mouseenter', ['circle-point'], handleHoverEvent('pointer'))
      map.on('click', ['circle-point'], handlePointClick)
    }

    return () => {
      if (map) {
        map.off('mouseleave', ['circle-point'], handleHoverEvent(''))
        map.off('mouseenter', ['circle-point'], handleHoverEvent('pointer'))
        map.off('click', ['circle-point'], handlePointClick)
      }
    }
  }, [mapRef])

  return point && (
    <ReactMapGl
      ref={(ref) => setMapRef(ref)}
      interactiveLayerIds={['circle-point']}
      initialViewState={{
        longitude: point.longitude,
        latitude: point.latitude,
        zoom: 14
      }}
      style={{
        width: '100%',
        height: 300
      }}
      attributionControl={false}
      transformRequest={(url) => ({
        url,
        referrerPolicy: 'strict-origin-when-cross-origin'
      })}
      mapboxAccessToken={process.env.REACT_APP_APIKEY_MAPBOX}
      mapStyle={MapType.monochrome}
      logoPosition="top-right"
    >
      <Popup
        isOpen={isPopupOpen}
        setIsOpen={setIsPopupOpen}
        properties={properties}
        map={map}
      />
      {breachesPoints && (
        <Source {...breachesPoints}>
          <MapLayer {...circleLayer} />
        </Source>
      )}
    </ReactMapGl>
  )
}

export default BreachMap
