import { type SensorDataLog } from '@venturi-io/api/src/collector/sensor'
import dayjs from 'dayjs'
import { uiDateFormat } from 'src/utils/dates'
import { type FormattedSensorLog } from '.'

export const formatLogsToCsv = (logs: SensorDataLog[]) => logs.map(
  ({
    agentName,
    eventLog,
    sensorId,
    sensorName,
    sensorInstanceId,
    sensorInstanceName,
    timestamp
  }): FormattedSensorLog => ({
    Timestamp: dayjs(timestamp).format(uiDateFormat),
    Agent: agentName,
    'Sensor ID': sensorId,
    Sensor: sensorName,
    'Sensor Instance ID': sensorInstanceId,
    'Sensor Instance': sensorInstanceName,
    Value: eventLog
  }))
