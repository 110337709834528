import { Box, Group, Skeleton, Stack } from '@mantine/core'

const Item = () => <Skeleton height={24} radius={8} />

const Row = () => (
  <Group position="apart" spacing={16}>
    <Group
      sx={{
        flex: 3
      }}
      spacing={12}
      grow
    >
      <Item />
      <Item />
      <Item />
    </Group>
    <Group
      sx={{
        flex: 1
      }}
      spacing={12}
      grow
    >
      <Item />
      <Item />
    </Group>
  </Group>
)

export default function SkeletonLoader () {
  return (
    <Box>
      <Stack spacing={16}>
        <Skeleton height={48} width="100%" radius={8} />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
      </Stack>
    </Box>
  )
}
