import {
  type DialogProps,
  createStyles,
  Badge,
  Dialog,
  Divider,
  Group,
  Stack,
  Text
} from '@mantine/core'
import { useClickOutside } from '@mantine/hooks'
import { IconCpu2 } from '@tabler/icons-react'
import { toProperCase } from 'src/utils/strings'
import { sensorTypes, type SensorType } from '../shared'

const useStyles = createStyles(() => ({
  closeButton: {
    top: 16,
    right: 16
  },
  listContainer: {
    marginTop: 16,
    height: 'auto',
    maxHeight: 260,
    overflowY: 'auto',
    scrollbarWidth: 'thin'
  }
}))

interface Props extends DialogProps {
  data: SensorType[]
}

export default function MissingSensors ({
  data,
  opened = false,
  onClose
}: Props) {
  const { classes } = useStyles()
  const ref = useClickOutside(() => {
    if (onClose) {
      onClose()
    }
  })

  return (
    <Dialog
      ref={ref}
      classNames={{
        closeButton: classes.closeButton
      }}
      shadow="xl"
      opened={opened}
      onClose={onClose}
      withCloseButton
    >
      <Group spacing={8}>
        <Text size={16} weight={500}>
          Missing Sensors
        </Text>
        <Badge px={7} variant="filled" color="red">
          {data.length}
        </Badge>
      </Group>
      <Divider my={10} size="sm" variant="dotted" />
      {data.length > 0
        ? (
          <Stack
            className={classes.listContainer}
            spacing={12}
            align="flex-start"
          >
            {data.map((sensor) => (
              <Group
                key={sensor}
                spacing={4}
                position="center"
                align="center"
              >
                <IconCpu2 size={12} stroke={1.5} />
                <Text size={12} weight={400}>
                  {`${sensorTypes[sensor]}: ${toProperCase(sensor)}`}
                </Text>
              </Group>
            ))}
          </Stack>
          )
        : (
          <Text
            my={24}
            size={12}
            weight={400}
            color="silver"
            align="center"
          >
            No data available
          </Text>
          )}
    </Dialog>
  )
}
