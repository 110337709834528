import { useMemo } from 'react'
import { type AgentDetails } from '@venturi-io/api/src/config/agent'
import {
  useMantineTheme,
  createStyles,
  Paper,
  Text,
  Stack,
  Badge,
  Group
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import Breadcrumbs, { type BreadcrumbsItem } from 'src/Layout/Breadcrumbs'
import { mq } from 'src/utils/style'
import zIndex from 'src/utils/zIndex'
import TextTooltip from 'src/Layout/TextTooltip'
import { type FullIncidentReport } from '@venturi-io/api/src/config/alarmHistory'

const useStyles = createStyles((theme) => ({
  container: {
    position: 'sticky',
    top: 0,
    margin: 0,
    padding: 16,
    transition: 'all .3s ease',
    zIndex: zIndex.assetTemplateHeader,
    [mq(theme.breakpoints.xs)]: {
      margin: '0 16px',
      padding: 24,
      height: 128
    }
  },
  assetName: {
    fontSize: 20,
    [mq(theme.breakpoints.sm)]: {
      fontSize: 24
    }
  },
  metricsContainer: {
    position: 'absolute',
    bottom: 0
  }
}))

interface Props {
  agentName: AgentDetails['agentName']
  incidentName: string
  cleared?: FullIncidentReport['cleared']
}

export default function Header ({
  agentName,
  incidentName,
  cleared
}: Props) {
  const { classes } = useStyles()
  const {
    breakpoints: {
      sm,
      md,
      lg,
      xl
    }
  } = useMantineTheme()
  const isSmallScreen = useMediaQuery(mq(sm, false))
  const isMediumScreen = useMediaQuery(mq(md, false))
  const isLargeScreen = useMediaQuery(mq(lg, false))
  const isExtraLargeScreen = useMediaQuery(mq(xl, false))
  const nameMaxLength = useMemo(() => {
    let maxLength: number | undefined = 36

    if (isSmallScreen) {
      maxLength = 16
    }

    if (isMediumScreen) {
      maxLength = 26
    }

    if (isLargeScreen) {
      maxLength = 40
    }

    if (isExtraLargeScreen) {
      maxLength = undefined
    }

    return maxLength
  }, [
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isExtraLargeScreen
  ])

  const breadcrumbs: BreadcrumbsItem[] = [
    {
      title: 'Alarm history',
      href: '/alarms'
    },
    { title: 'Incident' }
  ]

  const hasResolved = useMemo(() => cleared ?? false, [])

  return (
    <Paper className={classes.container} radius="xs" shadow="xs">
      <Breadcrumbs items={breadcrumbs} />
      <Stack spacing={0} pt="sm">
        <Group align="center">
          <TextTooltip
            className={classes.assetName}
            weight={600}
            value={agentName}
            maxLength={nameMaxLength}
          />
          {hasResolved
            ? (
              <Badge
                size="lg"
                variant="filled"
                color="green"
              >
                Resolved
              </Badge>
              )
            : (
              <Badge
                size="lg"
                variant="filled"
                color="red"
              >
                Active
              </Badge>
              )}
        </Group>
        <Text
          size="sm"
          weight="bold"
          color="red"
        >
          {incidentName}
        </Text>
      </Stack>
    </Paper>
  )
}
