import React, {
  createContext,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useState
} from 'react'

export interface AssetContextProps {
  taskCount: number
  missingSensorCount: number
  showMissingSensors: boolean
  setTaskCount?: Dispatch<SetStateAction<number>>
  setMissingSensorCount: Dispatch<SetStateAction<number>>
  setShowMissingSensors: Dispatch<SetStateAction<boolean>>
}

export const AssetContext = createContext<AssetContextProps>({
  taskCount: 0,
  missingSensorCount: 0,
  showMissingSensors: false,
  setMissingSensorCount: () => 0,
  setShowMissingSensors: () => false
})

interface Props {
  children: ReactNode | JSX.Element
}

function Context ({ children }: Props) {
  const [taskCount, setTaskCount] = useState(0)
  const [missingSensorCount, setMissingSensorCount] = useState(0)
  const [showMissingSensors, setShowMissingSensors] = useState(false)

  return (
    <AssetContext.Provider
      value={{
        taskCount,
        missingSensorCount,
        showMissingSensors,
        setTaskCount,
        setMissingSensorCount,
        setShowMissingSensors
      }}
    >
      {children}
    </AssetContext.Provider>
  )
}

export default Context
