import React, {
  type Dispatch,
  type RefObject,
  type SetStateAction,
  useEffect,
  useState
} from 'react'
import SelectFromTo from 'src/Input/Select/SelectFromTo'
import { type Range } from '@venturi-io/api'
import {
  Alert,
  Button,
  Loader,
  Stack,
  Text
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  type AgentLocation,
  type AgentLocationHistoryRequest,
  getAgentLocationHistory
} from '@venturi-io/api/src/collector/agent'
import { useApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import dayjs from 'dayjs'
import { IconAlertCircle } from '@tabler/icons-react'
import { dateFormat } from 'src/utils/dates'
import { type MapRef } from 'react-map-gl'
import { useViewTypes } from '../AgentAttendanceContext'
import LocationsTable from './LocationsTable'

interface Props {
  agentId: number
  mapRef: RefObject<MapRef>
  onFetch: Dispatch<SetStateAction<AgentLocation[]>>
}

const Locations = ({ agentId, mapRef, onFetch }: Props) => {
  const { setSelectedLocation } = useViewTypes()
  const { token } = useUser()
  const getHistory = useApi(getAgentLocationHistory)
  const [timeRange, setTimeRange] = useState<Range | null>(null)
  const [locationData, setLocationData] = useState<AgentLocation[]>([])

  const isMoreThan90Days = timeRange
    ? dayjs(timeRange.to).diff(timeRange.from, 'days') > 90
    : false

  const handleClickLocation = (data: AgentLocation) => {
    const {
      geoLocation: {
        longitude,
        latitude
      }
    } = data
    if (mapRef.current) {
      const zoom = mapRef.current.getZoom()
      mapRef.current?.easeTo({
        zoom: Math.max(zoom, 10),
        center: [
          longitude,
          latitude
        ]
      })
    }
    if (setSelectedLocation) {
      setSelectedLocation(data)
    }
  }

  useEffect(() => {
    if (timeRange) {
      const { from, to } = timeRange
      const req: AgentLocationHistoryRequest = {
        agentId,
        noOfRecords: 99999,
        startTime: isMoreThan90Days
          ? dayjs(to)
            .subtract(90, 'day')
            .format(dateFormat)
          : from,
        endTime: to
      }
      void getHistory.fetch(req, token)
    }
  }, [timeRange, agentId])

  useEffect(() => {
    getHistory.data.ifJust(({ items }) => {
      setLocationData(items)
    })
  }, [getHistory.data])

  useEffect(() => {
    if (locationData.length > 0) {
      onFetch(locationData)
    }
  }, [locationData])

  return (
    <Stack spacing="xs" h="100%">
      {isMoreThan90Days && (
        <Alert
          icon={<IconAlertCircle size="1rem" />}
          color="yellow"
          variant="outline"
          sx={{
            flexShrink: 1
          }}
        >
          <Text size="xs">
            There is only 90 days of information available when selecting a range outside of this range
          </Text>
        </Alert>
      )}
      <SelectFromTo
        size="xs"
        withTime
        dateRange={timeRange}
        onChangeRange={date => {
          if (date && setTimeRange) {
            setTimeRange(date)
          }
        }}
        applyButton={(submit, disabled) => (
          <Button
            size="xs"
            disabled={disabled}
            onClick={submit}
            color="primary"
            leftIcon={<FontAwesomeIcon icon={['far', 'rotate-right']} />}
          >
            Apply range
          </Button>
        )}
      />
      {getHistory.loading && <Loader size="sm" color="primary" />}
      {locationData.length
        ? <LocationsTable items={locationData} onClickLocation={handleClickLocation} />
        : (
          <Text size="xs" color="dimmed">
            Please select a range
          </Text>
          )}

    </Stack>
  )
}

export default Locations
