/* eslint-disable @typescript-eslint/naming-convention */
import {
  createStyles,
  useMantineTheme,
  Accordion,
  Box,
  Group,
  Text
} from '@mantine/core'
import { getWhiteBackgroundColor } from 'src/utils/theme'
import { IconPrinter, IconSitemap } from '@tabler/icons-react'
import { type Report } from '@venturi-io/api/src/config/report'
import { type Range } from '@venturi-io/api'
import { toProperCase } from 'src/utils/strings'
import { downloadCSV } from 'src/utils/files'
import { type TimePeriod } from 'src/Buttons/TimePeriodButton'
import { isEmpty } from 'src/utils/objects'
import { type SiteTankLevels } from '..'
import ExportButton from '../../ExportButton'
import List, { formatList } from './List'
import Chart from './Chart'

const useStyle = createStyles((theme) => ({
  root: {
    ...getWhiteBackgroundColor(theme)
  },
  panel: {
    ...getWhiteBackgroundColor(theme)
  }
}))

interface Props {
  report: Report
  timePeriod: TimePeriod['value'] | null
  range: Range | null
  reportData: Record<string, Array<Record<string, string | null>>>
  data: SiteTankLevels[]
}

export default function Content ({
  report,
  timePeriod,
  range,
  reportData,
  data
}: Props) {
  const { colorScheme } = useMantineTheme()
  const { classes } = useStyle()
  const disabledDownload = isEmpty(reportData)

  return (
    <Accordion
      className={classes.root}
      styles={{
        chevron: {
          marginLeft: 2
        }
      }}
      defaultValue={[data[0].site_id.toString()]}
      variant="filled"
      radius="xs"
      multiple
    >
      {data.map((item) => {
        const { name } = report
        const {
          site_id,
          site_name,
          list,
          graph
        } = item
        const siteName = toProperCase(site_name)

        return (
          <Accordion.Item key={site_id} value={site_id.toString()}>
            <Accordion.Control
              icon={(
                <IconSitemap
                  size={18}
                  stroke={1.8}
                  color="#228BE6"
                />
              )}
            >
              <Group position="apart" align="center">
                <Text weight={500} size={14}>
                  {siteName}
                </Text>
                {/* Prevents accordion from toggling if the export button has been clicked */}
                <Box onClick={event => event.stopPropagation()}>
                  <ExportButton
                    Icon={IconPrinter}
                    report={report}
                    timePeriod={timePeriod}
                    range={range}
                    reportData={reportData}
                    data={[item]}
                    onClickDownloadAsCSV={() => {
                      downloadCSV(formatList(list), `${siteName} - ${name}`)
                    }}
                    disabled={disabledDownload}
                  />
                </Box>
              </Group>
            </Accordion.Control>
            <Accordion.Panel className={classes.panel}>
              <List items={list} />
              <Chart
                timePeriod={timePeriod}
                items={graph}
                unit="L"
                colorScheme={colorScheme}
                height={350}
                margin={{
                  top: 30,
                  right: 20,
                  bottom: 70,
                  left: 40
                }}
                unstacked
                disabled={graph.length === 0}
              />
            </Accordion.Panel>
          </Accordion.Item>
        )
      })}
    </Accordion>
  )
}
