import { type Sensor } from '@venturi-io/api/src/config/agent'
import { Box, Stack } from '@mantine/core'
import { findSensor } from 'src/AssetTemplate/shared'
import SensorHistoryBarChart from 'src/AssetTemplate/Components/Charts/SensorHistoryBarChart'

interface Props {
  sensors: Sensor[]
}

export default function Fuel ({ sensors }: Props) {
  const fuelLevelLiter = findSensor('FUEL_LEVEL_L', sensors)
  const fuelConsumption = findSensor('FUEL_CONSUMPTION', sensors)

  return (
    <Box>
      <Stack spacing={28}>
        <SensorHistoryBarChart
          title="Fuel Level (L)"
          sensorInstanceId={fuelLevelLiter?.sensorInstanceId}
          unit={fuelLevelLiter?.unit}
          height={300}
          margin={{
            top: 30,
            right: 20,
            bottom: 50,
            left: 60
          }}
          roundedValues
        />
        <SensorHistoryBarChart
          title="Fuel Consumption"
          sensorInstanceId={fuelConsumption?.sensorInstanceId}
          unit={fuelConsumption?.unit}
          height={300}
          margin={{
            top: 30,
            right: 20,
            bottom: 50,
            left: 60
          }}
          roundedValues
        />
      </Stack>
    </Box>
  )
}
