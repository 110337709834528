/* eslint-disable @typescript-eslint/naming-convention */
import { useNotifications } from 'src/utils/notifications'
import { useCallback, useState } from 'react'
import { Collapse, Text, useMantineTheme } from '@mantine/core'
import { useUser } from 'src/UserContext'
import { useDisclosure } from '@mantine/hooks'
import { reverseGeocode } from 'src/Maps/GeoZoneMap/mapbox'
import dayjs from 'dayjs'
import { toUtc, uiDateFormat } from 'src/utils/dates'
import { downloadCSV } from 'src/utils/files'
import { generatePdfDocument } from 'src/Reports/shared'
import { getLinkTextColor, getToggleableColorWBG } from 'src/utils/theme'
import { getAgentTrips, type TripSummary } from '@venturi-io/api/src/collector/trip'
import { secondsToDhms } from 'src/Assets/shared'
import { round } from 'src/utils/math'
import Trips from 'src/Assets/VehicleDashboard/Trips'
import RowExportButton, { type SuccessData } from '../RowExportButton'
import ExportablePdf from './ExportablePdf'
import { type AgentTripItem, type ParsedTripItem, type Props } from '.'

interface RowProps extends Pick<Props, 'range' | 'maxWidth'> {
  trip: ParsedTripItem
}

const Row = ({ trip, range }: RowProps) => {
  const {
    agent_id,
    agent_name,
    alarm_status,
    total_trips,
    total_duration,
    total_distance_in_kilometres
  } = trip
  const theme = useMantineTheme()
  const { token } = useUser()
  const { showError } = useNotifications()
  const [tableHeight, setTableHeight] = useState(200)
  const [open, { toggle }] = useDisclosure(false)

  const loadTrips = async (onSuccess: (trips: TripSummary[]) => void) => {
    // I need to call it asynchrounusly and get the result on done fetching.
    // Saving to state causes delay and might not load the info correctly on the pdf
    await getAgentTrips({
      agentId: Number(agent_id),
      page: 1,
      size: 999,
      startTime: toUtc(range?.from ?? dayjs().subtract(24, 'hour')) + 'Z',
      endTime: toUtc(range?.to ?? dayjs()) + 'Z'
    }, token).caseOf({
      Left: err => showError(err ?? 'Error on fetching idle trips'),
      Right: ({ items }) => onSuccess(items)
    })
  }

  const downloadableData = useCallback(async (
    type: 'CSV' | 'PDF',
    data: TripSummary[]
  ) => await Promise.all(data.map(async ({
    tripId,
    startTime,
    endTime,
    distance,
    startPoint,
    endPoint,
    rfidTag,
    user,
    speedMax,
    speedAvg,
    drivingDuration,
    idlingDuration,
    xAccelerationMax,
    yAccelerationMax,
    zAccelerationMax
  }) => {
    try {
      const start_location = await reverseGeocode([startPoint.longitude, startPoint.latitude])
      const end_location = await reverseGeocode([endPoint.longitude, endPoint.latitude])

      return {
        trip_id: tripId,
        start_time: dayjs(startTime).format(uiDateFormat),
        end_time: dayjs(endTime).format(uiDateFormat),
        asset: agent_name,
        rfid: rfidTag ?? '-',
        driver: user
          ? `${user.firstName} ${user.lastName}`
          : '-',
        start_location,
        end_location,
        driving_duration: secondsToDhms(drivingDuration ?? 0).toString(),
        idling_duration: secondsToDhms(idlingDuration ?? 0).toString(),
        distance_travelled: `${round(distance / 1000)} kms`,
        average_speed: speedAvg
          ? `${round(speedAvg)} Kmh`
          : '-',
        max_speed: speedMax
          ? `${round(speedMax)} Kmh`
          : '-',
        max_x_acceleration: xAccelerationMax
          ? round(xAccelerationMax).toString()
          : '-',
        max_y_acceleration: yAccelerationMax
          ? round(yAccelerationMax).toString()
          : '-',
        max_z_acceleration: zAccelerationMax
          ? round(zAccelerationMax).toString()
          : '-'
      }
    } catch (err: any) {
      throw Error('Failed to generate trips report')
    }
  })), [])

  const handleCSV = async (data: SuccessData) => {
    const events = await downloadableData('CSV', data as TripSummary[])
    downloadCSV(events, `${agent_name} - Trip History Report`)
  }

  const handleDownloadPdf = () => async (data: SuccessData) => {
    const events = await downloadableData(
      'PDF',
      data as TripSummary[]
    ) as never as AgentTripItem[]
    await generatePdfDocument((
      <ExportablePdf
        agentName={agent_name}
        data={events}
        dateRange={range}
        totalTrips={events.length.toString()}
        totalDuration={total_duration}
        totalDistance={total_distance_in_kilometres}
      />
    ), `${agent_name} - Trip History Report`)
  }

  return (
    <>
      <tr
        onClick={toggle}
        style={{
          cursor: 'pointer',
          backgroundColor: getToggleableColorWBG(open),
          borderBottom: 1,
          position: open
            ? 'sticky'
            : 'initial',
          top: 34,
          zIndex: open
            ? 100
            : 'unset'
        }}
      >
        <td>
          <Text
            {...getLinkTextColor(theme)}
            weight={open
              ? 'bold'
              : 'normal'}
          >
            {agent_name}
          </Text>
        </td>
        <td>{alarm_status}</td>
        <td>{total_trips}</td>
        <td>{total_duration}</td>
        <td>{total_distance_in_kilometres}</td>
        <RowExportButton
          skipMapBuild
          agent_id={agent_id}
          agent_name={agent_name}
          loadData={loadTrips}
          onDownloadCsv={handleCSV}
          onDownloadPdf={handleDownloadPdf}
        />
      </tr>
      <tr>
        <td
          colSpan={6}
          style={{
            padding: 0,
            borderTop: 0,
            borderBottom: open
              ? 1
              : 0
          }}
        >
          <Collapse in={open}>
            {open && (
              <Trips
                showTitle={false}
                agentId={parseInt(agent_id)}
                startTime={range?.from}
                endTime={range?.to}
                hideRfid
                style={{
                  // computation for sidebar: 60px + container and paper padding (40px & 36px)
                  width: 'calc(100vw - 136px)',
                  overflowY: 'hidden',
                  overflowX: 'auto',
                  scrollbarWidth: 'thin',
                  height: tableHeight,
                  margin: 0
                }}
                didChangeHeight={(height) => {
                  setTableHeight(height)
                }}
              />
            )}
          </Collapse>
        </td>
      </tr>
    </>
  )
}

export default Row
