/* eslint-disable @typescript-eslint/naming-convention */
import Link from 'src/Layout/Link'
import {
  useMantineTheme,
  Group,
  Table as MantineTable,
  Text,
  ThemeIcon
} from '@mantine/core'
import { IconCylinder } from '@tabler/icons-react'
import { getLinkTextColor, getWhiteBackgroundColor } from 'src/utils/theme'
import { type Props as ListProps, type ItemProps } from '.'

const Row = ({
  data: {
    agent_id,
    agent_name,
    agent_liquid_type,
    tank_capacity,
    start_time,
    start_volume,
    end_time,
    end_volume
  }
}: ItemProps) => {
  const theme = useMantineTheme()

  return (
    <tr>
      <td>
        <Group ml={-4} spacing={4} noWrap>
          <ThemeIcon
            sx={{
              background: 'transparent'
            }}
            variant="light"
            color="primary"
          >
            <IconCylinder size={18} stroke={2} />
          </ThemeIcon>
          <Text
            component={Link}
            to={`/assets/tank/${agent_id}`}
            size={14}
            weight={500}
            {...getLinkTextColor(theme)}
          >
            {agent_name}
          </Text>
        </Group>
      </td>
      <td>{agent_liquid_type}</td>
      <td>{tank_capacity}</td>
      <td>{start_time}</td>
      <td>{start_volume}</td>
      <td>{end_time}</td>
      <td>{end_volume}</td>
    </tr>
  )
}

export default function Table ({ items }: ListProps) {
  const theme = useMantineTheme()

  return (
    <MantineTable
      sx={{
        ...getWhiteBackgroundColor(theme),
        position: 'relative'
      }}
    >
      <thead
        style={{
          ...getWhiteBackgroundColor(theme),
          position: 'sticky',
          top: 0,
          zIndex: 100
        }}
      >
        <tr
          style={{
            borderBottom: 1
          }}
        >
          <th
            style={{
              width: 200
            }}
          >
            Tank
          </th>
          <th
            style={{
              width: 100
            }}
          >
            Fluid
          </th>
          <th
            style={{
              width: 130
            }}
          >
            Tank Capacity (L)
          </th>
          <th
            style={{
              width: 150
            }}
          >
            Start Date/Time
          </th>
          <th
            style={{
              width: 120
            }}
          >
            Start Volume (L)
          </th>
          <th
            style={{
              width: 150
            }}
          >
            End Date/Time
          </th>
          <th
            style={{
              width: 120
            }}
          >
            End Volume (L)
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((data) => (
          <Row key={data.agent_id} data={data} />
        ))}
      </tbody>
    </MantineTable>
  )
}
