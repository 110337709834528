import { useCallback, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { type AgentDetails } from '@venturi-io/api/src/config/agent'
import { deleteAgent } from '@venturi-io/api/src/config/agent'
import {
  createStyles,
  ActionIcon,
  Badge,
  Group,
  Indicator,
  Menu,
  Text
} from '@mantine/core'
import { useDisclosure, useHotkeys } from '@mantine/hooks'
import {
  IconArrowBigUp,
  IconBell,
  IconClipboardText,
  IconContainer,
  IconCpuOff,
  IconDots,
  IconHistory,
  IconSettings2,
  IconTrash
} from '@tabler/icons-react'
import { useUser } from 'src/UserContext'
import { useApi } from 'src/utils/useApi'
import ConfirmModal from 'src/Layout/ConfirmModal'
import { AssetContext } from '../Context'

const useMenuStyles = createStyles((theme) => ({
  dropdown: {
    padding: 4,
    width: 204
  },
  label: {
    fontSize: 10,
    color: theme.colors.gray[6]
  },
  item: {
    padding: '8px 12px',
    fontSize: 12
  },
  divider: {
    margin: '2px 0'
  }
}))

interface Props {
  agentId: AgentDetails['agentId']
  agentName: AgentDetails['agentName']
  withDelete: boolean
  onDelete?: () => void
}

export default function Settings ({
  agentId,
  agentName,
  withDelete = false,
  onDelete
}: Props) {
  const { classes } = useMenuStyles()
  const { token } = useUser()
  const removeAgent = useApi(deleteAgent)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [openSettings, { toggle }] = useDisclosure(false)
  const { missingSensorCount, setShowMissingSensors } = useContext(AssetContext)

  useHotkeys([
    ['shift+C', () => window.open(`/settings/agents/${agentId}`, 'rel=noopener noreferrer')]
  ])

  const handleDelete = useCallback(() => {
    void removeAgent
      .fetch({ agentId }, token, `Succesfully deleted "${agentName}"`)
      .finally(() => {
        setShowDeleteModal(false)

        if (onDelete) {
          onDelete()
        }
      })
  }, [agentId, agentName, openSettings, onDelete])

  return (
    <>
      <Indicator
        color="red"
        size={8}
        processing
        disabled={missingSensorCount === 0}
      >
        <Menu
          classNames={classes}
          opened={openSettings}
          shadow="md"
          width="xl"
          position="bottom-end"
          transitionProps={{
            transition: 'pop-top-right'
          }}
          onClose={toggle}
          closeOnClickOutside
          withArrow
        >
          <Menu.Target>
            <ActionIcon
              color="dark.2"
              variant="subtle"
              onClick={toggle}
            >
              <IconDots size={20} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>Asset Settings</Menu.Label>
            <Menu.Item
              icon={<IconSettings2 size={12} />}
              component={Link}
              to={`/settings/agents/${agentId}`}
              rightSection={(
                <Group position="center" align="center" spacing={2}>
                  <IconArrowBigUp size={12} color="gray" />
                  <Text size="xs" color="gray.6">C</Text>
                </Group>
              )}
            >
              Configuration
            </Menu.Item>
            <Menu.Item
              icon={<IconHistory size={12} />}
              component={Link}
              to={`/my-admin/event-logs?agentId=${agentId}`}
            >
              Event Logs
            </Menu.Item>
            <Menu.Item
              icon={<IconBell size={12} />}
              component={Link}
              to={`/settings/agents/${agentId}/sensors`}
            >
              Alarms
            </Menu.Item>

            <Menu.Divider />

            <Menu.Label>Administration</Menu.Label>
            <Menu.Item
              icon={<IconClipboardText size={12} />}
              component={Link}
              to={`/my-admin/agent/logs?agentId=${agentId}`}
            >
              Asset Logs
            </Menu.Item>
            <Menu.Item
              icon={<IconContainer size={12} />}
              component={Link}
              to={`/my-admin/field-gateway-config?agentId=${agentId}`}
            >
              Field Gateway
            </Menu.Item>
            <Menu.Item
              icon={<IconCpuOff size={12} />}
              onClick={() => setShowMissingSensors(true)}
            >
              <Group spacing={8}>
                <Text size={12}>
                  Missing Sensors
                </Text>
                <Badge px={7} variant="filled" color="red">
                  {missingSensorCount}
                </Badge>
              </Group>
            </Menu.Item>
            {withDelete && (
              <Menu.Item
                key="Delete Agent"
                icon={<IconTrash size={12} />}
                color="red"
                onClick={() => setShowDeleteModal(true)}
              >
                Delete Asset
              </Menu.Item>)}
          </Menu.Dropdown>
        </Menu>
      </Indicator>
      <ConfirmModal
        type="delete"
        opened={showDeleteModal}
        title={`Deleting "${agentName}"`}
        loading={removeAgent.loading}
        question="Are you sure you want to delete this asset? This cannot be undone."
        onClose={() => setShowDeleteModal(false)}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
      />
    </>
  )
}
