/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useMemo } from 'react'
import { Table as MantineTable, Paper, useMantineTheme } from '@mantine/core'
import { type ReportData } from '@venturi-io/api/src/config/report'
import { uiDateFormatUniversal } from 'src/utils/dates'
import dayjs from 'dayjs'
import { type Range } from '@venturi-io/api'
import { getWhiteBackgroundColor } from 'src/utils/theme'
import Row from './Row'

export interface Props {
  data: ReportData
  queryId: number
  range: Range | null
  maxWidth: number
  setSpeedAlertsData: (data: ParsedSpeedEvent[]) => void
  selectedAgentIds: string[]
  selectedAgentGroupIds: string[]
}

export interface SpeedAlertItem {
  trip_id: string
  agent_id: string
  agent_name: string
  agent_groups_ids: string | string[]
  total_speed_alerts: string
  trip_start_time: string
  trip_end_time: string
  org_user_first_name: string
  org_user_last_name: string
}

export interface ParsedSpeedEvent {
  trip_id: string | null
  asset: string | null
  driver: string | null
  date: string | null
  count: string | null
}

interface RowsProps extends Omit<Props, 'data'> {
  data: SpeedAlertItem[]
}

const Rows = ({ data, range, maxWidth }: RowsProps) => {
  return data
    ? data.map(trip => (
      <Row
        key={trip.trip_id}
        trip={trip}
        range={range}
        maxWidth={maxWidth}
      />
    ))
    : null
}

export default function SpeedingReportView (props: Props) {
  const {
    data,
    queryId,
    setSpeedAlertsData,
    selectedAgentIds,
    selectedAgentGroupIds
  } = props

  const theme = useMantineTheme()
  const speedAlertData = data[`${queryId}`] as unknown as SpeedAlertItem[] ?? []

  // Convert agent group ids into array of string
  const updatedSpeedAlertData = speedAlertData.map(({ agent_groups_ids, ...props }) => ({
    ...props,
    agent_groups_ids: (typeof agent_groups_ids === 'string') ? agent_groups_ids.split(',') : []
  }))

  // Convert selected agent group ids into string
  const agentGroupIds = selectedAgentGroupIds.join(',') ?? ''

  // Filtered updatedSpeedAlertData by selected agent group id/ids
  const filteredDataByGroups = useMemo(() =>
    agentGroupIds.length > 0
      ? updatedSpeedAlertData.filter(({ agent_groups_ids }) => agent_groups_ids.some(id => agentGroupIds.includes(id)))
      : updatedSpeedAlertData,
  [updatedSpeedAlertData, agentGroupIds])

  // Filtered updatedSpeedAlertData/filteredDataByGroups by selected agent id/ids
  const filteredDataById = useMemo(() =>
    selectedAgentIds.length > 0
      ? filteredDataByGroups.filter(({ agent_id }) => selectedAgentIds.includes(agent_id ?? ''))
      : filteredDataByGroups,
  [filteredDataByGroups, selectedAgentIds])

  const parsedSpeedEvents: ParsedSpeedEvent[] = filteredDataById.map(({
    trip_id,
    agent_name,
    total_speed_alerts,
    trip_start_time,
    org_user_first_name,
    org_user_last_name
  }) => ({
    trip_id,
    asset: agent_name,
    driver: org_user_first_name && org_user_last_name
      ? `${org_user_first_name} ${org_user_last_name}`
      : 'N/A',
    date: dayjs(trip_start_time).format(uiDateFormatUniversal),
    count: total_speed_alerts
  }))

  useEffect(() => {
    setSpeedAlertsData(parsedSpeedEvents)
  }, [queryId, props.range, selectedAgentIds, selectedAgentGroupIds])

  return (
    <Paper
      p="sm"
      shadow="xs"
      sx={{
        width: '100%',
        scrollbarWidth: 'thin'
      }}
    >
      <MantineTable
        sx={{
          position: 'relative'
        }}
      >
        <thead
          style={{
            ...getWhiteBackgroundColor(theme),
            position: 'sticky',
            top: 0,
            zIndex: 100
          }}
        >
          <tr
            style={{
              borderBottom: 1
            }}
          >
            <th>Asset Name</th>
            <th
              style={{
                width: 180
              }}
            >
              Date
            </th>
            <th
              style={{
                width: 100
              }}
            >
              Violations
            </th>
            <th>Driver</th>
            <th
              style={{
                width: 180
              }}
            />
          </tr>
        </thead>
        <tbody>
          <Rows {...props} data={filteredDataById} />
        </tbody>
      </MantineTable>
    </Paper>
  )
}
