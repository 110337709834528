import { Progress, SimpleGrid, Text } from '@mantine/core'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { parseEmpty } from 'src/utils/parseEmpty'
import { type Agent } from '@venturi-io/api/src/config/agent'
import { convertStrToFloat, findSensor } from 'src/AssetTemplate/shared'
import AgentAlarmStatus from 'src/Assets/shared/AgentAlarmStatus'
import dayjs from 'dayjs'
import TextTooltip from 'src/Layout/TextTooltip'
import Link from 'src/Layout/Link'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'
import { secondsToHours } from 'src/utils/dates'
import { computePercentage } from 'src/utils/math'

export default function Row ({
  agentId,
  agentName,
  alarmStatus,
  agentStatus,
  connectionStatus,
  sensors,
  metadata,
  lastSeenTime
}: Agent & ListRow) {
  const { classes } = useStyles()
  const controlMode = findSensor('CONTROL_MODE', sensors)
  const engineState = findSensor('ENGINE_OPERATE_MODE', sensors)
  const engineRpm = findSensor('ENGINE_SPEED', sensors)
  const engineRuntime = findSensor('ENGINE_RUN_TIME', sensors)
  const engineRuntimeValue = convertStrToFloat(engineRuntime?.currentValue)
  const totalRuntime = engineRuntimeValue > 0
    ? `${secondsToHours(convertStrToFloat(engineRuntime?.currentValue)).toFixed(2)} hr`
    : '0 hr'
  const fuelLevelLiter = findSensor('FUEL_LEVEL_L', sensors)
  const fuelLevelLiterValue = Math.round(convertStrToFloat(fuelLevelLiter?.currentValue))
  const fuelCapacity = metadata?.FUEL_CAPACITY?.value ?? '0'
  const fuelCapacityLiterValue = Math.round(convertStrToFloat(fuelCapacity))
  const fuelLevelPercentValue = computePercentage(fuelLevelLiterValue, fuelCapacityLiterValue)
  const batteryVoltage = findSensor('ENGINE_BATTERY_VOLTAGE', sensors)
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint, true)

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={classes.col}>
        <Link to={`/assets/genset/${agentId}`}>
          <TextTooltip
            size="sm"
            value={parseEmpty(agentName)}
            maxLength={textMaxLength}
          />
        </Link>
      </div>
      <div
        className={classes.col}
        style={{
          justifyContent: 'center'
        }}
      >
        <AgentAlarmStatus
          alarmStatus={alarmStatus}
          agentStatus={agentStatus}
          connectionStatus={connectionStatus}
          lastSeenTime={lastSeenTime}
        />
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {parseEmpty(controlMode?.currentValue)}
        </Text>
      </div>
      <div className={classes.col}>
        <Text
          size="sm"
          color={engineState?.currentValue === 'Running'
            ? 'green'
            : 'yellow'}
        >
          {parseEmpty(engineState?.currentValue)}
        </Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {engineRpm?.currentValue ?? 0}
          {' '}
          RPM
        </Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {totalRuntime}
        </Text>
      </div>
      <div
        className={classes.col}
        style={{
          justifyContent: 'center'
        }}
      >
        <SimpleGrid cols={1}>
          <Progress
            color={fuelLevelPercentValue < 33
              ? 'red'
              : 'green'}
            radius="sm"
            size="lg"
            value={fuelLevelPercentValue}
          />
          <Text size="sm">
            {isNaN(fuelLevelPercentValue)
              ? 'N/A'
              : `${fuelLevelPercentValue}%`}
          </Text>
        </SimpleGrid>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {batteryVoltage?.currentValue
            ? `${batteryVoltage.currentValue} ${batteryVoltage.unit}`
            : '-'}
        </Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {dayjs(lastSeenTime).fromNow()}
        </Text>
      </div>
    </div>
  )
}
