import { type ReactElement, useContext, useEffect, useMemo } from 'react'
import { type AgentDetails, type AssetType } from '@venturi-io/api/src/config/agent'
import {
  useMantineTheme,
  createStyles,
  Box,
  Group,
  Paper
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import Breadcrumbs, { type BreadcrumbsItem } from 'src/Layout/Breadcrumbs'
import { mq } from 'src/utils/style'
import zIndex from 'src/utils/zIndex'
import TextTooltip from 'src/Layout/TextTooltip'
import ConnectivityStatus, {
  type Status as ConnectivityStatusType
} from '../Components/ConnectivityStatus'
import { type DiagnosticMetricItem } from '../Components/DiagnosticMetric'
import { type SensorMetricItem } from '../Components/SensorMetric'
import SensorMetrics from '../Content/SensorMetrics'
import MissingSensors from '../Components/MissingSensors'
import { AssetContext } from '../Context'
import { type SensorType } from '../shared'
import DiagnosticMetrics from './DiagnosticMetrics'
import Settings from './Settings'

interface StyleParams {
  expanded: boolean
}

const useStyles = createStyles((theme, { expanded }: StyleParams) => ({
  container: {
    position: 'sticky',
    top: 0,
    margin: 0,
    padding: 16,
    transition: 'all .3s ease',
    zIndex: zIndex.assetTemplateHeader,
    [mq(theme.breakpoints.xs)]: {
      margin: '0 16px',
      padding: 24,
      height: expanded
        ? 136
        : 128
    }
  },
  assetContainer: {
    display: 'flex',
    flexDirection: 'column',
    [mq(theme.breakpoints.sm)]: {
      gap: 8,
      flexDirection: 'row'
    },
    [mq(theme.breakpoints.md)]: {
      gap: 32
    }
  },
  assetName: {
    fontSize: 20,
    paddingTop: 4,
    [mq(theme.breakpoints.sm)]: {
      fontSize: 24,
      paddingTop: 12
    }
  },
  metricsContainer: {
    position: 'absolute',
    bottom: 0
  }
}))

interface Props {
  agentId: AgentDetails['agentId']
  agentName: AgentDetails['agentName']
  assetType: AgentDetails['assetType']
  connectivityStatus: ConnectivityStatusType
  connectivityDuration?: string
  statuses: ReactElement
  diagnosticMetrics?: DiagnosticMetricItem[]
  sensorMetrics?: SensorMetricItem[]
  expanded?: boolean
  missingSensors?: SensorType[]
}

const assetTypeTitles: Record<AssetType, string> = {
  GENSET: 'Generators',
  ATS: 'ATS',
  UPS: 'UPS',
  TANK: 'Tanks',
  VEHICLE: 'Vehicles',
  DURESS: 'PDD',
  'POWER METER': 'Power meters',
  '3P-POWER': '3P-Power',
  OTHERS: 'Others'
}

export default function Header ({
  agentId,
  agentName,
  assetType,
  connectivityStatus,
  connectivityDuration,
  statuses,
  diagnosticMetrics,
  sensorMetrics,
  expanded = false,
  missingSensors = []
}: Props) {
  const {
    showMissingSensors,
    setShowMissingSensors,
    setMissingSensorCount
  } = useContext(AssetContext)
  const { classes } = useStyles({ expanded })
  const {
    breakpoints: {
      sm,
      md,
      lg,
      xl
    }
  } = useMantineTheme()
  const isSmallScreen = useMediaQuery(mq(sm, false))
  const isMediumScreen = useMediaQuery(mq(md, false))
  const isLargeScreen = useMediaQuery(mq(lg, false))
  const isExtraLargeScreen = useMediaQuery(mq(xl, false))
  const nameMaxLength = useMemo(() => {
    let maxLength: number | undefined = 36

    if (isSmallScreen) {
      maxLength = 16
    }

    if (isMediumScreen) {
      maxLength = 26
    }

    if (isLargeScreen) {
      maxLength = 40
    }

    if (isExtraLargeScreen) {
      maxLength = undefined
    }

    return maxLength
  }, [
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isExtraLargeScreen
  ])

  const breadcrumbs: BreadcrumbsItem[] = [
    { title: 'Asset' },
    {
      title: typeof assetType !== 'undefined'
        ? assetTypeTitles[assetType as AssetType] ?? 'Generic'
        : 'Generic',
      href: typeof assetType !== 'undefined' && assetType !== 'OTHERS'
        ? assetTypeTitles[assetType as AssetType]
          ? `/assets/${assetType.toLowerCase().replaceAll(' ', '-')}`
          : '/assets/generic'
        : '/assets/generic'
    },
    { title: '' }
  ]

  useEffect(() => {
    setMissingSensorCount(missingSensors.length)
  }, [missingSensors])

  return (
    <Paper className={classes.container} radius="xs" shadow="xs">
      <Breadcrumbs items={breadcrumbs} />
      <Box className={classes.assetContainer}>
        <TextTooltip
          className={classes.assetName}
          weight={600}
          value={agentName}
          maxLength={nameMaxLength}
        />
        <Group
          position="apart"
          align="center"
          sx={{
            flex: 1
          }}
        >
          {statuses}
          <Settings
            agentId={agentId}
            agentName={agentName}
            withDelete
          />
        </Group>
      </Box>
      {expanded && (
        <Group className={classes.metricsContainer} spacing={32}>
          <Box>
            <ConnectivityStatus status={connectivityStatus} duration={connectivityDuration} />
          </Box>
          {diagnosticMetrics && (
            <DiagnosticMetrics items={diagnosticMetrics} />
          )}
          {sensorMetrics && (
            <SensorMetrics items={sensorMetrics} />
          )}
        </Group>
      )}
      <MissingSensors
        data={missingSensors}
        opened={showMissingSensors}
        onClose={() => setShowMissingSensors(false)}
      />
    </Paper>
  )
}
