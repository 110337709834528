import {
  Collapse,
  createStyles,
  Group,
  Stack,
  Text
} from '@mantine/core'
import { IconHourglass } from '@tabler/icons-react'
import React from 'react'
import { Marker as MapboxMarker } from 'react-map-gl'
import { useClickOutside, useDisclosure } from '@mantine/hooks'
import dayjs from 'dayjs'
import { uiDateFormatUniversal } from 'src/utils/dates'
import { getBackgroundColor } from 'src/utils/theme'

interface StyleOptions {
  selected: boolean
}

const useStyles = createStyles((theme, { selected }: StyleOptions) => {
  const fontWeight = selected
    ? 800
    : 500

  return {
    container: {
      cursor: 'pointer',
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      padding: '8px 12px 8px 8px',
      ...getBackgroundColor(theme)
    },
    label: {
      fontSize: 13,
      lineHeight: '13px',
      fontWeight
    },
    subtitle: {
      paddingTop: 4,
      color: 'dimmed',
      fontSize: 10,
      lineHeight: '14px'
    }
  }
})

export interface AgentCluster {
  clusterId?: number | string
  latitude: number
  longitude: number
}

interface Props extends AgentCluster {
  idleInMins?: string
  timestamp?: string
  showTimestamps?: boolean
  onSelect?: (clusterId: number) => void
}

function Marker ({
  longitude,
  latitude,
  idleInMins,
  timestamp,
  showTimestamps = false
}: Props) {
  const { classes } = useStyles({ selected: false })
  const [expanded, { toggle, close }] = useDisclosure(showTimestamps)

  const ref = useClickOutside(close)

  return (
    <MapboxMarker
      longitude={longitude}
      latitude={latitude}
      anchor="bottom-left"
      onClick={toggle}
    >
      <Group
        ref={ref}
        spacing={5}
        className={classes.container}
      >
        <IconHourglass size={20} />
        <Stack spacing={0}>
          <Text className={classes.label}>
            {idleInMins}
          </Text>
          <Collapse in={expanded}>
            <Text className={classes.subtitle}>
              {dayjs(timestamp).format(uiDateFormatUniversal)}
            </Text>
          </Collapse>
        </Stack>
      </Group>
    </MapboxMarker>
  )
}

export default Marker
