import { Paper, Stack } from '@mantine/core'
import { type Row as ListRow } from 'src/Layout/ActionList'
import TextGroup from 'src/Layout/ActionList/TextGroup'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'
import { type IncidentBreach } from '@venturi-io/api/src/config/alarmHistory'
import dayjs from 'dayjs'
import { uiDateFormatUniversal } from 'src/utils/dates'
import { useAddress } from 'src/Maps/CoreMap/shared'

export default function Card ({
  value,
  speed,
  orgUser,
  agentLocationPoint,
  detected
}: IncidentBreach & ListRow) {
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint, true)
  const address = useAddress(agentLocationPoint)

  return (
    <Paper p="lg" my={10} shadow="md">
      <Stack>
        <TextGroup
          label="Detected"
          value={dayjs(detected).format(uiDateFormatUniversal)}
          maxLength={textMaxLength}
        />
        <TextGroup
          label="User"
          value={orgUser
            ? `${orgUser.firstName} ${orgUser.lastName}`
            : 'N/A'}
          maxLength={25}
        />
        <TextGroup
          label="Location"
          value={address ?? 'N/A'}
          maxLength={25}
        />
        <TextGroup
          label="Value"
          value={typeof speed !== 'undefined' && speed !== 0
            ? speed.toString()
            : value.toString()}
          maxLength={25}
        />
      </Stack>
    </Paper>
  )
}
