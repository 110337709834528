import { useEffect } from 'react'
import { useParams } from 'react-router'
import { Box, Text } from '@mantine/core'
import { useUser } from 'src/UserContext'
import { usePaginatedApi } from 'src/utils/useApi'
import LoadingOverlay from 'src/Layout/LoadingOverlay'
import { getIncidentBreaches, type IncidentBreach } from '@venturi-io/api/src/config/alarmHistory'
import ActionList from 'src/Layout/ActionList'
import { Row, Header, Card } from './List'

interface RouteParams extends Record<string, string | undefined> {
  incidentId: string
}

interface Props {
  onUpdateBreaches: (breaches: IncidentBreach[]) => void
}

function Breaches ({ onUpdateBreaches }: Props) {
  const incidentBreaches = usePaginatedApi(getIncidentBreaches)
  const { incidentId } = useParams<RouteParams>()
  const { token } = useUser()

  if (!incidentId) {
    return null
  }

  const loadIncidentBreaches = async (page?: number, size?: number): Promise<void> => {
    void incidentBreaches
      .fetch({
        incidentId,
        page: page ?? incidentBreaches.page,
        size: size ?? incidentBreaches.size
      }, token)
  }

  useEffect(() => {
    void loadIncidentBreaches()
  }, [incidentBreaches.page])

  useEffect(() => {
    incidentBreaches.data.ifJust(({ items }) => {
      onUpdateBreaches(items)
    })
  }, [incidentBreaches])

  return (
    <Box p="sm">
      <LoadingOverlay visible={incidentBreaches.loading} />
      <Text size={14} weight={600}>Breaches</Text>
      <ActionList
        hideShadow
        mt="xs"
        isLoading={incidentBreaches.loading}
        data={incidentBreaches.data.mapOrDefault(({ items }) => items, [])}
        getId={({ breachId }) => breachId}
        header={() => <Header />}
        row={props => <Row {...props} />}
        card={props => <Card {...props} />}
        totalPages={incidentBreaches.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
        page={incidentBreaches.page}
        onPageChange={incidentBreaches.setPage}
      />
    </Box>
  )
}

export default Breaches
