import { forwardRef } from 'react'
import { type AgentDetails } from '@venturi-io/api/src/config/agent'
import { createStyles, Box, Stack } from '@mantine/core'
import { mq } from 'src/utils/style'
import { type FullIncidentReport } from '@venturi-io/api/src/config/alarmHistory'
import { uiDateFormatUniversal } from 'src/utils/dates'
import dayjs from 'dayjs'
import Tasks from 'src/AssetTemplate/Details/Tasks'
import Detail from './Detail'

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    [mq(theme.breakpoints.xs)]: {
      width: 240
    }
  }
}))

interface Props {
  agentId: AgentDetails['agentId']
  incident: FullIncidentReport
}

const Details = forwardRef<HTMLDivElement, Props>(({
  agentId,
  incident
}) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.container}>
      <Stack spacing={16}>
        <Detail
          title="Incident Details"
          data={incident}
          mappings={[
            {
              label: 'Detected',
              key: 'detected',
              formatter: (value) => dayjs(value).format(uiDateFormatUniversal)
            },
            {
              label: 'Resolved',
              key: 'cleared',
              formatter: (value) => dayjs(value).format(uiDateFormatUniversal)
            },
            {
              label: 'Duration',
              key: 'timeGap'
            }
          ]}
        />
        <Detail
          title="Values"
          data={incident}
          mappings={[
            {
              label: 'Max',
              key: 'maximum',
              formatter: (value) => `${value} ${incident.unit}`
            },
            {
              label: 'Min',
              key: 'minimum',
              formatter: (value) => `${value} ${incident.unit}`
            },
            {
              label: 'Average',
              key: 'average',
              formatter: (value) => `${value} ${incident.unit}`
            },
            {
              label: 'Set point',
              key: 'setPoint',
              formatter: (value) => `${incident.operator}${value} ${incident.unit}`
            }
          ]}
        />
        <Tasks agentId={agentId} />
      </Stack>
    </Box>
  )
})

export default Details
