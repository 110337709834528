/* eslint-disable @typescript-eslint/naming-convention */
import { useNotifications } from 'src/utils/notifications'
import { useCallback, useState } from 'react'
import { Collapse, Text, useMantineTheme } from '@mantine/core'
import { useUser } from 'src/UserContext'
import { useDisclosure } from '@mantine/hooks'
import { reverseGeocode } from 'src/Maps/GeoZoneMap/mapbox'
import dayjs from 'dayjs'
import { dateFormat, uiDateFormatUniversal } from 'src/utils/dates'
import { downloadCSV } from 'src/utils/files'
import { generatePdfDocument } from 'src/Reports/shared'
import { getLinkTextColor, getToggleableColorWBG } from 'src/utils/theme'
import { getIncidentsSummary, type Incident } from '@venturi-io/api/src/config/alarmHistory'
import RowExportButton, { type SuccessData } from '../RowExportButton'
import ExportablePdf, { type ParsedDuress } from './ExportablePdf'
import DuressAlerts from './DuressAlerts'
import { type DuressAlertItem, type Props } from '.'

interface RowProps extends Pick<Props, 'range' | 'maxWidth'> {
  trip: DuressAlertItem
}

const Row = ({ trip }: RowProps) => {
  const {
    agent_id,
    trip_id,
    sensor_instance_id,
    agent_name,
    total_duress_events,
    trip_start_time,
    trip_end_time,
    user_groups_names,
    org_user_first_name,
    org_user_last_name
  } = trip
  const { token } = useUser()
  const { showError } = useNotifications()
  const theme = useMantineTheme()
  const [tableHeight, setTableHeight] = useState(200)
  const [open, { toggle }] = useDisclosure(false)

  const driver = org_user_first_name ?? org_user_last_name
    ? `${org_user_first_name ?? ''} ${org_user_last_name ?? ''}`
    : 'N/A'

  const loadDuresses = async (onSuccess: (data: Incident[]) => void) => {
    // I need to call it asynchrounusly and get the result on done fetching.
    // Saving to state causes delay and might not load the info correctly on the pdf
    await getIncidentsSummary({
      sensorInstanceId: sensor_instance_id,
      startTime: trip_start_time,
      endTime: trip_end_time,
      page: 1,
      size: 999
    }, token).caseOf({
      Left: err => showError(err ?? 'Error on fetching idle trips'),
      Right: ({ items }) => onSuccess(items)
    })
  }

  const downloadableData = useCallback(async (
    type: 'CSV' | 'PDF',
    data: Incident[]
  ) => await Promise.all(
    data.map(async (event, idx) => {
      const {
        detected,
        cleared,
        geoZoneName,
        agentLocationPoint
      } = event
      try {
        const duress_location = agentLocationPoint
          ? await reverseGeocode([agentLocationPoint?.longitude ?? 0, agentLocationPoint?.latitude ?? 0])
          : 'N/A'

        return type === 'CSV'
          ? {
              'Event #': idx + 1,
              'Event Time': dayjs(detected).format(uiDateFormatUniversal),
              Location: duress_location,
              Geozone: geoZoneName,
              'Notification Cleared': dayjs(cleared).format(uiDateFormatUniversal)
            }
          : {
              ...event,
              location: duress_location
            }
      } catch (err: any) {
        throw Error('Failed to generate duress alert reports')
      }
    }))
  , [])

  const handleCSV = async (data: SuccessData) => {
    const events = await downloadableData('CSV', data as Incident[])
    downloadCSV(events, `${agent_name} - Duress Event Report`)
  }

  const handleDownloadPdf = (mapUri?: string) => async (data: SuccessData) => {
    const events = await downloadableData(
      'PDF',
      data as Incident[]
    ) as never as ParsedDuress[]
    await generatePdfDocument((
      <ExportablePdf
        agentName={agent_name}
        data={events}
        mapUri={mapUri}
        date={trip_start_time}
        count={events.length.toString()}
        {...trip}
      />
    ), `${agent_name} - Duress Event Report`)
  }

  return (
    <>
      <tr
        onClick={toggle}
        style={{
          cursor: 'pointer',
          backgroundColor: getToggleableColorWBG(open),
          borderBottom: 1,
          position: open
            ? 'sticky'
            : 'initial',
          top: 34,
          zIndex: open
            ? 100
            : 'unset'
        }}
      >
        <td>
          <Text
            {...getLinkTextColor(theme)}
            weight={open
              ? 'bold'
              : 'normal'}
          >
            {agent_name}
          </Text>
        </td>
        <td>{dayjs(trip_start_time, dateFormat).format(uiDateFormatUniversal)}</td>
        <td>{total_duress_events}</td>
        <td>{driver}</td>
        <td>{user_groups_names ?? 'N/A'}</td>
        <RowExportButton
          mode="duress"
          trip_id={trip_id}
          agent_id={agent_id}
          agent_name={agent_name}
          trip_end_time={trip_end_time}
          trip_start_time={trip_start_time}
          sensorInstanceId={Number(sensor_instance_id)}
          loadData={loadDuresses}
          onDownloadCsv={handleCSV}
          onDownloadPdf={handleDownloadPdf}
        />
      </tr>
      <tr>
        <td
          colSpan={6}
          style={{
            padding: 0,
            borderTop: 0,
            borderBottom: open
              ? 1
              : 0
          }}
        >
          <Collapse in={open}>
            {open && (
              <DuressAlerts
                showTitle={false}
                startTime={trip_start_time}
                endTime={trip_end_time}
                sensorInstanceId={parseInt(sensor_instance_id ?? '0')}
                style={{
                  width: '100%',
                  height: tableHeight,
                  margin: 0
                }}
                stickyTop={78}
                didChangeHeight={(height) => {
                  setTableHeight(height)
                }}
              />
            )}
          </Collapse>
        </td>
      </tr>
    </>
  )
}

export default Row
