import {
  type ColorScheme,
  type OverlayProps,
  type TextProps,
  useMantineTheme,
  createStyles,
  Overlay,
  Text
} from '@mantine/core'

const useStyles = createStyles(() => ({
  container: {
    cursor: 'default',
    userSelect: 'none'
  }
}))

interface Props extends OverlayProps {
  size?: TextProps['size']
  colorScheme?: ColorScheme
}

export default function NotApplicableOverlay ({
  size = 'md',
  colorScheme = 'light',
  color,
  blur = 0.5,
  zIndex = 1
}: Props) {
  const { colors } = useMantineTheme()
  const { classes } = useStyles()
  const overlayColor = typeof color === 'undefined'
    ? colorScheme === 'light'
      ? colors.gray[0]
      : colors.dark[4]
    : color
  const textColor = colorScheme === 'light'
    ? colors.dark[2]
    : colors.gray[7]

  return (
    <Overlay
      className={classes.container}
      color={overlayColor}
      blur={blur}
      zIndex={zIndex}
      center
    >
      <Text size={size} weight={500} color={textColor}>
        N/A
      </Text>
    </Overlay>
  )
}
