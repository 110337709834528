import { type PaginatedRequest, type ReportIdRequest, type Req, paginated } from '@venturi-io/api'
import { createStatefulApi, createStatelessApi } from '../configure'
import { z } from 'zod'

const cannotContain = [
  'drop',
  'delete',
  'grant',
  'update',
  'insert',
  'sleep',
  'alter'
]

const sql = z
  .string()
  .refine(input => {
    let ret = true
    cannotContain.forEach(kw => {
      if (!ret) return // short-circuit

      if (input.toLowerCase().includes(kw)) {
        ret = false
      }
    })

    return ret
  }, {
    message: `Your SQL cannot connect any dangerous keywords: ${cannotContain.join(', ')}`
  })

const reportType = z.object({
  id: z.number(),
  name: z.string(),
  description: z.optional(z.string()),
  reportQueryIds: z.array(z.number()).max(3)
})

const reportTypes = paginated.extend({
  items: z.array(reportType)
})

export type ReportType = z.infer<typeof reportType>

export const reportView = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().optional(),
  createQuery: sql,
  isTable: z.boolean().optional().default(false)
})

const reportViews = paginated.extend({
  items: z.array(reportView)
})

export const reportQuery = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().optional(),
  query: sql,
  params: z.record(z.string())
})

const reportQueries = paginated.extend({
  items: z.array(
    reportQuery.extend({
      reportView
    })
  )
})

export type ReportQuery = z.infer<typeof reportQuery>

const report = z.object({
  id: z.number(),
  name: z.string(),
  description: z.optional(z.string()),
  reportType
})

const reports = paginated.extend({
  items: z.array(report)
})

export type Report = z.infer<typeof report>

// Report Types
export const getReportTypes = createStatelessApi<PaginatedRequest, typeof reportTypes>(
  'config',
  '/back-office/reports/report-types',
  { method: 'GET', type: 'param' },
  reportTypes
)

// Report Queries
export const getReportQueries = createStatelessApi<PaginatedRequest, typeof reportQueries>(
  'config',
  '/back-office/reports/report-queries',
  { method: 'GET', type: 'param' },
  reportQueries
)

// Report Views
export const getReportViews = createStatelessApi<PaginatedRequest, typeof reportViews>(
  'config',
  '/back-office/reports/report-views',
  { method: 'GET', type: 'param' },
  reportViews
)

// Reports
export const getReports = createStatelessApi<PaginatedRequest, typeof reports>(
  'config',
  '/back-office/reports/reports',
  { method: 'GET', type: 'param' },
  reports
)

interface ReportRequest extends ReportIdRequest {
  name: string
  description?: string
  reportTypeId: number
}

export const createReport = createStatefulApi<Omit<ReportRequest, 'reportId'>, typeof report>(
  'config',
  '/back-office/reports/report',
  { method: 'POST' },
  report
)

export const updateReport = createStatefulApi<ReportRequest, typeof report>(
  'config',
  '/back-office/reports/report/:reportId',
  { method: 'PUT' },
  report
)

export const deleteReport = createStatefulApi<ReportIdRequest, z.ZodBoolean>(
  'config',
  '/back-office/reports/report/:reportId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)

// Report Execution
export type QueryParam = Record<string | number, string | number>

export interface QueryParams extends Record<string | number, QueryParam> {}

const rawData = z.record(z.string(), z.array(z.record(z.nullable(z.string()))))
const reportData = z.object({
  data: rawData,
  fromTime: z.optional(z.string()),
  toTime: z.optional(z.string())
})

export type ReportData = z.infer<typeof rawData>

interface ExecuteReport extends Req {
  reportId: number
  queryParams: QueryParams
}

export const executeReport = createStatefulApi<ExecuteReport, typeof reportData>(
  'config',
  '/reports/run/report/:reportId',
  { method: 'POST' },
  reportData
)

export const reportScheduleType = [
  'DAILY',
  'WEEKLY',
  'FORTNIGHTLY',
  'MONTHLY',
  'QUARTERLY',
  'SPECIFIC_DAY_OF_WEEK'
] as const

export type ScheduleType = typeof reportScheduleType[number]

export const scheduleDay = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY'
] as const

export type ScheduleDay = typeof scheduleDay[number]

export const orgUser = z.object({
  orgUserId: z.number(),
  firstName: z.optional(z.string()),
  lastName: z.optional(z.string()),
  email: z.optional(z.string())
})

const schedule = z.object({
  jobRunTime: z.optional(z.string()),
  reportScheduleId: z.number(),
  reportId: z.number(),
  reportScheduleType: z.enum(reportScheduleType),
  scheduleDay: z.optional(z.enum(scheduleDay)),
  scheduleStartDate: z.string(),
  lastScheduleRun: z.optional(z.string()),
  scheduleEnabled: z.boolean(),
  reportName: z.string(),
  orgUsers: z.optional(z.array(orgUser))
})

const schedules = paginated.extend({
  items: z.array(schedule)
})

export type ReportSchedule = z.infer<typeof schedule>
export type ReportSchedules = z.infer<typeof schedules>

interface ReportScheduleIdRequest extends Req {
  reportScheduleId: number
}

interface OrgUser {
  orgUserId: number
}

interface ReportScheduleRequest extends ReportScheduleIdRequest {
  reportId: number
  reportScheduleType: string
  scheduleDay?: string
  scheduleStartDate: string
  scheduleEnabled: boolean
  orgUsers?: OrgUser[]
}

// Scheduled Reports
export const getReportSchedule = createStatelessApi<ReportScheduleIdRequest, typeof schedule>(
  'config',
  '/reports/schedules/:reportScheduleId',
  { method: 'GET', type: 'path' },
  schedule
)

export const getReportSchedules = createStatelessApi<PaginatedRequest, typeof schedules>(
  'config',
  '/reports/schedules',
  { method: 'GET', type: 'path' },
  schedules
)

export const createReportSchedule = createStatefulApi<Omit<ReportScheduleRequest, 'reportScheduleId'>, typeof schedule>(
  'config',
  '/reports/schedules',
  { method: 'POST' },
  schedule
)

export const updateReportSchedule = createStatefulApi<ReportScheduleRequest, typeof schedule>(
  'config',
  '/reports/schedules/:reportScheduleId',
  { method: 'PUT' },
  schedule
)

export const deleteReportSchedule = createStatefulApi<ReportScheduleIdRequest, z.ZodBoolean>(
  'config',
  '/reports/schedules/:reportScheduleId',
  { method: 'DELETE', type: 'path' },
  z.boolean()
)

const generatedReport = z.object({
  generatedReportId: z.number(),
  reportName: z.string(),
  schedule: z.string(),
  fromTime: z.optional(z.string()),
  toTime: z.optional(z.string()),
  finishedAt: z.optional(z.string()),
  sentAt: z.optional(z.string())
})

const generatedReports = paginated.extend({
  items: z.array(generatedReport)
})

export type GeneratedReport = z.infer<typeof generatedReport>

export const getGeneratedReports = createStatelessApi<PaginatedRequest, typeof generatedReports>(
  'config',
  '/reports/generated',
  { method: 'GET', type: 'path' },
  generatedReports
)

interface ReportGeneratedIdRequest extends Req {
  generatedReportId: number
}

export const getGeneratedReport = createStatelessApi<ReportGeneratedIdRequest, typeof reportData>(
  'config',
  '/reports/generated/:generatedReportId',
  { method: 'GET', type: 'path' },
  reportData
)
