import { Box, Text } from '@mantine/core'
import { getTextColorAsProp } from 'src/utils/theme'

interface Props {
  label: string
  value: string | number
  isBold?: boolean
}

export default function Detail ({
  label,
  value,
  isBold = false
}: Props) {
  return (
    <Box>
      <Text size={10} weight={500} color="dimmed">
        {label}
      </Text>
      <Text
        size={12}
        weight={isBold
          ? 500
          : 400}
        {...getTextColorAsProp()}
      >
        {value}
      </Text>
    </Box>
  )
}
