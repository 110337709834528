/* eslint-disable @typescript-eslint/naming-convention */
import {
  useMantineTheme,
  Divider,
  Group,
  Paper,
  SimpleGrid,
  Stack,
  ThemeIcon,
  Text
} from '@mantine/core'
import Link from 'src/Layout/Link'
import { getLinkTextColor, getWhiteBackgroundColor } from 'src/utils/theme'
import { IconCylinder } from '@tabler/icons-react'
import Detail from './Detail'
import { type Props as ListProps, type ItemProps } from '.'

const Card = ({
  data: {
    agent_id,
    agent_name,
    agent_liquid_type,
    tank_capacity,
    start_time,
    start_volume,
    end_time,
    end_volume
  }
}: ItemProps) => {
  const theme = useMantineTheme()
  const isLight = theme.colorScheme === 'light'

  return (
    <Paper
      sx={{
        ...getWhiteBackgroundColor(theme),
        border: isLight
          ? 'initial'
          : `1px solid ${theme.colors.gray[7]}`
      }}
      p={16}
      shadow="xs"
      radius={4}
    >
      <Stack spacing={8}>
        <Group ml={-4} spacing={4} noWrap>
          <ThemeIcon
            sx={{
              background: 'transparent'
            }}
            variant="light"
            color="primary"
          >
            <IconCylinder size={16} stroke={2} />
          </ThemeIcon>
          <Text
            component={Link}
            to={`/assets/tank/${agent_id}`}
            size={12}
            weight={500}
            {...getLinkTextColor(theme)}
          >
            {agent_name}
          </Text>
        </Group>
        <Divider mt={0} mb={4} variant="dotted" />
        <SimpleGrid cols={2} spacing={8}>
          <Detail label="Fluid" value={agent_liquid_type} />
          <Detail label="Tank Capacity (L)" value={tank_capacity} />
          <Detail label="Start Date/Time" value={start_time} />
          <Detail label="Start Volume (L)" value={start_volume} />
          <Detail label="End Date/Time" value={end_time} />
          <Detail label="End Volume (L)" value={end_volume} />
        </SimpleGrid>
      </Stack>
    </Paper>
  )
}

export default function Cards ({ items }: ListProps) {
  return (
    <Stack my={12} spacing={12}>
      {items.map((data) => (
        <Card key={data.agent_id} data={data} />
      ))}
    </Stack>
  )
}
