import { Center, Text } from '@mantine/core'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { parseEmpty } from 'src/utils/parseEmpty'
import { type Agent } from '@venturi-io/api/src/config/agent'
import { checkIfAlarm } from 'src/utils/status'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextTooltip from 'src/Layout/TextTooltip'
import Link from 'src/Layout/Link'
import AgentAlarmStatus from 'src/Assets/shared/AgentAlarmStatus'
import { getTextMaxLengthPerBreakpoint } from 'src/utils/strings'
import { getCurrentBreakpoint } from 'src/utils/style'

export default function Row ({
  agentId,
  agentName,
  alarmStatus,
  agentStatus,
  connectionStatus,
  lastSeenTime,
  assignedTo
}: Agent & ListRow) {
  const { classes } = useStyles()
  const isAlert = checkIfAlarm(alarmStatus)
  const initialBreakpoint = getCurrentBreakpoint()
  const textMaxLength = getTextMaxLengthPerBreakpoint(initialBreakpoint)

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={classes.col}>
        <Link to={`/assets/duress/${agentId}`}>
          <TextTooltip
            size="sm"
            value={parseEmpty(agentName)}
            maxLength={textMaxLength}
          />
        </Link>
      </div>
      <div className={classes.col}>
        <AgentAlarmStatus
          alarmStatus={alarmStatus}
          agentStatus={agentStatus}
          connectionStatus={connectionStatus}
          lastSeenTime={lastSeenTime}
        />
      </div>
      <div className={classes.col}>
        <Center>
          <FontAwesomeIcon
            color={isAlert ? 'red' : 'gray'}
            icon={['fas', 'warning']}
            size="lg"
          />
        </Center>
      </div>
      <div className={classes.col}>
        <Link to={`/?agentId=${agentId}&list=false`}>
          <Text size="sm">View Map</Text>
        </Link>
      </div>
      <div className={classes.col}>
        <TextTooltip
          size="sm"
          value={parseEmpty(assignedTo)}
          maxLength={textMaxLength}
        />
      </div>
    </div>
  )
}
