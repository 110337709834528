/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ActionIcon,
  Button,
  Group,
  Popover,
  Stack,
  Text
} from '@mantine/core'
import { IconFileTypeCsv, IconFileTypePdf, IconPrinter } from '@tabler/icons-react'
import { Link } from 'react-router-dom'
import MapBuilderModal, { type BuilderProps } from 'src/Maps/MapToPdf/MapBuilderModal'
import { type TripSummary, type IdlingTrips } from '@venturi-io/api/src/collector/trip'
import { useNotifications } from 'src/utils/notifications'
import { type ExportMode } from 'src/Maps/MapToPdf'
import { type Incident } from '@venturi-io/api/src/config/alarmHistory'
import { type GeozoneEvents } from '@venturi-io/api/src/analytics/geozone'
import { type SpeedAlert } from '@venturi-io/api/src/analytics/speedAlerts'

export type SuccessData =
  IdlingTrips |
  Incident[] |
  GeozoneEvents |
  SpeedAlert[] |
  TripSummary[]

interface Props {
  mode?: ExportMode
  trip_id?: string
  agent_id: string
  agent_name: string
  sensorInstanceId?: number // required on duress
  trip_start_time?: string
  trip_end_time?: string
  skipMapBuild?: boolean
  loadData: (onSuccess: (data: SuccessData) => void) => Promise<void>
  onDownloadCsv: (data: SuccessData) => Promise<void>
  onDownloadPdf: (mapUri?: string) => (data: SuccessData) => Promise<void>
}

const RowExportButton = ({
  mode,
  trip_id,
  agent_id,
  trip_start_time,
  trip_end_time,
  sensorInstanceId,
  skipMapBuild,
  loadData,
  onDownloadCsv,
  onDownloadPdf
}: Props) => {
  const ref = useRef<BuilderProps>(null)
  const { showError } = useNotifications()
  const [isLoadingCsv, setIsLoadingCsv] = useState(false)
  const [isLoadingPdf, setIsLoadingPdf] = useState(false)

  const handleDownloadCsv = useCallback(async () => {
    try {
      setIsLoadingCsv(true)
      await loadData(onDownloadCsv)
    } catch (error) {
      showError(error as Error)
    } finally {
      setIsLoadingCsv(false)
    }
  }, [])

  const handleDownloadPdf = useCallback(async (uri?: string) => {
    try {
      setIsLoadingPdf(true)
      await loadData(onDownloadPdf(uri))
    } catch (error) {
      showError(error as Error)
    } finally {
      setIsLoadingPdf(false)
    }
  }, [])

  return (
    <td align="right" onClick={(e) => e.stopPropagation()}>
      <Group
        spacing="lg"
        position="center"
        align="center"
      >
        {trip_id &&
        agent_id &&
        trip_start_time && (
          <Link to={`/?agentId=${agent_id}&tripId=${trip_id}&startTime=${encodeURIComponent(trip_start_time ?? '')}`}>
            <Button
              size="xs"
              leftIcon={<FontAwesomeIcon icon={['far', 'route']} />}
              variant="outline"
              color="primary"
            >
              View trip
            </Button>
          </Link>
        )}
        <Popover
          position="bottom-end"
          withArrow
          arrowOffset={12}
        >
          <Popover.Target>
            <ActionIcon
              size="xs"
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <IconPrinter size={16} />
            </ActionIcon>
          </Popover.Target>
          <Popover.Dropdown>
            <Stack align="flex-start" spacing="xs">
              <Text color="dimmed" size="xs">Export data</Text>
              <Button
                loading={isLoadingCsv}
                disabled={isLoadingPdf}
                leftIcon={<IconFileTypeCsv size={16} />}
                variant="subtle"
                color="primary"
                onClick={handleDownloadCsv}
              >
                Download as CSV
              </Button>
              <Button
                loading={isLoadingPdf}
                disabled={isLoadingCsv}
                leftIcon={<IconFileTypePdf size={16} />}
                variant="subtle"
                color="primary"
                onClick={() => {
                  if (skipMapBuild) {
                    void handleDownloadPdf()
                  } else {
                    ref.current?.open()
                  }
                }}
              >
                Download as PDF
              </Button>
              {/* Map modal so it won't show until download pdf is clicked */}
              <MapBuilderModal
                ref={ref}
                mode={mode}
                tripId={Number(trip_id)}
                sensorInstanceId={sensorInstanceId}
                startTime={trip_start_time}
                endTime={trip_end_time}
                didFinishLoading={handleDownloadPdf}
              />
            </Stack>
          </Popover.Dropdown>
        </Popover>
      </Group>
    </td>
  )
}

export default RowExportButton
