import { type ReactNode } from 'react'
import { type PageProps, Document, Font, Page } from '@react-pdf/renderer'
import Header from './Header'
import Footer from './Footer'
import { useLogo } from './LogoContext'

interface Props {
  title: string
  page: PageProps
  children?: ReactNode
}

export default function ReportTemplate ({ title, page, children }: Props) {
  const state = useLogo()
  const fonts = {
    family: 'Inter',
    fonts: [
      {
        src: require('../../../public/fonts/Inter_18pt-Regular.ttf')
      },
      {
        src: require('../../../public/fonts/Inter_18pt-Bold.ttf'),
        fontStyle: 'bold',
        fontWeight: 700
      }
    ]
  }
  Font.register(fonts)

  return (
    <Document title={title}>
      <Page {...page}>
        <Header title={title} imageUrl={state?.logo} />
        {children}
        <Footer />
      </Page>
    </Document>
  )
}
