import { useEffect, useMemo, useState } from 'react'
import { IconBell, IconDroplet, IconStackPush, IconStackPop } from '@tabler/icons-react'
import { getAgentDetails } from '@venturi-io/api/src/config/agent'
import { getAlarmsForSensorInstance } from '@venturi-io/api/src/analytics/alarm'
import { useParams } from 'react-router'
import AssetTemplate from 'src/AssetTemplate'
import { type SensorType, getMissingSensors } from 'src/AssetTemplate/shared'
import { type DiagnosticMetricItem } from 'src/AssetTemplate/Components/DiagnosticMetric'
import { findSensor } from 'src/AssetTemplate/shared'
import NotFound from 'src/Router/NotFound'
import { useUser } from 'src/UserContext'
import { useApi, usePaginatedApi } from 'src/utils/useApi'
import dayjs from 'dayjs'
import { getTankTransactions } from '@venturi-io/api/src/config/fms'
import { dateFormat } from 'src/utils/dates'
import { formatNumber } from 'src/utils/numbers'
import Nothing from 'src/Nothing'
import { getFuelDetails } from 'src/Assets/shared'
import Loader from 'src/AssetTemplate/Components/Loader'
import Transactions from './Transactions'
import Statuses from './Statuses'
import TankStatus from './TankStatus'
import CapacityHistory from './CapacityHistory'

interface RouteParams extends Record<string, string | undefined> {
  assetId: string
}

const requiredSensors: SensorType[] = [
  'FUEL_CONSUMPTION',
  'FUEL_LEVEL',
  'FUEL_LEVEL_L'
]

export default function Dashboard () {
  const { assetId } = useParams<RouteParams>()
  const { token } = useUser()
  const agentDetails = useApi(getAgentDetails)
  const agent = agentDetails.data.mapOrDefault(data => data, null)
  const outTransactions = usePaginatedApi(getTankTransactions)
  const inTransactions = usePaginatedApi(getTankTransactions)
  const getTransactions = usePaginatedApi(getTankTransactions)
  const getFuelLevelPercentAlarms = usePaginatedApi(getAlarmsForSensorInstance)
  const getFuelLevelLiterAlarms = usePaginatedApi(getAlarmsForSensorInstance)
  const [isInitialLoad, setIsInitialLoad] = useState(true)

  const totalInVol = useMemo(() => (inTransactions.data.mapOrDefault(({ items }) => (
    formatNumber(
      Math.round(items.reduce((total, { volume }) => total + volume, 0))
    )
  ), 0)), [inTransactions.data])

  const totalOutVol = useMemo(() => (outTransactions.data.mapOrDefault(({ items }) => (
    formatNumber(
      Math.round(items.reduce((total, { volume }) => total + volume, 0))
    )
  ), 0)), [outTransactions.data])

  const fuelLevelPercentAlarms = useMemo(() => (getFuelLevelPercentAlarms.data.mapOrDefault(({ items }) => (
    items
      .filter(({ enabled }) => enabled)
      .sort((a, b) => a.setPoint - b.setPoint)
  ), [])), [getFuelLevelPercentAlarms.data])

  const fuelLevelLiterAlarms = useMemo(() => (getFuelLevelLiterAlarms.data.mapOrDefault(({ items }) => (
    items
      .filter(({ enabled }) => enabled)
      .sort((a, b) => a.setPoint - b.setPoint)
  ), [])), [getFuelLevelLiterAlarms.data])

  if (typeof assetId === 'undefined' || isNaN(parseInt(assetId))) {
    return <NotFound />
  }

  useEffect(() => {
    const req = { agentId: parseInt(assetId) }

    void agentDetails
      .fetch(req, token)
      .finally(() => {
        agentDetails.stopPolling()
        agentDetails.startPolling(req, token, 30)
        setIsInitialLoad(false)
      })

    return () => {
      agentDetails.stopPolling()
      agentDetails.abort()
    }
  }, [assetId])

  useEffect(() => {
    if (typeof assetId !== 'undefined') {
      const agentId = Number(assetId)
      const transactionTypesPayload = {
        agentId,
        page: 1,
        size: 999999,
        sort: 'started',
        order: 'desc' as const,
        startTime: `${dayjs(new Date()).subtract(3, 'days').format(dateFormat)}`,
        endTime: `${dayjs(new Date()).format(dateFormat)}`
      }

      // Fetch inwards and outwards transactions
      void inTransactions.fetch({
        ...transactionTypesPayload,
        type: 'IN'
      }, token)

      void outTransactions.fetch({
        ...transactionTypesPayload,
        type: 'OUT'
      }, token)

      // Fetch all transactions
      void getTransactions.fetch({
        agentId,
        page: 1,
        size: 4,
        sort: 'started',
        order: 'desc'
      }, token)
    }
  }, [assetId])

  useEffect(() => {
    // Tank's alarms will refer on Fuel Level Liter and Fuel Level Percentage sensors
    const fuelLevelPercent = findSensor('FUEL_LEVEL', agent?.sensors ?? [])
    const fuelLevelLiter = findSensor('FUEL_LEVEL_L', agent?.sensors ?? [])
    const pageOptions = {
      page: 1,
      size: 999999
    }

    if (fuelLevelPercent !== null) {
      void getFuelLevelPercentAlarms.fetch({
        sensorInstanceId: fuelLevelPercent.sensorInstanceId,
        ...pageOptions
      }, token)
    }

    if (fuelLevelLiter !== null) {
      void getFuelLevelLiterAlarms.fetch({
        sensorInstanceId: fuelLevelLiter.sensorInstanceId,
        ...pageOptions
      }, token)
    }
  }, [agent?.sensors])

  if (agent === null || (isInitialLoad && agentDetails.loading)) {
    return <Loader />
  }

  const {
    agentId,
    agentName,
    assetType,
    liquidType,
    metadata,
    geoLocation,
    sensors,
    lastSeenTime,
    connectionStatus
  } = agent

  const alarmCount = sensors
    .filter(({ alarmStatus }) => (
      alarmStatus === 'ALARM' || alarmStatus === 'WARNING'
    ))
    .length ?? 0

  // Tank sensors
  const fuelLevel = findSensor('FUEL_LEVEL', sensors)
  const fuelConsumption = findSensor('FUEL_CONSUMPTION', sensors)
  const fuelLevelLiter = findSensor('FUEL_LEVEL_L', sensors)
  const {
    fuelCapacityLiter,
    fuelLevelFloatVal,
    fuelLevelPercent,
    safeFillLevelPercent,
    safeFillLevelLiter
  } = getFuelDetails(sensors, metadata)

  const fuelRemainingPercent = 100 - fuelLevelPercent

  const getRemainingLitersUntilNextAlarm = () => {
    // Sort alarms in descending order
    const sortedAlarms = fuelLevelLiterAlarms?.sort((a, b) => Number(b.setPoint) - Number(a.setPoint))
    let liters = 0

    if (sortedAlarms) {
      for (let i = 0; i < sortedAlarms.length; i++) {
        const { setPoint } = sortedAlarms[i]

        if (fuelLevelFloatVal > setPoint) {
          liters = fuelLevelFloatVal - setPoint
          break
        } else if (fuelLevelFloatVal <= setPoint) {
          liters = 0
        }
      }
    }

    return liters
  }

  const diagnosticMetrics: DiagnosticMetricItem[] = [
    {
      sensorInstanceId: fuelLevel?.sensorInstanceId ?? -1,
      name: 'Level',
      icon: <IconDroplet size={16} />,
      value: fuelLevelPercent,
      displayValue: fuelLevelPercent.toString(),
      displayValueUnit: '%',
      label: `${formatNumber(fuelLevelFloatVal)} L / ${formatNumber(fuelCapacityLiter)} L`,
      disabled: fuelLevel === null
    },
    {
      sensorInstanceId: -1,
      name: 'Transactions',
      icon: <IconStackPush size={16} />,
      value: fuelLevelPercent,
      displayValue: totalInVol?.toString() ?? '',
      displayValueUnit: 'L',
      label: 'Inwards',
      lowerLabel: 'Last 3 days',
      disableChart: true
    },
    {
      sensorInstanceId: -1,
      name: 'Transactions',
      icon: <IconStackPop size={16} />,
      value: fuelLevelPercent,
      displayValue: totalOutVol?.toString() ?? '',
      displayValueUnit: 'L',
      label: 'Outwards',
      lowerLabel: 'Last 3 days',
      disableChart: true
    },
    {
      sensorInstanceId: -1,
      name: 'Next Alarm',
      icon: <IconBell size={16} />,
      value: fuelLevelPercent,
      displayValue: `${formatNumber(Math.round(Number(getRemainingLitersUntilNextAlarm())))}`,
      displayValueUnit: 'L',
      disableChart: true
    }
  ]

  const missingSensors = getMissingSensors(requiredSensors, sensors)

  return (
    // TODO: Add connectivityDuration once available in the BE data
    <AssetTemplate
      agentId={agentId}
      agentName={agentName}
      assetType={assetType}
      fuelType={liquidType}
      metadata={metadata}
      geoLocation={geoLocation}
      diagnosticMetrics={diagnosticMetrics}
      statuses={<Statuses alarms={alarmCount} />}
      connectivityStatus={connectionStatus === 'ONLINE'
        ? 'CONNECTED'
        : 'DISCONNECTED'}
      totalAssetRuntime=""
      lastDataTransfer={lastSeenTime}
      missingSensors={missingSensors}
    >
      <TankStatus
        fuelConsumption={fuelConsumption}
        fuelLevel={fuelLevel}
        fuelLevelPercent={fuelLevelPercent}
        fuelRemainingPercent={fuelRemainingPercent}
        fuelLevelLiter={fuelLevelLiter}
        safeFillLevelPercent={safeFillLevelPercent}
        safeFillLevelLiter={safeFillLevelLiter}
        currentFuelLevelLiter={fuelLevelFloatVal}
        fuelCapacityLiter={fuelCapacityLiter}
        fuelLevelPercentAlarms={fuelLevelPercentAlarms}
        fuelLevelLiterAlarms={fuelLevelLiterAlarms}
      />
      {sensors.length > 0 && (
        <CapacityHistory sensors={sensors} />
      )}
      {getTransactions.data.caseOf({
        Nothing: () => (
          <Nothing
            isLoading={getTransactions.loading}
            nothing={getTransactions.data.isNothing()}
          />
        ),
        Just: ({ items }) => <Transactions transactions={items} />
      })}
    </AssetTemplate>
  )
}
