import { Stack, Text, Paper } from '@mantine/core'
import { type FullIncidentReport } from '@venturi-io/api/src/config/alarmHistory'
import Attribute from './Attribute'

export interface Mapping {
  label: string
  key: keyof FullIncidentReport
  formatter?: (value?: string | number) => JSX.Element | string
}

interface Props {
  title: string
  data?: FullIncidentReport
  mappings: Mapping[]
}

export default function Detail ({
  title,
  data,
  mappings
}: Props) {
  return (
    <Paper p={16} radius={4} shadow="xs">
      <Text size={14} weight={600}>{title}</Text>
      {!data && (
        <Text
          mt={24}
          size={12}
          weight={400}
          color="silver"
          align="center"
        >
          No data available
        </Text>
      )}
      <Stack mt={16} spacing={16}>
        {data && mappings.map(({ label, key, formatter }) => (
          <Attribute
            key={key}
            label={label}
            value={formatter
              ? data[key]
                ? formatter(data[key])
                : undefined
              : data[key]}
          />
        ))}
      </Stack>
    </Paper>
  )
}
