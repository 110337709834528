import { useEffect, useMemo, useState } from 'react'
import { Stack } from '@mantine/core'
import { useNavigate, useLocation } from 'react-router'
import dayjs from 'dayjs'
import { dateFormat } from 'src/utils/dates'
import { type Range } from '@venturi-io/api'
import { type SensorDataLog } from '@venturi-io/api/src/collector/sensor'
import SearchBar from './SearchBar'
import Logs from './Logs'
import { formatLogsToCsv } from './shared'

export interface FormattedSensorLog {
  'Timestamp': string
  'Agent': string
  'Sensor ID': number
  'Sensor': string
  'Sensor Instance ID': number
  'Sensor Instance': string
  'Value': string
}

export default function EventLogs () {
  const { search } = useLocation()
  const navigate = useNavigate()
  const [showLogs, setShowLogs] = useState<boolean>(false)
  const [agentId, setAgentId] = useState<string | null>(null)
  const [sensorInstanceId, setSensorInstanceId] = useState<string | null>(null)
  const [logs, setLogs] = useState<SensorDataLog[]>([])
  const [totalLogs, setTotalLogs] = useState<number | null>(null)
  const [dateRange, setDateRange] = useState<Range | null>({
    from: dayjs()
      .subtract(1, 'days')
      .format(`${dateFormat}Z`),
    to: dayjs().format(`${dateFormat}Z`)
  })

  const formattedLogs = useMemo(() =>
    formatLogsToCsv(logs)
  , [logs])

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search)
      const id = params.get('agentId')
      if (id) {
        setAgentId(id)
      }
      const sensorId = params.get('sensorInstanceId')
      if (sensorId) {
        setSensorInstanceId(sensorId)
      }
    }
    setShowLogs(true)
  }, [search])

  useEffect(() => {
    const params = new URLSearchParams()
    if (agentId) {
      params.append('agentId', agentId)
    }
    if (sensorInstanceId) {
      params.append('sensorInstanceId', sensorInstanceId)
    }
    navigate(`?${params.toString()}`, { replace: true })
  }, [agentId, sensorInstanceId])

  return (
    <Stack
      spacing={0}
      sx={{
        position: 'absolute',
        width: '97%',
        height: '95%'
      }}
    >
      <SearchBar
        totalLogs={totalLogs}
        logs={formattedLogs}
        agentId={agentId}
        sensorId={sensorInstanceId}
        setSelectedAgentId={setAgentId}
        setSelectedSensorInstanceId={setSensorInstanceId}
        setDateRange={setDateRange}
        dateRange={dateRange}
      />
      {showLogs && (
        <Logs
          setLogs={setLogs}
          setTotalLogs={setTotalLogs}
          agentId={agentId ? Number(agentId) : undefined}
          sensorInstanceId={sensorInstanceId ? Number(sensorInstanceId) : undefined}
          dateRange={dateRange}
        />
      )}
    </Stack>
  )
}
