/* eslint-disable @typescript-eslint/no-var-requires */
import { useEffect, useMemo, useRef, useState } from 'react'
import { getTripDetails, type TripDetails } from '@venturi-io/api/src/collector/trip'
import { useUser } from 'src/UserContext'
import { useApi, usePaginatedApi } from 'src/utils/useApi'

import ReactMapGL, { Layer, Source, type LayerProps, type MapRef } from 'react-map-gl'
import mapboxgl, { type LngLatLike } from 'mapbox-gl'
import { getIncidentsSummary, type IncidentsSummary } from '@venturi-io/api/src/config/alarmHistory'
import { type BBox } from '@turf/turf'
import { Box } from '@mantine/core'
import html2canvas from 'html2canvas'
import {
  generateDuressPoints,
  generateIdlingPoints,
  generateLinePath,
  generateRawLinePath,
  generateSpeedingPoints,
  generateTripPoints,
  randomHexColor
} from '../GeoZoneMap/mapbox'
import { MapType } from '../StylePicker'
import { generateSpeedingDot } from '../GeoZoneMap/Trips/Layer/speedingDot'
import IdleMarker from '../GeoZoneMap/Trips/Layer/IdleMarker'

export type ExportMode =
  'idle' |
  'speeding' |
  'duress' |
  'all'
export interface MapToPdfProps {
  mode?: ExportMode
  tripId: number
  startTime?: string | null
  endTime?: string
  sensorInstanceId?: number
  didFinishLoading?: (dataUri: string) => void
}

interface ZoomBounds {
  zoom?: number
  idleBounds?: BBox
}

function MapToPdf ({
  mode,
  tripId,
  startTime,
  endTime,
  sensorInstanceId,
  didFinishLoading
}: MapToPdfProps) {
  const { token } = useUser()

  const mapRef = useRef<MapRef>(null)
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null)
  const tripDetails = useApi(getTripDetails)
  const duressSummary = usePaginatedApi(getIncidentsSummary)
  const [trip, setTrip] = useState<TripDetails>()

  // for duress only
  const [incidentSummary, setIncidentSummary] = useState<IncidentsSummary>()
  const [{ zoom, idleBounds }, setZoomBounds] = useState<ZoomBounds>({})

  useEffect(() => {
    // Load trip
    if (startTime) {
      void tripDetails.fetch({
        tripId,
        startTime
      }, token)
    }
    // If duress, load duress summary
    if (startTime && endTime && sensorInstanceId) {
      void duressSummary.fetch({
        sensorInstanceId: sensorInstanceId.toString(),
        startTime,
        endTime,
        page: 1,
        size: 999
      }, token)
    }
  }, [])

  useEffect(() => {
    tripDetails.data.ifJust((data) => {
      setTrip({
        ...data,
        color: randomHexColor()
      })
    })
  }, [tripDetails.data])

  useEffect(() => {
    duressSummary.data.ifJust((data) => {
      setIncidentSummary(data)
    })
  }, [duressSummary.data])

  // trip points and appearance config
  const rawTripSource = useMemo(() => (
    trip
      ? generateTripPoints(trip.rawPath, trip.tripId, trip.color)
      : null
  ), [trip])

  const lineMapboxPathSource = useMemo(() => (
    trip?.inProgress
      ? generateRawLinePath(trip.rawPath, trip.color)
      : trip
        ? generateLinePath(trip.mapboxPath, trip.color)
        : null
  ), [trip])

  const speedingSource = useMemo(() => (
    trip
      ? generateSpeedingPoints(trip.rawPath, trip.tripId, trip.color)
      : null
  ), [trip])

  const idlingSource = useMemo(() => (
    trip
      ? generateIdlingPoints(trip.rawPath, trip.tripId)
      : null
  ), [trip])

  const duressSource = useMemo(() => (
    incidentSummary
      ? generateDuressPoints(incidentSummary)
      : null
  ), [incidentSummary])

  const speeding: LayerProps = {
    id: 'speeding-arrow-points',
    type: 'symbol',
    layout: {
      'icon-image': 'arrow-indicator', // reference the image
      'icon-size': 1,
      'icon-rotate': ['get', 'direction'],
      'icon-allow-overlap': true
    }
  }

  const lineBorder: LayerProps = {
    type: 'line',
    paint: {
      'line-color': 'gray',
      'line-opacity': 1,
      'line-gap-width': 9,
      'line-width': 1
    },
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    }
  }

  const lineLayer: LayerProps = {
    id: 'main-line',
    type: 'line',
    paint: {
      'line-gradient': [
        'interpolate',
        ['linear'],
        ['line-progress'],
        0,
        'lightGray',
        1,
        trip?.color ?? 'red'
      ],
      'line-color': ['get', 'color'],
      'line-opacity': 1,
      'line-width': 9
    },
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    }
  }

  const speedingLayer: LayerProps = {
    id: 'speeding-points',
    type: 'symbol',
    layout: {
      'icon-image': 'speeding-dot',
      'icon-allow-overlap': true
    }
  }

  const duressPinLayer: LayerProps = {
    id: 'duressFence',
    type: 'circle',
    paint: {
      'circle-color': ['get', 'color'],
      'circle-opacity': 1,
      'circle-radius': 14,
      'circle-stroke-color': '#fff',
      'circle-stroke-opacity': 1,
      'circle-stroke-width': 2
    }
  }

  const duressTextLayer: LayerProps = {
    id: 'duressText',
    type: 'symbol',
    layout: {
      'text-field': ['get', 'position'],
      'text-font': ['Arial Unicode MS Bold'],
      'text-size': 12,
      'text-allow-overlap': true
    },
    paint: {
      'text-color': 'white'
    }
  }

  const bounds = useMemo(() => {
    if (trip) {
      const coordinates: LngLatLike[] = trip.rawPath.map(({ longitude, latitude }) => ({
        lng: longitude,
        lat: latitude
      }))
      // Create a 'LngLatBounds' with both corners at the first coordinate.
      const bounds = new mapboxgl.LngLatBounds(
        coordinates[0],
        coordinates[0]
      )

      // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
      for (const coord of coordinates) {
        bounds.extend(coord)
      }

      return bounds
    }
    return null
  }, [trip])

  const hasData = useMemo(() => {
    return (
      lineMapboxPathSource &&
      rawTripSource &&
      idlingSource &&
      speedingSource
    )
  }, [lineMapboxPathSource, rawTripSource, idlingSource, speedingSource])

  const loadImage = () => {
    if (mapRef.current) {
      const map = mapRef.current.getMap()
      map.loadImage(require('../assets/idle-indicator.png'), (error, image) => {
        if (error) {
          console.error('ERR', error)
        }
        if (image && !map.hasImage('idle-indicator')) {
          map.addImage('idle-indicator', image)
        }
      })
      map.loadImage(require('../assets/arrow-indicator.png'), (error, image) => {
        if (error) {
          console.error('ERR', error)
        }
        if (image && !map.hasImage('arrow-indicator')) {
          map.addImage('arrow-indicator', image)
        }
      })
      if (!map.hasImage('speeding-dot')) {
        const speedingDot = generateSpeedingDot(map)
        map.addImage('speeding-dot', speedingDot, { pixelRatio: 2 })
      }
    }
  }

  const captureImage = async () => {
    if (containerRef) {
      const canvas = await html2canvas(containerRef)
      const mapImage = canvas.toDataURL('image/png', 1.0)
      if (didFinishLoading) {
        didFinishLoading(mapImage ?? 'N/A')
      }
    }
  }

  // check duress result if mode === 'duress'
  if (mode === 'duress' && incidentSummary === undefined) {
    return null
  }

  return trip && hasData && (
    <Box
      ref={setContainerRef}
      sx={{
        pointerEvents: 'none',
        // this would prevent the skewing bug by setting a
        // fixed width and height for the main container
        width: 1280,
        height: 720
      }}
    >
      <ReactMapGL
        ref={mapRef}
        preserveDrawingBuffer
        style={{
          width: 1280,
          height: 720
        }}
        interactive={false}
        cursor="default"
        maxZoom={16}
        mapStyle={MapType.satellite}
        mapboxAccessToken={process.env.REACT_APP_APIKEY_MAPBOX}
        attributionControl={false}
        transformRequest={(url) => ({
          url,
          referrerPolicy: 'strict-origin-when-cross-origin'
        })}
        onLoad={() => {
          // load idling pin layer
          loadImage()
          if (didFinishLoading && bounds && mapRef.current) {
            mapRef.current.fitBounds(bounds, {
              duration: 0,
              padding: {
                top: 80,
                bottom: 80,
                left: 160,
                right: 160
              }
            })
            setZoomBounds({
              idleBounds: mapRef.current.getBounds()
                .toArray()
                .flat() as never as BBox,
              zoom: mapRef.current.getZoom()
            })
            setTimeout(() => {
              void captureImage()
            }, 1500)
          }
        }}
      >
        {/* Render trip layer here */}
        {lineMapboxPathSource && (
          <Source {...lineMapboxPathSource}>
            <Layer
              {...lineBorder}
            />
            <Layer
              {...lineLayer}
            />
          </Source>
        )}
        {trip && idleBounds && zoom && (
          <IdleMarker
            showTimestamps
            mapRef={mapRef}
            rawPath={trip.rawPath}
            bounds={idleBounds}
            zoom={zoom}
          />
        )}
        {duressSource && (
          <Source {...duressSource}>
            <Layer {...duressPinLayer} />
            <Layer {...duressTextLayer} />
          </Source>
        )}
        {speedingSource && mode === 'speeding' && (
          <Source {...speedingSource}>
            <Layer {...speedingLayer} />
            <Layer {...speeding} />
          </Source>
        )}
      </ReactMapGL>
    </Box>
  )
}

export default MapToPdf
