/* eslint-disable @typescript-eslint/naming-convention */
import { Text, View } from '@react-pdf/renderer'
import dayjs from 'dayjs'
import { uiDateFormatUniversal, uiDateOnly, uiTimeOnly } from 'src/utils/dates'
import ReportTemplate from 'src/Layout/ReportTemplate'
import { reportStyles, tableStyles } from 'src/Layout/ReportTemplate/styles'
import { secondsToDhms } from 'src/Assets/shared'
import { type GeozoneEvent } from '@venturi-io/api/src/analytics/geozone'
import { type ParsedGeozoneEvent } from '.'

interface Props extends Partial<ParsedGeozoneEvent> {
  agentName: string
  data: ParsedGeozone[]
}
interface Value {
  value?: string | null
}

const THCell = ({ value }: Value) => (
  <View style={[tableStyles.cell, tableStyles.tHead]}>
    <Text>{value ?? 'N/A'}</Text>
  </View>
)

const TDCell = ({ value }: Value) => (
  <View style={[tableStyles.cell, tableStyles.td]}>
    <Text>{value ?? 'N/A'}</Text>
  </View>
)

const TableHeader = () => (
  <View style={[reportStyles.group]}>
    <THCell value="Event #" />
    <THCell value="Geozone" />
    <THCell value="Event Date" />
    <THCell value="Time In" />
    <THCell value="Time Out" />
    <THCell value="Duration In" />
    <THCell value="Parked Duration" />
    <THCell value="Times Visited" />
  </View>
)

interface LabelValueProps {
  label: string
  value?: string | null
}
const LabelValue = ({ label, value }: LabelValueProps) => (
  <View style={reportStyles.stack}>
    <Text style={reportStyles.subtitle}>
      {label}
    </Text>
    <Text style={reportStyles.subtitleValue}>
      {value ?? 'N/A'}
    </Text>
  </View>
)

interface DetailsProps {
  driver?: string | null
  group?: string | null
  date?: string | null
  count?: string | null
}
const Details = ({
  driver,
  group,
  date,
  count
}: DetailsProps) => (
  <View style={reportStyles.details}>
    <LabelValue
      label="Driver"
      value={driver}
    />
    <LabelValue
      label="Date/time"
      value={date}
    />
    <LabelValue
      label="Group"
      value={group}
    />
    <LabelValue
      label="Count"
      value={count}
    />
  </View>
)

export interface ParsedGeozone extends GeozoneEvent {}

interface TableRowProps extends ParsedGeozone {
  index: number
}

const TableRow = ({
  index,
  geoZoneName,
  timesVisited,
  enteredTime,
  leftTime,
  totalParkedTime,
  totalDurationInsideZone
}: TableRowProps) => {
  return (
    <View style={reportStyles.group}>
      <TDCell value={index.toString()} />
      <TDCell value={geoZoneName} />
      <TDCell value={dayjs(enteredTime).format(uiDateOnly)} />
      <TDCell value={dayjs(enteredTime).format(uiTimeOnly)} />
      <TDCell value={dayjs(leftTime).format(uiTimeOnly)} />
      <TDCell value={secondsToDhms(totalDurationInsideZone)} />
      <TDCell value={secondsToDhms(totalParkedTime ?? 0)} />
      <TDCell value={timesVisited.toString()} />
    </View>
  )
}

interface TableBodyProps {
  data: ParsedGeozone[]
}
const TableBody = ({ data }: TableBodyProps) => data.map((item, idx) => (
  <TableRow
    key={item.geoZoneId}
    index={idx + 1}
    {...item}
  />
))

const Space = () => <View style={reportStyles.space} />

function ExportablePdf ({
  data,
  agentName,
  driver,
  group,
  date,
  count
}: Props) {
  const title = `${agentName ?? 'Unknown'} - Geozone Event Report`
  return (
    <ReportTemplate
      title={title}
      page={{
        size: 'A4',
        orientation: 'landscape',
        style: reportStyles.page,
        wrap: true
      }}
    >
      <Details
        driver={driver}
        group={group}
        date={dayjs(date).format(uiDateFormatUniversal)}
        count={count}
      />
      <Space />
      <TableHeader />
      <TableBody data={data} />
    </ReportTemplate>
  )
}

export default ExportablePdf
