import { createStatelessApi } from '../configure'
import {
  type FullIncidentReportRequest,
  type PaginatedRequest,
  geoLocation,
  paginated
} from '../shared'
import { z } from 'zod'
import { orgUser } from './report'
// Request for both
interface GetSummaryRequest extends Record<string, unknown> {
  sensorInstanceId: string
  startTime?: string
  endTime?: string
}

const complianceSummary = z.object({
  sensorInstanceId: z.number(),
  average: z.optional(z.number()),
  minimum: z.optional(z.number()),
  maximum: z.optional(z.number()),
  samplesCollected: z.number(),
  alarms: z.number(),
  alarmIncidents: z.number(),
  unit: z.optional(z.string())
})

export type ComplianceSummary = z.infer<typeof complianceSummary>

export const getComplianceSummary = createStatelessApi<GetSummaryRequest, typeof complianceSummary>(
  'config',
  '/report/sensorInstance/:sensorInstanceId/compliance/summary',
  { method: 'GET', type: 'path' },
  complianceSummary
)

// Incident summary
const incident = z.object({
  alarmId: z.number(),
  name: z.string(),
  operator: z.string(),
  setPoint: z.number(),
  incidentId: z.number(),
  detected: z.string(),
  cleared: z.optional(z.string()),
  agentId: z.optional(z.number()),
  agentName: z.optional(z.string()),
  agentLocationPoint: z.optional(z.object({
    latitude: z.optional(z.number()),
    longitude: z.optional(z.number())
  })),
  geoZoneId: z.optional(z.number()),
  geoZoneName: z.optional(z.string())
})

export type Incident = z.infer<typeof incident>

const incidentSummary = paginated.extend({
  attributes: z.object({
    sensorInstanceId: z.number(),
    sensorInstanceName: z.string()
  }),
  items: z.array(incident)
})

// All incidents
const getAllIncidentsResponse = paginated.extend({
  items: z.array(incident)
})

export type IncidentsSummary = z.infer<typeof incidentSummary>

interface GetIncidentsRequest extends PaginatedRequest {
  sensorInstanceId: string
  startTime?: string
  endTime?: string
}

export const getIncidentsSummary = createStatelessApi<GetIncidentsRequest, typeof incidentSummary>(
  'config',
  '/report/sensorInstance/:sensorInstanceId/compliance/incidents',
  { method: 'GET', type: 'path' },
  incidentSummary
)

interface GetAllIncidentsRequest extends PaginatedRequest {
  orgId: number
  startTime: string
  endTime: string
}

export const getAllIncidents = createStatelessApi<GetAllIncidentsRequest, typeof getAllIncidentsResponse>(
  'config',
  '/report/compliance/org/:orgId/incidents',
  { method: 'GET', type: 'path' },
  getAllIncidentsResponse
)

// Incident report
const fullIncidentReport = z.object({
  agentName: z.string(),
  agentId: z.number(),
  minimum: z.number(),
  maximum: z.number(),
  average: z.number(),
  name: z.string(),
  operator: z.string(),
  setPoint: z.number(),
  detected: z.string(),
  cleared: z.optional(z.string()),
  timeGap: z.optional(z.string()),
  unit: z.optional(z.string())
})

export type FullIncidentReport = z.infer<typeof fullIncidentReport>

export const getFullIncidentReport = createStatelessApi<FullIncidentReportRequest, typeof fullIncidentReport>(
  'config',
  '/report/compliance/incident/:incidentId',
  { method: 'GET', type: 'path' },
  fullIncidentReport
)

const incidentBreach = z.object({
  breachId: z.number(),
  value: z.number(),
  detected: z.string(),
  detectedTime: z.optional(z.string()),
  alarmOperator: z.string(),
  alarmSetPoint: z.number(),
  agentLocationPoint: z.optional(geoLocation),
  speed: z.optional(z.number()),
  orgUser: z.optional(orgUser)
})

export type IncidentBreach = z.infer<typeof incidentBreach>

// Incident breaches/samples
const incidentBreaches = paginated.extend({
  items: z.array(incidentBreach)
})

interface GetIncidentBreachesRequest extends PaginatedRequest {
  incidentId: string
}

export const getIncidentBreaches = createStatelessApi<GetIncidentBreachesRequest, typeof incidentBreaches>(
  'config',
  '/report/compliance/incident/:incidentId/breaches',
  { method: 'GET', type: 'path' },
  incidentBreaches
)
