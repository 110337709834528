import React from 'react'
import { type ScheduleDay, type ScheduleType } from '@venturi-io/api/src/config/report'
import dayjs from 'dayjs'
import { toProperCase } from 'src/utils/strings'
import { Alert, Flex, Text } from '@mantine/core'
import { IconInfoCircle } from '@tabler/icons-react'
import { uiDateOnlyFormatUniversal } from 'src/utils/dates'

const getScheduleTypePrefix = (type: ScheduleType) => {
  switch (type) {
    case 'DAILY':
      return 'every day'
    case 'WEEKLY':
    case 'SPECIFIC_DAY_OF_WEEK':
      return 'every week'
    case 'FORTNIGHTLY':
      return 'every 2 weeks'
    case 'QUARTERLY':
      return 'every quarter'
    default:
      return 'every'
  }
}

interface RunTimeProps {
  type?: ScheduleType
  day?: ScheduleDay
  date?: string
  jobRunTime?: string
}

const getOrdinal = (d: number) => {
  const ordinal = d.toString()
  if (d > 3 && d < 21) return ordinal + 'th'
  switch (d % 10) {
    case 1:
      return ordinal + 'st'
    case 2:
      return ordinal + 'nd'
    case 3:
      return ordinal + 'rd'
    default:
      return ordinal + 'th'
  }
}

export const defaultRunTime = 'at 23:59:59'

export const getReadableRunTime = ({
  type,
  day,
  date,
  jobRunTime = defaultRunTime
}: RunTimeProps) => {
  const rawRunTime = jobRunTime.replace('at ', '')
  const sampleDate = `${dayjs().format(uiDateOnlyFormatUniversal)} ${rawRunTime}`
  const formattedRunTime = dayjs(sampleDate, `${uiDateOnlyFormatUniversal} HH:mm:ss`).format('h:mm A')
  if (!type) return `every day ${formattedRunTime}`

  const isWeeklyOrSpecific = type === 'SPECIFIC_DAY_OF_WEEK' || type === 'WEEKLY'
  const isStartRequired = type === 'FORTNIGHTLY' || type === 'QUARTERLY' || isWeeklyOrSpecific
  const isDateRequired = type !== 'DAILY' && !isStartRequired
  const convertedDate = dayjs(date)

  const readableType = getScheduleTypePrefix(type)

  // only show on weekly or specific day of the week
  const readableDay = isWeeklyOrSpecific
    ? type === 'SPECIFIC_DAY_OF_WEEK'
      ? `on ${day
          ? toProperCase(day)
          : 'Monday'
        }`
      : `on ${convertedDate.format('dddd')}`
    : undefined

  const readableDate = isDateRequired
    ? getOrdinal(convertedDate.date())
    : undefined

  const readbleStartDate = isStartRequired
    ? `starting at the ${getOrdinal(convertedDate.date())}`
    : undefined

  return [
    readableType,
    readableDay,
    readbleStartDate,
    readableDate,
    formattedRunTime
  ].join(' ')
}

interface Props {
  inline?: boolean
  jobRunTime?: string
  type?: ScheduleType
  day?: ScheduleDay
  date?: string
}

function ReportRunTime ({
  inline,
  type,
  day,
  date,
  jobRunTime
}: Props) {
  return jobRunTime && (
    <Alert
      py={6}
      color="primary"
      icon={<IconInfoCircle size="1rem" />}
    >
      <Flex
        align={inline
          ? 'center'
          : 'flex-start'}
        direction={inline
          ? 'row'
          : 'column'}
        gap={inline
          ? 'xs'
          : 0}
      >
        <Text size="sm" weight="bold">Report generation:</Text>
        <Text size="xs">
          {getReadableRunTime({
            type,
            day,
            date,
            jobRunTime
          })}
        </Text>
      </Flex>
    </Alert>
  )
}

export default ReportRunTime
