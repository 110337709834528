/* eslint-disable @typescript-eslint/naming-convention */
import { useNotifications } from 'src/utils/notifications'
import { useCallback, useState } from 'react'
import { Collapse, Stack, Text, useMantineTheme } from '@mantine/core'
import { useUser } from 'src/UserContext'
import { useDisclosure } from '@mantine/hooks'
import { getTripIdlingLocations, type IdlingTrips } from '@venturi-io/api/src/collector/trip'
import { reverseGeocode } from 'src/Maps/GeoZoneMap/mapbox'
import dayjs from 'dayjs'
import { dateFormat, uiDateFormatUniversal } from 'src/utils/dates'
import { secondsToDhms } from 'src/Assets/shared'
import { downloadCSV } from 'src/utils/files'
import { generatePdfDocument } from 'src/Reports/shared'
import { getLinkTextColor, getToggleableColorWBG } from 'src/utils/theme'
import RowExportButton, { type SuccessData } from '../RowExportButton'
import ExportablePdf, { type ParsedIdle } from './ExportablePdf'
import IdleTime from './IdleTime'
import { type IdlingItem, type Props } from '.'

interface RowProps extends Pick<Props, 'range' | 'maxWidth'> {
  trip: IdlingItem
}

function Row ({
  trip
}: RowProps) {
  const {
    agent_id,
    trip_id,
    agent_name,
    trip_start_time,
    trip_end_time,
    user_groups_names,
    org_user_first_name,
    org_user_last_name,
    total_idling_events,
    total_idling_duration_in_seconds
  } = trip

  const theme = useMantineTheme()
  const { token } = useUser()
  const { showError } = useNotifications()
  const [tableHeight, setTableHeight] = useState(200)
  const [open, { toggle }] = useDisclosure(false)

  const driver = org_user_first_name ?? org_user_last_name
    ? `${org_user_first_name ?? ''} ${org_user_last_name ?? ''}`
    : 'N/A'

  const loadIdlings = async (onSuccess: (idlings: IdlingTrips) => void) => {
    // I need to call it asynchrounusly and get the result on done fetching.
    // Saving to state causes delay and might not load the info correctly on the pdf
    await getTripIdlingLocations({
      tripId: Number(trip_id),
      startTime: trip_start_time,
      endTime: trip_end_time
    }, token).caseOf({
      Left: err => showError(err ?? 'Error on fetching idle trips'),
      Right: onSuccess
    })
  }

  const downloadableData = useCallback(async (
    type: 'CSV' | 'PDF',
    data: IdlingTrips
  ) => await Promise.all(
    data.map(async (event, idx) => {
      const {
        time,
        latitude,
        longitude,
        idlingDuration,
        geoZones
      } = event
      try {
        const location = await reverseGeocode([longitude, latitude])

        const geoZoneName = geoZones.map(({ name }) => name).join(', ')

        return type === 'CSV'
          ? {
              'Event #': idx + 1,
              'Event Time': dayjs(time).format(uiDateFormatUniversal),
              Location: location,
              Geozone: geoZoneName,
              'Idling Duration': secondsToDhms(idlingDuration)
            }
          : {
              ...event,
              location
            }
      } catch (err: any) {
        throw Error('Failed to generate idle time alert reports')
      }
    }))
  , [])

  const handleCSV = async (data: SuccessData) => {
    const events = await downloadableData('CSV', data as IdlingTrips)
    downloadCSV(events, `${agent_name} - Idle Time Report`)
  }

  const handleDownloadPdf = (mapUri?: string) => async (data: SuccessData) => {
    const events = await downloadableData(
      'PDF',
      data as IdlingTrips
    ) as never as ParsedIdle[]
    await generatePdfDocument((
      <ExportablePdf
        agentName={agent_name}
        data={events}
        mapUri={mapUri}
        count={events.length.toString()}
        date={trip_start_time}
        {...trip}
      />
    ), `${agent_name} - Idling Time Report`)
  }

  return (
    <>
      <tr
        onClick={toggle}
        style={{
          cursor: 'pointer',
          backgroundColor: getToggleableColorWBG(open),
          borderBottom: 1,
          position: open
            ? 'sticky'
            : 'initial',
          top: 34,
          zIndex: open
            ? 100
            : 'unset'
        }}
      >
        <td>
          <Text
            {...getLinkTextColor(theme)}
            weight={open
              ? 'bold'
              : 'normal'}
          >
            {agent_name}
          </Text>
        </td>
        <td>{dayjs(trip_start_time, dateFormat).format(uiDateFormatUniversal)}</td>
        <td>{total_idling_events}</td>
        <td>{secondsToDhms(Number(total_idling_duration_in_seconds))}</td>
        <td>{driver}</td>
        <td>{user_groups_names ?? 'N/A'}</td>
        <RowExportButton
          mode="idle"
          trip_id={trip_id}
          agent_id={agent_id}
          agent_name={agent_name}
          trip_end_time={trip_end_time}
          trip_start_time={trip_start_time}
          loadData={loadIdlings}
          onDownloadCsv={handleCSV}
          onDownloadPdf={handleDownloadPdf}
        />
      </tr>
      <tr>
        <td
          colSpan={7}
          style={{
            padding: 0,
            borderTop: 0,
            borderBottom: open
              ? 1
              : 0
          }}
        >
          <Collapse in={open}>
            {open && (
              <Stack>
                <IdleTime
                  showTitle={false}
                  startTime={trip_start_time}
                  endTime={trip_end_time}
                  tripId={parseInt(trip_id ?? '0')}
                  style={{
                    width: '100%',
                    height: tableHeight,
                    margin: 0
                  }}
                  stickyTop={78}
                  didChangeHeight={(height) => {
                    setTableHeight(height)
                  }}
                />
              </Stack>
            )}
          </Collapse>
        </td>
      </tr>
    </>
  )
}

export default Row
