import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import { reportStyles } from './styles'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 10
  },
  logo: {
    width: 160,
    height: 50,
    objectFit: 'contain'
  }
})

interface TitleProps {
  title: string
  imageUrl?: string
}
const Title = ({ title }: TitleProps) => (
  <View>
    <Text style={reportStyles.title}>{title}</Text>
  </View>
)

export default function Header ({ title, imageUrl }: TitleProps) {
  if (!imageUrl) {
    return null
  }

  return (
    <View style={styles.container} fixed>
      <Title title={title} />
      <Image src={imageUrl} style={styles.logo} />
    </View>
  )
}
