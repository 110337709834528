import React, { useMemo, useState } from 'react'
import {
  Box,
  createStyles,
  Group,
  Stack,
  Text,
  useMantineTheme
} from '@mantine/core'
import { AutoSizer, List, type ListRowProps } from 'react-virtualized'
import { type TripAgentLocation } from '@venturi-io/api/src/collector/trip'
import dayjs from 'dayjs'
import { uiDateFormatUniversal } from 'src/utils/dates'
import { computeIdleInMins } from 'src/utils/math'
import { IconHourglass } from '@tabler/icons-react'

const useStyles = createStyles(() => ({
  container: {
    position: 'absolute',
    top: 49,
    left: 0,
    right: 0,
    bottom: 0
  },
  list: {
    scrollbarWidth: 'thin',
    overflow: 'scroll'
  },
  itemLabel: {
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: '14px'
  },
  itemSubtitle: {
    fontSize: 10,
    color: 'dimmed',
    lineHeight: '10px'
  }
}))

interface Props {
  list?: TripAgentLocation[]
  onSelect?: (longitude: number, latitude: number) => void
}

const IdleList = ({ list, onSelect }: Props) => {
  const { colorScheme, colors } = useMantineTheme()
  const isLight = colorScheme === 'light'
  const { classes } = useStyles()
  const [cursor, setCursor] = useState(0)

  const handleClick = (longitude: number, latitude: number) => () => {
    if (onSelect) {
      onSelect(longitude, latitude)
    }
  }

  const sorted = useMemo(() => list?.sort((a, b) => (
    a.time < b.time
      ? 1
      : -1
  )), [list])

  return sorted && (
    <Box className={classes.container}>
      <AutoSizer>
        {({ width, height }) => (
          <List
            className={classes.list}
            style={{
              overflowY: 'scroll'
            }}
            height={height}
            width={width}
            rowCount={sorted.length}
            rowHeight={44}
            itemData={sorted}
            rowRenderer={({
              index,
              key,
              style
            }: ListRowProps) => {
              const {
                longitude,
                latitude,
                idlingDuration,
                time
              } = sorted[index]

              return (
                <Box
                  key={`${time}-${key}`}
                  px="sm"
                  style={{
                    ...style,
                    cursor: 'pointer',
                    backgroundColor: cursor === index
                      ? isLight
                        ? colors.gray[2]
                        : colors.dark[6]
                      : 'unset'
                  }}
                  onMouseOver={() => {
                    setCursor(index)
                  }}
                  onClick={handleClick(longitude, latitude)}
                >
                  <Group spacing="xs" py="sm">
                    <IconHourglass size={16} />
                    <Stack spacing={0}>
                      <Text className={classes.itemLabel}>
                        {computeIdleInMins(idlingDuration, true)}
                      </Text>
                      <Text className={classes.itemSubtitle}>
                        {dayjs(time).format(uiDateFormatUniversal)}
                      </Text>
                    </Stack>
                  </Group>
                </Box>
              )
            }}
          />
        )}
      </AutoSizer>
    </Box>
  )
}

export default IdleList
