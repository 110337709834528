import {
  Box,
  Button,
  Flex,
  Group,
  Paper,
  Text,
  useMantineTheme
} from '@mantine/core'
import { useState, type Dispatch, type ReactNode, type SetStateAction } from 'react'
import { IconArrowLeft, IconDots } from '@tabler/icons-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type Report } from '@venturi-io/api/src/config/report'
import { type Range } from '@venturi-io/api'
import dayjs from 'dayjs'
import { downloadCSV } from 'src/utils/files'
import { isEmpty } from 'src/utils/objects'
import TimePeriodButton, { type TimePeriod } from 'src/Buttons/TimePeriodButton'
import { uiDateFormatUniversal } from 'src/utils/dates'
import { getWhiteBackgroundColor } from 'src/utils/theme'
import SelectReport from 'src/Input/Select/SelectReport'
import { useNavigate } from 'react-router'
import { type ParsedTripItem } from './TripReportView'
import { type ParsedSpeedEvent } from './SpeedingReportView'
import { type ParsedDuressEvent } from './DuressReportView'
import { type ParsedIdleEvent } from './IdleReportView'
import { type ParsedGeozoneEvent } from './GeozoneReportView'
import { type SiteTankLevels } from './TankLevelReportView'
import ExportButton from './ExportButton'
import { formatList } from './TankLevelReportView/Content/List'

interface HeaderBarProps {
  report?: Report
  timePeriod: TimePeriod['value'] | null
  dateRange: Range | null
  reportData: Record<string, Array<Record<string, string | null>>>
  tripReportData: ParsedTripItem[]
  speedReportData: ParsedSpeedEvent[]
  duressReportData: ParsedDuressEvent[]
  idleReportData: ParsedIdleEvent[]
  geozoneReportData: ParsedGeozoneEvent[]
  tankLevelReportData: SiteTankLevels[]
  fromEmbed?: boolean
  busy: boolean
  extensions: ReactNode
  queryId: number
  setReport: Dispatch<SetStateAction<Report | undefined>>
  onGenerate: (range: Range | null, timePeriod: TimePeriod['value'] | null) => void
}

function HeaderBar ({
  report,
  timePeriod,
  dateRange,
  reportData,
  tripReportData,
  speedReportData,
  duressReportData,
  idleReportData,
  geozoneReportData,
  tankLevelReportData,
  fromEmbed = false,
  busy = false,
  extensions,
  queryId,
  setReport,
  onGenerate
}: HeaderBarProps) {
  const theme = useMantineTheme()
  const navigate = useNavigate()
  const [timePeriodValue, setTimePeriodValue] = useState<TimePeriod['value'] | null>(timePeriod)
  const [upcomingRange, setUpcomingRange] = useState<Range | null>(dateRange)
  const disabledDownload = isEmpty(reportData)

  const isValidRange = () => {
    if (!report) {
      return true
    }
    const { reportType: { name } } = report
    switch (name) {
      case 'Trip History':
      case 'Duress Event Report':
      case 'Idle Time Report':
      case 'Geozone Event Report':
      case 'Speed Event Report':
      case 'Tank Level History Report':
        return upcomingRange
          ? dayjs(upcomingRange.from).isBefore(dayjs(upcomingRange.to))
          : true
      default:
        return true
    }
  }

  const handleDownloadReportAsCSV = (name: string) => {
    if (!report) {
      return true
    }
    const { reportType: { name: reportType } } = report
    switch (reportType) {
      case 'Trip History':
        return downloadCSV(tripReportData as unknown as Array<Record<string, unknown>>, name)
      case 'Duress Event Report':
        return downloadCSV(duressReportData as unknown as Array<Record<string, unknown>>, name)
      case 'Idle Time Report':
        return downloadCSV(idleReportData as unknown as Array<Record<string, unknown>>, name)
      case 'Geozone Event Report':
        return downloadCSV(geozoneReportData as unknown as Array<Record<string, unknown>>, name)
      case 'Speed Event Report':
        return downloadCSV(speedReportData as unknown as Array<Record<string, unknown>>, name)
      case 'Tank Level History Report': {
        const transformedData = formatList(
          tankLevelReportData.reduce((output: SiteTankLevels['list'], { list }) => ([
            ...output,
            ...list
          ]), [])
        )

        return downloadCSV(transformedData, name)
      }
      default:
        return Object
          .keys(reportData)
          .map(queryId => {
            downloadCSV(reportData[queryId], name)
            return queryId
          })
    }
  }

  const handleGenerate = () => {
    onGenerate(upcomingRange, timePeriodValue)
  }

  const getReportData = () => {
    if (!report) {
      return tripReportData
    }
    const { reportType: { name: reportType } } = report
    switch (reportType) {
      case 'Trip History':
        return tripReportData
      case 'Duress Event Report':
        return duressReportData
      case 'Idle Time Report':
        return idleReportData
      case 'Geozone Event Report':
        return geozoneReportData
      case 'Tank Level History Report':
        return tankLevelReportData
      default:
        return speedReportData
    }
  }

  return (
    <Paper my={0} shadow="xs">
      <Flex
        align="flex-end"
        p="md"
        pb={reportData[queryId] ? 0 : 'md'}
      >
        <Group
          position="left"
          spacing="sm"
          align="flex-end"
          sx={{ flexGrow: 1 }}
        >
          {fromEmbed && (
            <Button
              color="gray"
              variant="outline"
              leftIcon={<IconArrowLeft size={16} />}
              onClick={() => navigate('/reports/generated', { replace: true })}
            >
              Back
            </Button>
          )}
          <SelectReport
            disabled={fromEmbed}
            value={report?.reportType?.id}
            onChange={val => setReport(val)}
          />
          {extensions}
          {!fromEmbed && (
            <TimePeriodButton
              timePeriod={timePeriodValue}
              dateRange={upcomingRange}
              onChange={(range, timePeriod) => {
                setUpcomingRange(range)
                setTimePeriodValue(timePeriod)
              }}
              onSubmit={handleGenerate}
            />
          )}
          {report && !fromEmbed && (
            <Button
              title="Generate"
              color="primary"
              leftIcon={<FontAwesomeIcon icon={['fas', 'rotate']} />}
              onClick={handleGenerate}
              disabled={!isValidRange()}
              loading={busy}
            >
              Generate
            </Button>
          )}
        </Group>
        {report && (
          <ExportButton
            Icon={IconDots}
            report={report}
            queryId={queryId}
            timePeriod={timePeriodValue}
            range={dateRange}
            reportData={reportData}
            data={getReportData()}
            onClickDownloadAsCSV={() => {
              handleDownloadReportAsCSV(report.name)
            }}
            disabled={disabledDownload}
          />
        )}
      </Flex>
      {dateRange && reportData[queryId] && (
        <Box
          p="md"
          sx={{
            borderRadius: 'sm',
            ...getWhiteBackgroundColor(theme),
            position: 'sticky',
            top: 0,
            zIndex: 100
          }}
        >
          <Text size="sm" px="md" color="dimmed">
            Displaying
            {' '}
            <strong>{reportData[queryId].length}</strong>
            {' '}
            events from
            {' '}
            {dayjs(dateRange.from).format(uiDateFormatUniversal)}
            {' '}
            to
            {' '}
            {dayjs(dateRange.to).format(uiDateFormatUniversal)}
          </Text>
        </Box>
      )}
    </Paper>
  )
}

export default HeaderBar
