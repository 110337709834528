/* eslint-disable @typescript-eslint/naming-convention */
import { Image, Text, View } from '@react-pdf/renderer'
import { type Report } from '@venturi-io/api/src/config/report'
import { type Range } from '@venturi-io/api'
import dayjs from 'dayjs'
import { dateFormat, uiDateFormatRegular } from 'src/utils/dates'
import ReportTemplate from 'src/Layout/ReportTemplate'
import { reportStyles, tableStyles } from 'src/Layout/ReportTemplate/styles'
import { toProperCase, truncateWithEllipsis } from 'src/utils/strings'
import { type SiteTankLevels } from '../TankLevelReportView'

interface Props {
  report: Report
  data: SiteTankLevels[]
  range: Range | null
}

const formatToReadableDate = (date?: string | null) => date
  ? dayjs(date, dateFormat).format(uiDateFormatRegular)
  : '--'

interface LabelValueProps {
  label: string
  value?: string
}
const LabelValue = ({ label, value }: LabelValueProps) => (
  <View style={reportStyles.stack}>
    <Text style={reportStyles.subtitle}>
      {label}
    </Text>
    <Text style={reportStyles.subtitleValue}>
      {value ?? '--'}
    </Text>
  </View>
)

interface DetailsProps {
  report: Report
  range: Range | null
}
const Details = ({ report, range }: DetailsProps) => (
  <View style={reportStyles.details}>
    <LabelValue label="Report type" value={report.reportType.name} />
    <LabelValue
      label="Period Range"
      value={range
        ? `${formatToReadableDate(range.from)} - ${formatToReadableDate(range.to)}`
        : '--'}
    />
  </View>
)

interface Value {
  value: string | null
}

const THCell = ({ value }: Value) => (
  <View
    style={[
      tableStyles.cell,
      tableStyles.tHead
    ]}
  >
    <Text>{value ?? 'N/A'}</Text>
  </View>
)

const TDCell = ({ value }: Value) => (
  <View
    style={[
      tableStyles.cell,
      tableStyles.td
    ]}
  >
    <Text>{value ?? 'N/A'}</Text>
  </View>
)

const TableHeader = () => (
  <View style={[reportStyles.group]}>
    <THCell value="Tank" />
    <THCell value="Fluid" />
    <THCell value="Tank Capacity (L)" />
    <THCell value="Start Date/Time" />
    <THCell value="Start Volume (L)" />
    <THCell value="End Date/Time" />
    <THCell value="End Volume (L)" />
  </View>
)

const TableRow = ({
  agent_name,
  agent_liquid_type,
  tank_capacity,
  start_time,
  start_volume,
  end_time,
  end_volume
}: SiteTankLevels['list'][0]) => (
  <View style={reportStyles.group}>
    <TDCell value={truncateWithEllipsis(agent_name, 20)} />
    <TDCell value={agent_liquid_type} />
    <TDCell value={tank_capacity} />
    <TDCell value={start_time} />
    <TDCell value={start_volume} />
    <TDCell value={end_time} />
    <TDCell value={end_volume} />
  </View>
)

interface TableBodyProps {
  data: SiteTankLevels['list']
}

const TableBody = ({ data }: TableBodyProps) => (
  data.map((item) => (
    <TableRow
      key={`${item.site_id}-${item.agent_id}`}
      {...item}
    />
  ))
)

const Space = () => <View style={reportStyles.space} />

export default function TankLevelHistory ({ report, data, range }: Props) {
  return (
    <ReportTemplate
      title={report.name}
      page={{
        size: 'A4',
        orientation: 'landscape',
        style: reportStyles.page,
        wrap: true
      }}
    >
      <Details report={report} range={range} />
      <Space />
      {data.map(({
        site_id,
        site_name,
        list,
        chart_url
      }, index) => (
        <View
          key={site_id}
          style={reportStyles.stack}
          break={index > 0}
          wrap={false}
        >
          <Text style={reportStyles.stack}>
            {toProperCase(site_name)}
          </Text>
          <View>
            <TableHeader />
            <TableBody data={list} />
            {chart_url && (
              <View style={reportStyles.imageContainer}>
                <Image src={chart_url} style={reportStyles.chartImage} />
              </View>
            )}
          </View>
        </View>
      ))}
    </ReportTemplate>
  )
}
