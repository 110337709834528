import {
  ActionIcon,
  Button,
  Popover,
  Stack,
  Text
} from '@mantine/core'
import { type Report } from '@venturi-io/api/src/config/report'
import { type Range } from '@venturi-io/api'
import { type Icon, IconFileTypeCsv } from '@tabler/icons-react'
import { type TimePeriod } from 'src/Buttons/TimePeriodButton'
import { type ParsedTripItem } from './TripReportView'
import { type ParsedSpeedEvent } from './SpeedingReportView'
import { type ParsedDuressEvent } from './DuressReportView'
import { type ParsedIdleEvent } from './IdleReportView'
import { type SiteTankLevels } from './TankLevelReportView'
import ExportToPdf from './ExportToPdf'

type Parsed =
  ParsedTripItem[] |
  ParsedSpeedEvent[] |
  ParsedDuressEvent[] |
  ParsedIdleEvent[] |
  SiteTankLevels[]

interface Props {
  Icon: Icon
  report: Report
  queryId?: number
  timePeriod: TimePeriod['value'] | null
  range: Range | null
  reportData: Record<string, Array<Record<string, string | null>>>
  data?: Parsed
  onClickDownloadAsCSV: () => void
  disabled?: boolean
}

export default function ExportButton ({
  Icon,
  report,
  queryId,
  timePeriod,
  range,
  reportData,
  data,
  onClickDownloadAsCSV,
  disabled
}: Props) {
  return (
    <Popover position="bottom-end" arrowOffset={12} withArrow>
      <Popover.Target>
        <ActionIcon size={36} disabled={disabled}>
          <Icon size={16} />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack spacing="xs">
          <Text color="dimmed" size="xs">
            Export data
          </Text>
          <Button
            leftIcon={<IconFileTypeCsv size={16} />}
            variant="subtle"
            color="primary"
            onClick={onClickDownloadAsCSV}
            disabled={disabled}
          >
            Download as CSV
          </Button>
          <ExportToPdf
            report={report}
            rawData={reportData}
            data={data}
            timePeriod={timePeriod}
            range={range}
            queryId={queryId}
            disabled={disabled}
          />
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}
