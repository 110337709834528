import { Text } from '@mantine/core'
import { useStyles } from 'src/Layout/ActionList'

const Header = () => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.row, classes.header)}>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Asset Name
        </Text>
      </div>
      <div
        className={classes.col}
        style={{
          justifyContent: 'center'
        }}
      >
        <Text weight={600} size="sm">
          Status
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          KW Hour
        </Text>
      </div>
      <div className={`${classes.col} ${classes.smallCol}`}>
        <Text weight={600} size="sm">
          Hz
        </Text>
      </div>
      <div className={`${classes.col} ${classes.smallCol}`}>
        <Text weight={600} size="sm">
          V
        </Text>
      </div>
      <div className={`${classes.col} ${classes.smallCol}`}>
        <Text weight={600} size="sm">
          A
        </Text>
      </div>
    </div>
  )
}

export default Header
