import { Text } from '@mantine/core'
import { useStyles } from 'src/Layout/ActionList'

const Header = () => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.row, classes.header)}>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Detected
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          User
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Location
        </Text>
      </div>
      <div className={classes.col}>
        <Text weight={600} size="sm">
          Value
        </Text>
      </div>
    </div>
  )
}

export default Header
