import { type AgentLocation } from '@venturi-io/api/src/collector/agent'
import React, {
  type RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { type MapRef, Source, Layer as MapLayer, type LayerProps } from 'react-map-gl'
import { useMantineTheme } from '@mantine/core'
import mapboxgl, { type LngLatLike } from 'mapbox-gl'
import { useMediaQuery } from '@mantine/hooks'
import { mq } from 'src/utils/style'
import { generateLocationPoints, generateRawLocationLinePath } from '../../mapbox'
import { useDashLineLayer } from '../../Trips/Layer/DashLineLayer'
import { useViewTypes } from '../../AgentAttendanceContext'
import Popup from './Popup'

interface Props {
  locations: AgentLocation[]
  mapRef: RefObject<MapRef>
}

type MouseEvent = mapboxgl.MapMouseEvent & {
  features?: mapboxgl.MapboxGeoJSONFeature[] | undefined
} & mapboxgl.EventData

function Layer ({
  locations,
  mapRef
}: Props) {
  const theme = useMantineTheme()
  const isDesktop = useMediaQuery(mq(theme.breakpoints.sm, false))
  const { colors } = theme
  const {
    dashLineArray,
    start: startDashAnimation,
    stop: stopDashAnimation
  } = useDashLineLayer()
  const { selectedLocation, setSelectedLocation } = useViewTypes()
  const [properties, setProperties] = useState<AgentLocation | null>(null)
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)

  const pointSource = useMemo(() => (
    generateLocationPoints(locations)
  ), [locations])

  const lineSource = useMemo(() => (
    generateRawLocationLinePath([...locations].reverse())
  ), [locations])

  useEffect(() => {
    if (mapRef.current && locations.length > 0) {
      setIsPopupOpen(false)
      const coordinates: LngLatLike[] = locations.map(({
        geoLocation: {
          longitude,
          latitude
        }
      }) => ({
        lng: longitude,
        lat: latitude
      }))

      // Create a 'LngLatBounds' with both corners at the first coordinate.
      const bounds = new mapboxgl.LngLatBounds(
        coordinates[0],
        coordinates[0]
      )

      // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
      for (const coord of coordinates) {
        bounds.extend(coord)
      }
      mapRef.current.fitBounds(
        bounds,
        isDesktop
          ? {
              padding: {
                left: 40,
                right: 200,
                top: 40,
                bottom: 40
              },
              maxZoom: 16
            }
          : {
              padding: 40,
              maxZoom: 16
            })
    }
  }, [locations])

  const pointLayer: LayerProps = {
    id: 'raw-pointer',
    type: 'circle',
    paint: {
      'circle-color': colors.primary[7],
      'circle-opacity': 1,
      'circle-radius': 6,
      'circle-stroke-color': '#fff',
      'circle-stroke-opacity': 1,
      'circle-stroke-width': 1
    }
  }

  const dashLayer: LayerProps = {
    type: 'line',
    layout: {
      'line-join': 'miter',
      'line-cap': 'butt'
    },
    paint: {
      'line-color': 'white',
      'line-width': 4,
      'line-dasharray': dashLineArray
    }
  }

  useEffect(() => {
    void startDashAnimation()

    return () => {
      void stopDashAnimation()
    }
  }, [locations])

  const map = mapRef.current
    ? mapRef.current.getMap()
    : null

  const handleHoverEvent = useCallback((pointer: string) => () => {
    if (map) {
      map.getCanvas().style.cursor = pointer
    }
  }, [map])

  const handlePointClick = useCallback((e: MouseEvent) => {
    if (map) {
      // Copy coordinates array.
      if (e.features) {
        const feature = e.features[0]
        const { properties, geometry } = feature
        if (properties && geometry.type === 'Point') {
          const props = properties as AgentLocation
          const coordinates = geometry.coordinates.slice()
          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0]
              ? 360
              : -360
          }

          setProperties(() => {
            setIsPopupOpen(true)

            return {
              ...props,
              geoLocation: {
                latitude: coordinates[1],
                longitude: coordinates[0]
              }
            }
          })
        }
      }
    }
  }, [map])

  useEffect(() => {
    if (map) {
      map.on('mouseleave', ['raw-pointer'], handleHoverEvent(''))
      map.on('mouseenter', ['raw-pointer'], handleHoverEvent('pointer'))
      map.on('click', ['raw-pointer'], handlePointClick)
    }

    return () => {
      if (map) {
        map.off('mouseleave', ['raw-pointer'], handleHoverEvent(''))
        map.off('mouseenter', ['raw-pointer'], handleHoverEvent('pointer'))
        map.off('click', ['raw-pointer'], handlePointClick)
      }
    }
  }, [map])

  useEffect(() => {
    if (selectedLocation) {
      setProperties(() => {
        setIsPopupOpen(true)

        return selectedLocation
      })
    }
  }, [selectedLocation])

  useEffect(() => {
    if (!isPopupOpen && setSelectedLocation) {
      setSelectedLocation(undefined)
    }
  }, [isPopupOpen])

  return (
    <>
      <Popup
        isOpen={isPopupOpen}
        setIsOpen={setIsPopupOpen}
        properties={properties}
        map={map}
      />
      <Source {...lineSource}>
        <MapLayer {...dashLayer} />
      </Source>
      <Source {...pointSource}>
        <MapLayer {...pointLayer} />
      </Source>
    </>
  )
}

export default Layer
