import { useState } from 'react'
import { createStyles, Box, Stack, Paper } from '@mantine/core'
import { mq } from 'src/utils/style'
import { type IncidentBreach } from '@venturi-io/api/src/config/alarmHistory'
import BreachMap from './BreachMap'
import Breaches from './Breaches'

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    flex: 1,
    width: '100%',
    [mq(theme.breakpoints.xs)]: {
      width: 1
    }
  }
}))

export default function Content () {
  const { classes } = useStyles()
  const [breaches, setBreaches] = useState<IncidentBreach[]>([])

  return (
    <Box className={classes.container}>
      <Paper radius={4} shadow="xs">
        <Stack spacing="xs">
          <BreachMap breaches={breaches} />
          <Breaches onUpdateBreaches={(items) => setBreaches(items)} />
        </Stack>
      </Paper>
    </Box>
  )
}
