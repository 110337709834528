/* eslint-disable @typescript-eslint/naming-convention */
import { useNotifications } from 'src/utils/notifications'
import { useCallback, useState } from 'react'
import { Collapse, Text, useMantineTheme } from '@mantine/core'
import { useUser } from 'src/UserContext'
import { useDisclosure } from '@mantine/hooks'
import { reverseGeocode } from 'src/Maps/GeoZoneMap/mapbox'
import dayjs from 'dayjs'
import { dateFormat, uiDateFormatUniversal } from 'src/utils/dates'
import { downloadCSV } from 'src/utils/files'
import { generatePdfDocument } from 'src/Reports/shared'
import { getLinkTextColor, getToggleableColorWBG } from 'src/utils/theme'
import { getSpeedAlerts, type SpeedAlert } from '@venturi-io/api/src/analytics/speedAlerts'
import RowExportButton, { type SuccessData } from '../RowExportButton'
import ExportablePdf, { type ParsedSpeedAlert } from './ExportablePdf'
import SpeedAlerts from './SpeedAlerts'
import { type SpeedAlertItem, type Props } from '.'

interface RowProps extends Pick<Props, 'range' | 'maxWidth'> {
  trip: SpeedAlertItem
}

function Row ({ trip }: RowProps) {
  const {
    agent_id,
    trip_id,
    agent_name,
    total_speed_alerts,
    trip_start_time,
    trip_end_time,
    org_user_first_name,
    org_user_last_name
  } = trip
  const theme = useMantineTheme()
  const { token, orgId } = useUser()
  const { showError } = useNotifications()
  const [tableHeight, setTableHeight] = useState(200)
  const [open, { toggle }] = useDisclosure(false)

  const driver = org_user_first_name ?? org_user_last_name
    ? `${org_user_first_name ?? ''} ${org_user_last_name ?? ''}`
    : 'N/A'

  const loadSpeedings = async (onSuccess: (data: SpeedAlert[]) => void) => {
    // I need to call it asynchrounusly and get the result on done fetching.
    // Saving to state causes delay and might not load the info correctly on the pdf
    await getSpeedAlerts({
      orgId,
      tripId: Number(trip_id),
      parentLog: true,
      page: 1,
      size: 999
    }, token).caseOf({
      Left: err => showError(err ?? 'Error on fetching idle trips'),
      Right: ({ items }) => onSuccess(items)
    })
  }

  const downloadableData = useCallback(async (
    type: 'CSV' | 'PDF',
    data: SpeedAlert[]
  ) => await Promise.all(data.map(async (event, idx) => {
    const {
      speed,
      speedLimit,
      speedDateTime,
      geoZoneName,
      agentLocationPoint
    } = event
    try {
      const speed_location = agentLocationPoint
        ? await reverseGeocode([agentLocationPoint.longitude, agentLocationPoint.latitude])
        : 'N/A'

      return type === 'CSV'
        ? {
            'Event #': idx + 1,
            'Event Time': dayjs(speedDateTime).format(uiDateFormatUniversal),
            Location: speed_location,
            Geozone: geoZoneName ?? 'N/A',
            'Speed Limit': `${speedLimit} km/h`,
            'Speed Violation': `${speed} km/h`
          }
        : {
            ...event,
            location: speed_location
          }
    } catch (err: any) {
      throw Error('Failed to generate speeding alert reports')
    }
  })), [])

  const handleCSV = async (data: SuccessData) => {
    const events = await downloadableData('CSV', data as SpeedAlert[])
    downloadCSV(events, `${agent_name} - Speed Event Report`)
  }

  const handleDownloadPdf = (mapUri?: string) => async (data: SuccessData) => {
    const events = await downloadableData(
      'PDF',
      data as SpeedAlert[]
    ) as never as ParsedSpeedAlert[]
    await generatePdfDocument((
      <ExportablePdf
        agentName={agent_name}
        data={events}
        mapUri={mapUri}
        count={events.length.toString()}
        date={trip_start_time}
        {...trip}
      />
    ), `${agent_name} - Speed Event Report`)
  }

  return (
    <>
      <tr
        onClick={toggle}
        style={{
          cursor: 'pointer',
          backgroundColor: getToggleableColorWBG(open),
          borderBottom: 1,
          position: open
            ? 'sticky'
            : 'initial',
          top: 34,
          zIndex: open
            ? 100
            : 'unset'
        }}
      >
        <td>
          <Text
            {...getLinkTextColor(theme)}
            weight={open
              ? 'bold'
              : 'normal'}
          >
            {agent_name}
          </Text>
        </td>
        <td>{dayjs(trip_start_time, dateFormat).format(uiDateFormatUniversal)}</td>
        <td>{total_speed_alerts}</td>
        <td>{driver}</td>
        <RowExportButton
          mode="speeding"
          trip_id={trip_id}
          agent_id={agent_id}
          agent_name={agent_name}
          trip_end_time={trip_end_time}
          trip_start_time={trip_start_time}
          loadData={loadSpeedings}
          onDownloadCsv={handleCSV}
          onDownloadPdf={handleDownloadPdf}
        />
      </tr>
      <tr>
        <td
          colSpan={6}
          style={{
            padding: 0,
            borderTop: 0,
            borderBottom: open
              ? 1
              : 0
          }}
        >
          <Collapse in={open}>
            {open && (
              <SpeedAlerts
                showTitle={false}
                tripId={parseInt(trip_id ?? '0')}
                style={{
                  width: '100%',
                  height: tableHeight,
                  margin: 0
                }}
                stickyTop={78}
                didChangeHeight={(height) => {
                  setTableHeight(height)
                }}
              />
            )}
          </Collapse>
        </td>
      </tr>
    </>
  )
}

export default Row
