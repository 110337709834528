import { Text } from '@mantine/core'
import { type Row as ListRow, useStyles } from 'src/Layout/ActionList'
import { type IncidentBreach } from '@venturi-io/api/src/config/alarmHistory'
import dayjs from 'dayjs'
import { uiDateFormatUniversal } from 'src/utils/dates'
import { useAddress } from 'src/Maps/CoreMap/shared'

export default function Row ({
  value,
  speed,
  orgUser,
  agentLocationPoint,
  detected
}: IncidentBreach & ListRow) {
  const { classes } = useStyles()
  const address = useAddress(agentLocationPoint)

  return (
    <div className={`${classes.row} ${classes.onHover}`}>
      <div className={classes.col}>
        <Text size="sm">
          {dayjs(detected).format(uiDateFormatUniversal)}
        </Text>
      </div>
      <div className={classes.col}>
        <Text
          color={orgUser
            ? undefined
            : 'dimmed'}
          size="sm"
        >
          {orgUser
            ? `${orgUser.firstName} ${orgUser.lastName}`
            : 'N/A'}
        </Text>
      </div>
      <div className={classes.col}>
        <Text
          color={address
            ? undefined
            : 'dimmed'}
          size="sm"
        >
          {address ?? 'N/A'}
        </Text>
      </div>
      <div className={classes.col}>
        <Text size="sm">
          {typeof speed !== 'undefined' && speed !== 0
            ? speed
            : value}
        </Text>
      </div>
    </div>
  )
}
