/* eslint-disable @typescript-eslint/naming-convention */
import { Text, View } from '@react-pdf/renderer'
import dayjs from 'dayjs'
import { dateFormat, uiDateFormatRegular } from 'src/utils/dates'
import ReportTemplate from 'src/Layout/ReportTemplate'
import { reportStyles, tableStyles } from 'src/Layout/ReportTemplate/styles'
import { type Range } from '@venturi-io/api'
import { type AgentTripItem } from '.'

interface Props {
  agentName: string
  data: AgentTripItem[]
  dateRange: Range | null
  totalTrips: string
  totalDuration: string
  totalDistance: string
}

const formatToReadableDate = (date?: string) => date
  ? dayjs(date, dateFormat).format(uiDateFormatRegular)
  : '--'

interface LabelValueProps {
  label: string
  value?: string
}
const LabelValue = ({ label, value }: LabelValueProps) => (
  <View style={reportStyles.stack}>
    <Text style={reportStyles.subtitle}>
      {label}
    </Text>
    <Text style={reportStyles.subtitleValue}>
      {value ?? '--'}
    </Text>
  </View>
)

interface DetailsProps {
  dateRange: Range | null
  totalTrips: string
  totalDuration: string
  totalDistance: string
}
const Details = ({
  dateRange,
  totalTrips,
  totalDuration,
  totalDistance
}: DetailsProps) => (
  <View style={reportStyles.details}>
    <LabelValue
      label="Period Range"
      value={dateRange
        ? `${formatToReadableDate(dateRange.from)} - ${formatToReadableDate(dateRange.to)}`
        : '--'}
    />
    <LabelValue
      label="Total Duration"
      value={totalDuration}
    />
    <LabelValue
      label="No. of Trips"
      value={totalTrips}
    />
    <LabelValue
      label="Total Distance"
      value={`${totalDistance} KMs`}
    />
  </View>
)

interface Value {
  value: string
}

const THCell = ({ value }: Value) => (
  <View style={[tableStyles.cell, tableStyles.tHead]}>
    <Text>{value}</Text>
  </View>
)

const TDCell = ({ value }: Value) => (
  <View style={[tableStyles.cell, tableStyles.td]}>
    <Text>{value}</Text>
  </View>
)

const TableHeader = () => (
  <View style={[reportStyles.group]}>
    <THCell value="Start Date" />
    <THCell value="End Date" />
    <THCell value="Driver" />
    <THCell value="Start Location" />
    <THCell value="End Location" />
    <THCell value="Driving Duration" />
    <THCell value="Idling Duration" />
    <THCell value="KMs Travelled" />
    <THCell value="Average Speed" />
    <THCell value="Max Speed" />
    <THCell value="Max Accel (X)" />
    <THCell value="Max Accel (Y)" />
    <THCell value="Max Accel (Z)" />
  </View>
)

const TableRow = ({
  start_time,
  end_time,
  driver,
  start_location,
  end_location,
  driving_duration,
  idling_duration,
  distance_travelled,
  average_speed,
  max_speed,
  max_x_acceleration,
  max_y_acceleration,
  max_z_acceleration
}: AgentTripItem) => (
  <View style={reportStyles.group}>
    <TDCell value={start_time} />
    <TDCell value={end_time} />
    <TDCell value={driver} />
    <TDCell value={start_location} />
    <TDCell value={end_location} />
    <TDCell value={driving_duration} />
    <TDCell value={idling_duration} />
    <TDCell value={distance_travelled} />
    <TDCell value={average_speed} />
    <TDCell value={max_speed} />
    <TDCell value={max_x_acceleration} />
    <TDCell value={max_y_acceleration} />
    <TDCell value={max_z_acceleration} />
  </View>
)

interface TableBodyProps {
  data: AgentTripItem[]
}
const TableBody = ({ data }: TableBodyProps) => data.map(item => (
  <TableRow key={item.trip_id} {...item} />
))

const Space = () => <View style={reportStyles.space} />

function ExportablePdf ({
  agentName,
  data,
  dateRange,
  totalTrips,
  totalDuration,
  totalDistance
}: Props) {
  const title = `${agentName} - Trip History`
  return (
    <ReportTemplate
      title={title}
      page={{
        size: 'A4',
        orientation: 'landscape',
        style: reportStyles.page,
        wrap: true
      }}
    >
      <Details
        dateRange={dateRange}
        totalTrips={totalTrips}
        totalDuration={totalDuration}
        totalDistance={totalDistance}
      />
      <Space />
      <TableHeader />
      <TableBody data={data} />
    </ReportTemplate>
  )
}

export default ExportablePdf
