import { useEffect } from 'react'
import { getAllSensorDataLogs, type SensorDataLog } from '@venturi-io/api/src/collector/sensor'
import { usePaginatedApi } from 'src/utils/useApi'
import { useUser } from 'src/UserContext'
import { dateFormat } from 'src/utils/dates'
import dayjs from 'dayjs'
import ActionList from 'src/Layout/ActionList'
import Nothing from 'src/Nothing'
import { type Range } from '@venturi-io/api'
import { useDebouncedValue } from '@mantine/hooks'
import { Card, Header, Row } from './List'

interface Props {
  agentId?: number
  sensorInstanceId?: number
  dateRange: Range | null
  setLogs: (data: SensorDataLog[]) => void
  setTotalLogs: (total: number) => void
}

export default function Logs ({
  agentId,
  sensorInstanceId,
  dateRange,
  setLogs,
  setTotalLogs
}: Props) {
  const { token } = useUser()
  const allSensorDataLogs = usePaginatedApi(
    getAllSensorDataLogs,
    true,
    true,
    false,
    50
  )
  const [debouncedRange] = useDebouncedValue(dateRange, 500)

  const { from, to } = debouncedRange
    ? {
        from: dayjs(debouncedRange.from, dateFormat),
        to: dayjs(debouncedRange.to, dateFormat)
      }
    : {
        from: null,
        to: null
      }

  const isMoreThanAWeek = to?.isBefore(from.add(7, 'days').add(1, 'minute'))

  const loadEvents = async (page?: number, size?: number): Promise<void> => {
    if (!isMoreThanAWeek) {
      return
    }
    const req = {
      page: page ?? allSensorDataLogs.page,
      size: size ?? allSensorDataLogs.size,
      agentId,
      sensorInstanceId,
      startTime: dayjs(from).format(`${dateFormat}Z`),
      endTime: dayjs(to).format(`${dateFormat}Z`)
    }
    void allSensorDataLogs
      .fetch(req, token)
      .finally(() => {
        allSensorDataLogs.startPolling(req, token, 60)
      })
  }

  useEffect(() => {
    void loadEvents(1)

    return () => {
      allSensorDataLogs.stopPolling()
      allSensorDataLogs.abort()
    }
  }, [
    agentId,
    sensorInstanceId,
    debouncedRange,
    allSensorDataLogs.size
  ])

  useEffect(() => {
    void loadEvents()

    return () => {
      allSensorDataLogs.stopPolling()
      allSensorDataLogs.abort()
    }
  }, [
    allSensorDataLogs.page
  ])

  useEffect(() => {
    allSensorDataLogs.data.ifJust(({ items, totalItems }) => {
      setLogs(items)
      setTotalLogs(totalItems)
    })
  }, [allSensorDataLogs.data])

  return (
    <>
      {allSensorDataLogs.data.caseOf({
        Nothing: () => (
          <Nothing
            isLoading={allSensorDataLogs.loading}
            nothing={allSensorDataLogs.data.isNothing()}
          />
        ),
        Just: ({ items }) => (
          <ActionList
            isLoading={allSensorDataLogs.loading}
            data={items}
            getId={({ id }) => id}
            header={() => <Header />}
            row={Row}
            card={Card}
            totalPages={allSensorDataLogs.data.mapOrDefault(({ totalPages }) => totalPages, 0)}
            page={allSensorDataLogs.page}
            size={allSensorDataLogs.size}
            onPageChange={allSensorDataLogs.setPage}
            onSizeChange={allSensorDataLogs.setSize}
          />
        )
      })}
    </>
  )
}
