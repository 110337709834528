/* eslint-disable @typescript-eslint/naming-convention */
import React, {
  type Dispatch,
  type SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle
} from 'react'
import { useDisclosure } from '@mantine/hooks'
import { useMantineTheme, Box, Modal, Text } from '@mantine/core'
import html2canvas from 'html2canvas'
import { type TimePeriod } from 'src/Buttons/TimePeriodButton'
import MantineLoader from 'src/Layout/Loader'
import Chart from './Content/Chart'
import { type SiteTankLevels } from '.'

const generateChartId = (siteId: string, timePeriod: Props['timePeriod']) =>
  `tank-level-chart-${siteId}-${timePeriod}`

const generateChartImageURL = async (chartId: string) => {
  const chart = document.getElementById(chartId)
  let imageUrl = null

  if (chart !== null) {
    const canvas = await html2canvas(chart)
    const url = canvas.toDataURL('image/png', 1.0)

    imageUrl = url
  }

  return imageUrl
}

const Loader = () => (
  <Box>
    <MantineLoader variant="bars" size="sm" />
    <Text size={10} weight={400}>
      Building report...
    </Text>
  </Box>
)

export interface ChartsBuilderProps {
  open: () => void
}

interface Props {
  timePeriod: TimePeriod['value'] | null
  data: SiteTankLevels[]
  isBuilding: boolean
  setIsBuilding: Dispatch<SetStateAction<boolean>>
  onSuccess?: (data: SiteTankLevels[]) => void
}

function Builder ({
  timePeriod,
  data,
  isBuilding,
  setIsBuilding,
  onSuccess
}: Props, ref: React.Ref<ChartsBuilderProps>) {
  const theme = useMantineTheme()
  const [isOpen, { close, open }] = useDisclosure(false)

  useImperativeHandle(ref, () => ({
    open
  }), [open])

  const handleSuccess = useCallback((data: SiteTankLevels[]) => {
    close()

    if (onSuccess) {
      onSuccess(data)
    }

    setIsBuilding(false)
  }, [onSuccess])

  const captureCharts = useCallback(async () => {
    const mappedData = await Promise.all(data.map(async ({ site_id, ...rest }) => {
      const chartId = generateChartId(site_id, timePeriod)
      const chart_url = await generateChartImageURL(chartId)

      return {
        site_id,
        chart_url,
        ...rest
      }
    }))

    handleSuccess(mappedData)
  }, [timePeriod, data])

  useEffect(() => {
    if (isOpen) {
      // Allow charts to render properly before capturing
      setTimeout(() => {
        void captureCharts()
      }, 1500)
    }
  }, [isOpen])

  return (
    <Modal
      size="auto"
      opened={isOpen}
      onClose={close}
      closeOnClickOutside={false}
      closeOnEscape={false}
      withCloseButton={false}
      overlayProps={{
        color: theme.colorScheme === 'dark'
          ? theme.colors.dark[9]
          : theme.colors.gray[2],
        opacity: 0.5,
        blur: 4
      }}
      centered
    >
      {isBuilding && <Loader />}
      {data.map(({ site_id, graph }) => {
        const chartId = generateChartId(site_id, timePeriod)

        return (
          // This container will hide each chart from user's screen to prevent the UI
          // from cluttering, but it will still be rendered to the DOM so html2canvas
          // can capture it
          <Box
            key={chartId}
            sx={{
              width: 0,
              height: 0,
              overflow: 'hidden'
            }}
          >
            <Box
              id={chartId}
              sx={{
                pointerEvents: 'none',
                width: 1280,
                height: 425
              }}
            >
              <Chart
                timePeriod={timePeriod}
                items={graph}
                unit="L"
                colorScheme="light"
                height={350}
                margin={{
                  top: 30,
                  right: 20,
                  bottom: 70,
                  left: 40
                }}
                unstacked
                showLegend
                disabled={graph.length === 0}
              />
            </Box>
          </Box>
        )
      })}
    </Modal>
  )
}

const ChartsBuilder = forwardRef(Builder)

export default ChartsBuilder
